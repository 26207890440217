import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { IS_DEV } from 'utils/constants';
import { CONF_ENVIRONMENT } from 'utils/config';
import { IPaymentCardToken, IPaymentProp } from 'utils/models';
import { useModalSaving } from 'hooks/useModalSaving';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

// components
import Button from 'components/Button';
import Stack from 'components/Stack';
import Toggle from 'components/Toggle';
import { useGlobalLoading } from 'hooks/useGlobalLoading';

interface IFormZaiPayment {
  cardToken: IPaymentCardToken;
  onSuccess: (props: IPaymentProp) => void;
  hasSaveCard?: boolean;
  className?: ClassValue;
  customSubmit?: ReactNode;
}

const fieldStyles = {
  input: {
    color: '#000645',
    'font-family': 'system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
    'letter-spacing': '0.02em',
    'background-color': '#ffffff'
  },
  '.invalid': {
    color: '#cc0001'
  },
  '::placeholder': {
    color: '#757575'
  },
  '.invalid .card-icon': {
    color: '#cc0001'
  }
};

const FormZaiPayment: FC<IFormZaiPayment> = ({
  cardToken,
  onSuccess,
  hasSaveCard = false,
  className,
  customSubmit
}) => {
  const reducers = useSelector((state: RootState) => state.others);
  const noOfSavedCard = reducers.noOfSavedCard as number;

  const { isSaving, setIsSaving } = useModalSaving();
  const { setShowLoader } = useGlobalLoading();

  const [error, setError] = useState<string>('');
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [paymentId, setPaymentId] = useState<string>('');
  const [saveCard, setSaveCard] = useState<boolean>(false);

  const handleSubmit = useCallback(
    () => onSuccess({ paymentId, isSaveCard: saveCard, isExist: false }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentId]
  );

  useEffect(() => {
    // Initialise hosted fields

    // @ts-ignore
    const hostedFields = assembly.hostedFields({
      environment: IS_DEV.includes(CONF_ENVIRONMENT || 'development') ? 'pre-live' : 'production'
    }); // pre-live or production

    const cardName = hostedFields.create('cardName', {
      placeholder: 'Full Name',
      styles: fieldStyles
    });

    const cardNumber = hostedFields.create('cardNumber', {
      placeholder: '•••• •••• •••• ••••',
      styles: fieldStyles
    });

    const cardExpiry = hostedFields.create('cardExpiry', {
      placeholder: 'MM/YY',
      styles: fieldStyles
    });

    const cardCvv = hostedFields.create('cardCvv', {
      placeholder: '•••',
      styles: fieldStyles
    });

    cardName.mount('#card-name-field');
    cardNumber.mount('#card-number-field');
    cardExpiry.mount('#card-expiry-field');
    cardCvv.mount('#card-cvv-field');

    const inputs = [cardName, cardNumber, cardExpiry, cardCvv];

    inputs.forEach((field) => {
      field.on('change', (event: any) => {
        setFieldErrors((prevState: any) => ({
          ...prevState,
          [event.fieldType]: event.error ? event.error.message : undefined
        }));
      });
    });

    // const form = document.querySelector('form');
    const form = document.querySelector('#hoisted-form');

    if (form) {
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        // setIsSubmitting(true);
        setShowLoader(true);
        setIsSaving(true);
        setError('');

        hostedFields
          .createCardAccount({
            token: cardToken.token,
            user_id: cardToken.user_id
          })
          .then((resp: any) => {
            // resp.card_accounts.id
            setPaymentId(resp.card_accounts.id);
          })
          .catch((error: any) => {
            // handle errors
            // setIsSubmitting(false);
            setIsSaving(false);
            setShowLoader(false);
            if (error.errors && error.errors.token) {
              setError('Your token is not authorized');
            } else {
              setError('There was an error creating your card account.');
            }
          });
      });
    }

    return function cleanup() {
      hostedFields.destroy();
    };
  }, [cardToken.token, cardToken.user_id, setIsSaving, setShowLoader]);

  useEffect(() => {
    if (paymentId) handleSubmit();
  }, [paymentId, handleSubmit]);

  return (
    <form id="hoisted-form" className={clsx('hoisted-form relative')}>
      <div className={clsx(className)}>
        <div className="cardName-container field-container space-y-2 text-sm">
          <label htmlFor="card-name-field">Cardholder Name</label>
          <div id="card-name-field" className="hosted-field"></div>
          {fieldErrors.cardName && <div className="error">{fieldErrors.cardName}</div>}
        </div>
        <div className="cardNumber-container field-container space-y-2 text-sm">
          <label htmlFor="card-number-field">Card Number</label>
          <div id="card-number-field" className="hosted-field"></div>
          {fieldErrors.cardNumber && <div className="error">{fieldErrors.cardNumber}</div>}
        </div>

        <div className="flex space-x-3 text-sm">
          <div className="cardExpiry-container field-container space-y-2 w-6/12">
            <label htmlFor="card-expiry-field">Expiry Date</label>
            <div id="card-expiry-field" className="hosted-field"></div>
            {fieldErrors.cardExpiry && <div className="error">{fieldErrors.cardExpiry}</div>}
          </div>
          <div className="cardCvv-container field-container space-y-2 w-6/12">
            <label htmlFor="card-cvv-field">CVV</label>
            <div id="card-cvv-field" className="hosted-field"></div>
            {fieldErrors.cardCvv && <div className="error">{fieldErrors.cardCvv}</div>}
          </div>
        </div>

        {error && <div className="response response-error">{error}</div>}
        <div className="disclaimer text-xs">Payment securely processed by Assembly Payments.</div>

        {hasSaveCard && (
          <div className="field-container flex justify-end">
            <div className="space-y-2">
              <div className=" flex justify-end">
                <Toggle
                  label="Save card"
                  isChecked={saveCard}
                  onChange={setSaveCard}
                  disabled={noOfSavedCard >= 4}
                />
              </div>

              {noOfSavedCard >= 4 && (
                <div className="text-sm italic">(you can only save up to 4 cards)</div>
              )}
            </div>
          </div>
        )}
      </div>

      {customSubmit ? (
        customSubmit
      ) : (
        <Stack className="justify-center" spacingX={3}>
          <Button
            type="submit"
            variant="green-btn-500"
            rounded="lg"
            className="w-48"
            classNameButton="items-center uppercase py-3 justify-center"
            isLoading={isSaving}
          >
            Pay
          </Button>
        </Stack>
      )}
    </form>
  );
};

export default FormZaiPayment;
