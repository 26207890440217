import React, { FC } from 'react';
import clsx from 'clsx';
import { ISteps } from 'utils/models';

interface IStepBar {
  steps: ISteps[];
  step: number;
  setStep?: (x: number) => void;
  theme?: 'default' | 'white' | 'orders' | 'appointment';
}

const themes = {
  default: {
    textFont: 'uppercase text-xs md:text-sm font-extrabold',
    textStep: 'text-polln-orange-100',
    textColor: 'text-black',
    flexWrap: 'space-x-20 md:space-x-36 flex flex-wrap justify-center '
  },
  appointment: {
    textFont: 'uppercase text-xs md:text-sm font-extrabold',
    textStep: 'text-polln-orange-100',
    textColor: 'text-black',
    flexWrap: 'space-x-0 flex flex-wrap justify-between md:justify-around md:space-x-0'
  },
  white: {
    textFont: 'uppercase text-xs md:text-sm font-extrabold',
    textStep: 'text-polln-orange-100',
    textColor: 'text-white',
    flexWrap: 'space-x-20 md:space-x-36 flex flex-wrap justify-center'
  },
  orders: {
    textFont: 'text-sm font-medium',
    textStep: '',
    textColor: 'text-white',
    flexWrap: 'flex justify-around '
  }
};

const StepBar: FC<IStepBar> = ({ steps, step, setStep, theme = 'default' }) => {
  return (
    <div className="space-y-5">
      <div className="flex justify-center">
        <div className="relative w-full">
          {/* <div className={clsx('absolute border-2 w-full z-[1] inset-y-3/4 border-gray-10')} /> */}
          <div className={clsx(themes[theme].flexWrap, 'z-[2] relative overflow-clip')}>
            {steps.map((item, index: number) => {
              return (
                <div
                  key={index}
                  className="space-y-2 flex justify-center items-center flex-col mx-3"
                >
                  <div
                    className={clsx(
                      themes[theme].textFont,
                      step >= item.step
                        ? clsx(themes[theme].textStep, 'cursor-pointer')
                        : themes[theme].textColor
                    )}
                    onClick={() => step >= item.step && setStep && setStep(item.step)}
                  >
                    {item.label}
                  </div>
                  <div
                    className={clsx(
                      'h-5 w-5 text-3xl border-2 p-2 rounded-full z-[2]',
                      step >= item.step
                        ? clsx(
                            'bg-polln-orange-100 border-polln-orange-100',
                            setStep && 'cursor-pointer'
                          )
                        : 'bg-gray-10 border-gray-10'
                    )}
                    onClick={() => step >= item.step && setStep && setStep(item.step)}
                  />
                  <div
                    className={clsx(
                      'border-2 w-5/12 bg-polln-orange absolute bottom-2',
                      step >= item.step && 'border-polln-orange-100 w-11/12'
                    )}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepBar;
