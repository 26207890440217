import { ICheckOptions, ILinkPermission, ISelectOptions, IYesOrNo } from './models';
import {
  SvgAnxiety,
  SvgCancerPain,
  SvgChemotherapy,
  SvgChronicIllness,
  SvgChronicInsomnia,
  SvgChronicPain,
  SvgDepression,
  SvgEpilepsy,
  SvgMigraine,
  SvgMultipleSclerosis,
  SvgNeuropathicPain,
  SvgPalliativeCare,
  SvgPtsd
} from 'components/SvgIcons';

export const IS_DEV = ['development', 'qa', 'staging'];

export const MOBILE_PREFIX = '+61';

export const CONDITIONS: ICheckOptions[] = [
  {
    id: 1,
    className: 'md:col-span-4',
    value: 'Chronic Pain',
    icon: SvgChronicPain
  },
  {
    id: 2,
    className: 'md:col-span-4',
    value: 'Anxiety',
    icon: SvgAnxiety
  },
  {
    id: 3,
    className: 'md:col-span-4',
    value: 'Depression',
    icon: SvgDepression
  },
  {
    id: 4,
    className: 'md:col-span-4',
    value: 'Insomnia',
    icon: SvgChronicInsomnia
  },
  {
    id: 5,
    className: 'md:col-span-4',
    value: 'Migraines',
    icon: SvgMigraine
  },
  {
    id: 6,
    className: 'md:col-span-4',
    value: 'Neuropathic Pain',
    icon: SvgNeuropathicPain
  },
  {
    id: 7,
    className: 'md:col-span-4',
    value: 'Multiple Sclerosis',
    icon: SvgMultipleSclerosis
  },
  {
    id: 8,
    className: 'md:col-span-4',
    value: 'PTSD',
    icon: SvgPtsd
  },
  {
    id: 9,
    className: 'md:col-span-4',
    value: 'Cancer Pain',
    icon: SvgCancerPain
  },
  {
    id: 10,
    className: 'md:col-span-4',
    value: 'Palliative Care',
    icon: SvgPalliativeCare
  },
  {
    id: 11,
    className: 'md:col-span-4',
    value: 'Epilepsy',
    icon: SvgEpilepsy
  },
  {
    id: 12,
    className: 'md:col-span-4',
    value: 'Chronic Illness',
    icon: SvgChronicIllness
  },
  {
    id: 13,
    className: 'md:col-span-4',
    value: 'Nausea/Vomiting',
    icon: SvgChemotherapy
  }
];

export const CONDITIONS_LUNGS: ICheckOptions[] = [
  {
    id: 1,
    className: 'md:col-span-4',
    value: 'Angina'
  },
  {
    id: 2,
    className: 'md:col-span-4',
    value: 'Atrial Fibrilation'
  },
  {
    id: 3,
    className: 'md:col-span-4',
    value: 'Coronary Arterial Disease'
  },
  {
    id: 4,
    className: 'md:col-span-4',
    value: 'Hypertension'
  },
  {
    id: 5,
    className: 'md:col-span-4',
    value: 'Asthma'
  }
];

export const CONDITIONS_MENTAL: ICheckOptions[] = [
  {
    id: 1,
    className: 'md:col-span-4',
    value: 'Anxiety'
  },
  {
    id: 2,
    className: 'md:col-span-4',
    value: 'Psychosis'
  },
  {
    id: 3,
    className: 'md:col-span-4',
    value: 'Schizophrenia'
  },
  {
    id: 4,
    className: 'md:col-span-4',
    value: 'Insomnia'
  },
  {
    id: 5,
    className: 'md:col-span-4',
    value: 'Depression'
  },
  {
    id: 6,
    className: 'md:col-span-4',
    value: 'PTSD'
  },
  {
    id: 7,
    className: 'md:col-span-4',
    value: 'ADHD'
  },
  {
    id: 8,
    className: 'md:col-span-4',
    value: 'Neuropathic Pain'
  },
  {
    id: 9,
    className: 'md:col-span-4',
    value: 'Migraines'
  },
  {
    id: 10,
    className: 'md:col-span-4',
    value: 'Epilepsy'
  }
];

export const CONDITIONS_SKELETAL: ICheckOptions[] = [
  {
    id: 1,
    className: 'md:col-span-4',
    value: 'Arthritis'
  },
  {
    id: 2,
    className: 'md:col-span-4',
    value: 'Chronic Pain'
  },
  {
    id: 3,
    className: 'md:col-span-4',
    value: 'Fibromyalgia'
  }
];

export const CONDITIONS_OTHERS: ICheckOptions[] = [
  {
    id: 1,
    className: 'md:col-span-4',
    value: 'Cancer'
  },
  {
    id: 2,
    className: 'md:col-span-4',
    value: 'Crohn’s Disease'
  },
  {
    id: 3,
    className: 'md:col-span-4',
    value: 'Diabetes'
  },
  {
    id: 4,
    className: 'md:col-span-4',
    value: 'Autoimmune Disease'
  },
  {
    id: 5,
    className: 'md:col-span-4',
    value: 'Endometriosis'
  },
  {
    id: 6,
    className: 'md:col-span-4',
    value: 'IBS/IBD'
  }
];

export const YESORNO: IYesOrNo[] = [
  {
    value: 1,
    label: 'Yes'
  },
  {
    value: 2,
    label: 'No'
  }
];

export const TIMEZONES: ISelectOptions[] = [
  {
    value: 'Australia/Eucla',
    label: '(GMT+8:45) Eucla'
  },
  {
    value: 'Australia/Perth',
    label: '(GMT+8:00) AWST - Perth'
  },
  {
    value: 'Australia/Darwin',
    label: '(GMT+9:30) ACST - Darwin'
  },
  {
    value: 'Australia/Brisbane',
    label: '(GMT+10:00) AEST - Brisbane'
  },
  {
    value: 'Australia/Lindeman',
    label: '(GMT+10:00) AEST - Lindeman'
  },
  {
    value: 'Australia/Adelaide',
    label: '(GMT+10:30) ACDT - Adelaide'
  },
  {
    value: 'Australia/Broken_Hill',
    label: '(GMT+10:30) ACDT - Broken Hill'
  },
  {
    value: 'Australia/Hobart',
    label: '(GMT+11:00) AEDT - Hobart'
  },
  {
    value: 'Australia/Melbourne',
    label: '(GMT+11:00) AEDT - Melbourne'
  },
  {
    value: 'Australia/Sydney',
    label: '(GMT+11:00) AEDT - Sydney'
  }
];

export const GENDER_OPTIONS: ISelectOptions[] = [
  {
    value: 'MALE',
    label: 'Male'
  },
  {
    value: 'FEMALE',
    label: 'Female'
  },
  {
    value: 'NOTTOSAY',
    label: 'Prefer not to say'
  },
  {
    value: 'OTHERS',
    label: 'Other'
  }
];

export const PROFILE_PHOTO_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

export const TABLE_PER_PAGE: ISelectOptions[] = [
  {
    label: '5 per page',
    value: 5
  },
  {
    label: '10 per page',
    value: 10
  },
  {
    label: '20 per page',
    value: 20
  },
  {
    label: '25 per page',
    value: 25
  },
  {
    label: '50 per page',
    value: 50
  },
  {
    label: '100 per page',
    value: 100
  }
];

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24
};

export const STATE_OPTIONS: ISelectOptions[] = [
  { label: 'ACT', value: 'ACT' },
  { label: 'NSW', value: 'NSW' },
  { label: 'NT', value: 'NT' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' }
];

export const WORK_STATUS_OPTIONS: ISelectOptions[] = [
  { label: 'Employed', value: 'Employed' },
  { label: 'Retired', value: 'Retired' },
  { label: 'Not Working', value: 'Not Working' },
  { label: 'Light Duty', value: 'Light Duty' }
];

export const MEDICAL_HISTORY: ICheckOptions[] = [
  {
    id: 1,
    className: 'md:col-span-3',
    value: 'None'
  },
  {
    id: 2,
    className: 'md:col-span-3',
    value: 'Allergies'
  },
  {
    id: 3,
    className: 'md:col-span-3',
    value: 'Anemia'
  },
  {
    id: 4,
    className: 'md:col-span-3',
    value: 'Angina'
  },
  {
    id: 5,
    className: 'md:col-span-3',
    value: 'Anxiety'
  },
  {
    id: 6,
    className: 'md:col-span-3',
    value: 'Arthritis'
  },
  {
    id: 7,
    className: 'md:col-span-3',
    value: 'Asthma'
  },
  {
    id: 8,
    className: 'md:col-span-3',
    value: 'Atrial Fibrilation'
  },
  {
    id: 9,
    className: 'md:col-span-3',
    value: 'Benign Prostatic Hypertrophy'
  },
  {
    id: 10,
    className: 'md:col-span-3',
    value: 'Blood Clots'
  },
  {
    id: 11,
    className: 'md:col-span-3',
    value: 'Cancer - Type'
  },
  {
    id: 12,
    className: 'md:col-span-3',
    value: 'Cerebrovascular Accident'
  },
  {
    id: 13,
    className: 'md:col-span-3',
    value: 'Chronic Pain'
  },
  {
    id: 14,
    className: 'md:col-span-3',
    value: 'Chronic Insomnia'
  },
  {
    id: 15,
    className: 'md:col-span-3',
    value: 'Cronary Artery Disease'
  },
  {
    id: 16,
    className: 'md:col-span-3',
    value: 'COPD (Emphysema)'
  },
  {
    id: 17,
    className: 'md:col-span-3',
    value: "Crohns's Disease"
  },
  {
    id: 18,
    className: 'md:col-span-3',
    value: 'Depression'
  },
  {
    id: 19,
    className: 'md:col-span-3',
    value: 'Diabetes'
  },
  {
    id: 20,
    className: 'md:col-span-3',
    value: 'Epilepsy'
  },
  {
    id: 21,
    className: 'md:col-span-3',
    value: 'Gallbladder Disease'
  },
  {
    id: 22,
    className: 'md:col-span-3',
    value: 'GERD (Reflux)'
  },
  {
    id: 23,
    className: 'md:col-span-3',
    value: 'Hepatitis C'
  },
  {
    id: 24,
    className: 'md:col-span-3',
    value: 'Hyperlidermia'
  },
  {
    id: 25,
    className: 'md:col-span-3',
    value: 'Hypertension'
  },
  {
    id: 26,
    className: 'md:col-span-3',
    value: 'Irritable Bowel Disease'
  },
  {
    id: 27,
    className: 'md:col-span-3',
    value: 'Liver Disease'
  },
  {
    id: 28,
    className: 'md:col-span-3',
    value: 'Neuropathic Pain'
  },
  {
    id: 29,
    className: 'md:col-span-3',
    value: 'Migraine Headaches'
  },
  {
    id: 30,
    className: 'md:col-span-3',
    value: 'Multiple Sclerosis'
  },
  {
    id: 31,
    className: 'md:col-span-3',
    value: 'Myocardial Infarction'
  },
  {
    id: 32,
    className: 'md:col-span-3',
    value: 'Osteoarthritis'
  },
  {
    id: 33,
    className: 'md:col-span-3',
    value: 'Palliative Care'
  },
  {
    id: 34,
    className: 'md:col-span-3',
    value: 'PTSD'
  },
  {
    id: 35,
    className: 'md:col-span-3',
    value: 'Renal Disease'
  },
  {
    id: 36,
    className: 'md:col-span-3',
    value: 'Seizure Disorder'
  },
  {
    id: 37,
    className: 'md:col-span-3',
    value: 'Thyroid Disease'
  }
];

export const GENERAL_HEALTH_OPTIONS: ISelectOptions[] = [
  { label: 'No', value: 'No' },
  { label: 'Daily', value: 'Daily' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Less', value: 'Less' },
  { label: 'Former User', value: 'Former User' }
];

export const EXERCISE_OPTIONS: ISelectOptions[] = [
  { label: 'None', value: 'None' },
  { label: 'Once a week', value: 'Once a week' },
  { label: 'Twice a week', value: 'Twice a week' },
  { label: 'More than 3 times per week', value: 'More than 3 times per week' }
];

export const FAMILY_HISTORY: ICheckOptions[] = [
  {
    id: 1,
    className: 'md:col-span-3',
    value: 'Adopted'
  },
  {
    id: 2,
    className: 'md:col-span-3',
    value: 'Alcoholism'
  },
  {
    id: 3,
    className: 'md:col-span-3',
    value: 'Allergies'
  },
  {
    id: 4,
    className: 'md:col-span-3',
    value: 'Asthma'
  },
  {
    id: 5,
    className: 'md:col-span-3',
    value: 'Arthritis'
  },
  {
    id: 6,
    className: 'md:col-span-3',
    value: 'Blood Disease'
  },
  {
    id: 7,
    className: 'md:col-span-3',
    value: 'CAD (Heart Attack)'
  },
  {
    id: 8,
    className: 'md:col-span-3',
    value: 'Cancer'
  },
  {
    id: 9,
    className: 'md:col-span-3',
    value: 'CVA (Stroke)'
  },
  {
    id: 10,
    className: 'md:col-span-3',
    value: 'Depression'
  },
  {
    id: 11,
    className: 'md:col-span-3',
    value: 'Development Delay'
  },
  {
    id: 12,
    className: 'md:col-span-3',
    value: 'Diabetes'
  },
  {
    id: 13,
    className: 'md:col-span-3',
    value: 'Eczema'
  },
  {
    id: 14,
    className: 'md:col-span-3',
    value: 'Hearing Deficiency'
  },
  {
    id: 15,
    className: 'md:col-span-3',
    value: 'Hyperlipidemia (High Cholesterol)'
  },
  {
    id: 16,
    className: 'md:col-span-3',
    value: 'Hypertension (High Blood Pressure)'
  },
  {
    id: 17,
    className: 'md:col-span-3',
    value: 'Irritable Bowel Disease'
  },
  {
    id: 18,
    className: 'md:col-span-3',
    value: 'Learning Disability'
  },
  {
    id: 19,
    className: 'md:col-span-3',
    value: 'Mental Illness'
  },
  {
    id: 20,
    className: 'md:col-span-3',
    value: 'Tuberculosis'
  },
  {
    id: 21,
    className: 'md:col-span-3',
    value: 'Obesity'
  },
  {
    id: 22,
    className: 'md:col-span-3',
    value: 'Osteoarthritis'
  },
  {
    id: 23,
    className: 'md:col-span-3',
    value: 'PVD'
  },
  {
    id: 24,
    className: 'md:col-span-3',
    value: 'Renal Disease'
  }
];

export const CANNABIS_USE_OPTIONS: ISelectOptions[] = [
  { label: 'Never', value: 'Never' },
  { label: 'Once or twice', value: 'Once or twice' },
  { label: 'Daily', value: 'Daily' },
  { label: 'Weekly', value: 'Weekly' }
];

export const STEPS_ONBOARD = {
  1: 'Next',
  2: 'Next',
  3: 'Done',
  4: 'Yes'
};

export const STEPS_HEALTH = {
  5: 'Next',
  6: 'Next',
  7: 'Next',
  8: 'Next',
  9: 'Done'
};

export const ABOUT_POLLN_OPTIONS: ISelectOptions[] = [
  { label: 'Word of Mouth', value: 'Word of Mouth' },
  { label: 'Referral', value: 'Referral' },
  { label: 'Search Engine', value: 'Search Engine' },
  { label: 'Google', value: 'Google' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Facebook Post/Groups', value: 'Facebook Post/Groups' },
  { label: 'YouTube', value: 'YouTube' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Email', value: 'Email' },
  { label: 'Radio', value: 'Radio' },
  { label: 'Newspaper/Blogs', value: 'Newspaper/Blogs' },
  { label: 'Others', value: 'Others' }
];

export const LINK_TUTORIAL_PERMISSION: ILinkPermission[] = [
  { name: 'Chrome', url: 'https://support.google.com/chrome/answer/2693767?hl=en' },
  {
    name: 'Firefox',
    url: 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions'
  },
  { name: 'Safari (Mac)', url: ' https://support.apple.com/en-au/guide/safari/ibrwe2159f50/mac' },
  { name: 'Safari (iPhone)', url: ' https://support.apple.com/en-au/guide/iphone/iph168c4bbd5/ios' }
];

export const STANDARD_SHIPPING_FEE: number = 15;
export const PICKUP_SHIPPING_FEE: number = 0;

export const STEPS_CART = {
  1: 'Proceed to shipping',
  2: 'Proceed to checkout',
  3: 'Complete checkout'
};

export const ORDER_STATUS_PROCESSING = [
  'PENDING',
  'ACTIVE',
  'INVOICED',
  'PAID',
  'READY_FOR_DISPENSING',
  'DISPENSING',
  'DELAYED'
];

export const ORDER_STATUS_SHIPPED = ['DISPENSED', 'SHIPPING'];

export const ORDER_STATUS_COMPLETED = ['DELIVERED', 'COMPLETED'];

export const ORDER_STATUS_HOLD = ['CANCELLED', 'INACTIVE', 'ON_HOLD'];

export const ORDER_STATUS_FAILED = ['PAYMENT_FAILED'];
