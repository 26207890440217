import gql from 'graphql-tag';

const query = {
  patientMe: gql`
    query patientMe {
      patientMe {
        code
        success
        message
        is_from_admin
        patient {
          id
          email
          timezone
          contact_no
          secondary_contact_no
          profile {
            patient_id
            first_name
            middle_name
            last_name
            pronoun
            gender
            date_of_birth
            street
            city
            state
            postcode
            country
            medicare_no
            profile_pic
            is_australian_resident
            is_over_18
            tried_conventional_treatment
            medical_symptoms
            medical_conditions
            conditions
            other_conditions
            how_long_suffered
            using_any_medicine
            have_any_allergy
            allergy_details
            relevant_notes
            onboarding_step
            about_polln
            currently_being_treated
            current_prescription
            taking_any_medication
            taking_any_medication_list
            achieve_polln
            addtl_info
          }
          health_history {
            patient_id
            english_language
            primary_language
            video_comfortable
            heart_lung_condition
            mental_neuro_condition
            muscle_condition
            other_condition
            other_condition_list
            currently_taking_medications
            current_medications
            medicine_with_prescription
            medicine_with_prescription_details
            medicine_without_prescription
            medicine_without_prescription_details
            smoke_tobacco
            smoke_tobacco_frequency
            drink_alcohol
            drink_alcohol_frequency
            pregnant_breastfeeding
            psychiatric_disorder
            family_with_health_condition
            family_with_health_condition_details
            injury_surgery__hospitalization
            injury_surgery__hospitalization_details
            current_diet
            physical_activity_level
            stress_emotions
            status
          }
          background {
            patient_id
            cannabis_used
            have_medical_prescription
            cannabis_history
            has_allergy
            allergy
            allergy_severity
            has_surgery
            surgery
            surgery_date
            alcohol_used
            tobacco_used
            exescise_activity
            family_history
            other_family_history
            medical_history
            other_medical_history
            currently_being_treated
            current_clinic
            current_doctor
            current_date
            currently_taking_medication
            current_medication
            current_medication_dose
            current_work_status
            current_work
          }
          phone_verification
          email_verification
          status
          billing_address {
            street
            city
            state
            postcode
          }
          delivery_address {
            street
            city
            state
            postcode
          }
          consultation_status
          last_consultation_date
          consent_approved
          shop_consent_approved
          same_delivery
          same_billing
          id_verification
          checkout_enabled
          ihi_no
          ihi_record_status
          ihi_status
          dva_no
        }
      }
    }
  `,
  consultationDetails: gql`
    query consultationDetails($id: String!) {
      consultationDetails(id: $id) {
        code
        success
        message
        consultation {
          id
          reference_id
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            price
            description
          }
          enquiry
          step
          # schedule_id
        }
      }
    }
  `,
  doctorList: gql`
    query doctorList {
      doctorList {
        code
        success
        message
        doctors {
          id
          email
          timezone
          contact_no
          profile {
            doctor_id
            first_name
            last_name
            profile_pic
          }
        }
      }
    }
  `,
  consultationSearchDoctorSchedules: gql`
    query consultationSearchDoctorSchedules($input: consultationSearchDoctorSchedulesInput!) {
      consultationSearchDoctorSchedules(input: $input) {
        code
        success
        message
        consultation_schedules {
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          # schedule_id
        }
      }
    }
  `,
  // consultationTypes: gql`
  //   query consultationTypes {
  //     consultationTypes {
  //       code
  //       success
  //       message
  //       consultation_types {
  //         id
  //         name
  //         capitalize_name
  //         duration
  //         price
  //         is_default
  //         is_preselected
  //         description
  //       }
  //     }
  //   }
  // `,
  // @TODO - consultations
  consultationTypes: gql`
    query consultationTypes($doctorId: String) {
      consultationTypes(doctorId: $doctorId) {
        code
        success
        message
        consultation_types {
          id
          name
          capitalize_name
          duration
          price
          is_default
          is_preselected
          description
        }
      }
    }
  `,
  consultationPatientUpcoming: gql`
    query consultationPatientUpcoming($count: Int) {
      consultationPatientUpcoming(count: $count) {
        code
        success
        message
        consultations {
          id
          reference_id
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            description
          }
          enquiry
          can_be_cancel
        }
      }
    }
  `,
  patientDocuments: gql`
    query patientDocuments {
      patientDocuments {
        code
        success
        message
        documents {
          filename
          file_url
          file_key
          uploaded_by
          file_type
          upload_date
          consultation {
            id
            reference_id
            patient {
              id
              email
              timezone
              contact_no
              profile {
                patient_id
                first_name
                middle_name
                last_name
                pronoun
                gender
                date_of_birth
                street
                city
                state
                postcode
                country
                medicare_no
                profile_pic
                is_australian_resident
                is_over_18
                tried_conventional_treatment
                medical_symptoms
                medical_conditions
                conditions
                other_conditions
                how_long_suffered
                using_any_medicine
                have_any_allergy
                allergy_details
                relevant_notes
                onboarding_step
                about_polln
                currently_being_treated
                current_prescription
                taking_any_medication
                taking_any_medication_list
                achieve_polln
                addtl_info
              }
              ihi_no
              ihi_record_status
              ihi_status
              dva_no
            }
            doctor {
              id
              email
              timezone
              contact_no
              profile {
                doctor_id
                first_name
                last_name
                date_of_birth
                street
                city
                state
                postcode
                country
                profile_pic
                description
                provider_no
                prescriber_no
                onboarding_step
              }
              phone_verification
              email_verification
              status
            }
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
              description
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
          visibility
        }
      }
    }
  `,
  consultationPatientHistory: gql`
    query consultationPatientHistory {
      consultationPatientHistory {
        code
        success
        message
        consultations {
          id
          reference_id
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            description
          }
          enquiry
          status
          prescription {
            id
            patient_id
            consultation_id
            products {
              prescription_id
              product_id
              product {
                id
                type
                brand
                product_name
                product_type {
                  dispensing_limit
                  icon
                  id
                  name
                  unit
                }
                stock
                format
                schedule
                price
                is_active
              }
              product_image
              no_of_units
              repeats
              dosage
            }
            custom_products {
              product_name
              product_details
              product_type
              no_of_units
              repeats
              dosage
              brand_name
              sku
              type
              format
            }
            consultation_notes
            treatment_notes
            careteam_notes
            consultation {
              id
              reference_id
              patient {
                id
                email
                timezone
                contact_no
                profile {
                  patient_id
                  first_name
                  middle_name
                  last_name
                  pronoun
                  gender
                  date_of_birth
                  street
                  city
                  state
                  postcode
                  country
                  medicare_no
                  profile_pic
                  is_australian_resident
                  is_over_18
                  tried_conventional_treatment
                  medical_symptoms
                  medical_conditions
                  conditions
                  other_conditions
                  how_long_suffered
                  using_any_medicine
                  have_any_allergy
                  allergy_details
                  relevant_notes
                  onboarding_step
                  about_polln
                  currently_being_treated
                  current_prescription
                  taking_any_medication
                  taking_any_medication_list
                  achieve_polln
                  addtl_info
                }
                ihi_no
                ihi_record_status
                ihi_status
                dva_no
              }
              doctor {
                id
                email
                timezone
                contact_no
                profile {
                  doctor_id
                  first_name
                  last_name
                  date_of_birth
                  street
                  city
                  state
                  postcode
                  country
                  profile_pic
                  description
                  provider_no
                  prescriber_no
                  onboarding_step
                }
                phone_verification
                email_verification
                status
              }
              start_at
              end_at
              consultation_type {
                id
                name
                capitalize_name
                duration
                is_default
                description
              }
              status
              enquiry
              tga_approval
              tga_acceptance
              script_approval
              documents {
                filename
                file_url
                file_key
                uploaded_by
                file_type
              }
            }
          }
        }
      }
    }
  `,
  patientConsultations: gql`
    query patientConsultations(
      $paginator: Paginator
      $date_from: DateTime
      $date_to: DateTime
      $status: [ConsultationStatus]
    ) {
      patientConsultations(
        paginator: $paginator
        date_from: $date_from
        date_to: $date_to
        status: $status
      ) {
        code
        success
        message
        count
        consultations {
          id
          reference_id
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            description
          }
          status
          enquiry
          prescription {
            id
            patient_id
            consultation_id
            products {
              prescription_id
              product_id
              product {
                id
                type
                brand
                product_name
                product_image
                product_type {
                  dispensing_limit
                  icon
                  id
                  name
                  unit
                }
                stock
                format
                schedule
                price
                is_active
              }
              no_of_units
              repeats
              dosage
            }
            custom_products {
              product_name
              product_details
              product_type
              no_of_units
              repeats
              dosage
              brand_name
              sku
              type
              format
            }
            consultation_notes
            treatment_notes
            careteam_notes
            consultation {
              id
              reference_id
              patient {
                id
                email
                timezone
                contact_no
                profile {
                  patient_id
                  first_name
                  middle_name
                  last_name
                  pronoun
                  gender
                  date_of_birth
                  street
                  city
                  state
                  postcode
                  country
                  medicare_no
                  profile_pic
                  is_australian_resident
                  is_over_18
                  tried_conventional_treatment
                  medical_symptoms
                  medical_conditions
                  conditions
                  other_conditions
                  how_long_suffered
                  using_any_medicine
                  have_any_allergy
                  allergy_details
                  relevant_notes
                  onboarding_step
                  about_polln
                  currently_being_treated
                  current_prescription
                  taking_any_medication
                  taking_any_medication_list
                  achieve_polln
                  addtl_info
                }
                ihi_no
                ihi_record_status
                ihi_status
                dva_no
              }
              doctor {
                id
                email
                timezone
                contact_no
                profile {
                  doctor_id
                  first_name
                  last_name
                  date_of_birth
                  street
                  city
                  state
                  postcode
                  country
                  profile_pic
                  description
                  provider_no
                  prescriber_no
                  onboarding_step
                }
                phone_verification
                email_verification
                status
              }
              start_at
              end_at
              consultation_type {
                id
                name
                capitalize_name
                duration
                is_default
                description
              }
              status
              enquiry
              tga_approval
              tga_acceptance
              script_approval
              documents {
                filename
                file_url
                file_key
                uploaded_by
                file_type
              }
            }
          }
          documents {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
          script_file {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
          tgaapproval_file {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
          tgaacceptance_file {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
          can_be_cancel
        }
      }
    }
  `,
  patientPrescriptions: gql`
    query patientPrescriptions {
      patientPrescriptions {
        code
        success
        message
        consultations {
          id
          reference_id
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            description
          }
          status
          enquiry
          script_file {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
          tgaapproval_file {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
          tgaacceptance_file {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
        }
      }
    }
  `,
  validateCoupon: gql`
    query validateCoupon($code: String!, $consultationId: String!) {
      validateCoupon(code: $code, consultationId: $consultationId) {
        code
        success
        message
        coupon {
          id
          price_discount
          code
        }
      }
    }
  `,
  patientEscripts: gql`
    query patientEscripts($count: Int, $is_history: Boolean) {
      patientEscripts(count: $count, is_history: $is_history) {
        code
        success
        message
        escripts {
          id
          reference_id
          patient {
            id
            email
            timezone
            contact_no
            profile {
              patient_id
              first_name
              middle_name
              last_name
              pronoun
              gender
              date_of_birth
              street
              city
              state
              postcode
              country
              medicare_no
              profile_pic
              is_australian_resident
              is_over_18
              tried_conventional_treatment
              medical_symptoms
              medical_conditions
              conditions
              other_conditions
              how_long_suffered
              using_any_medicine
              have_any_allergy
              allergy_details
              relevant_notes
              onboarding_step
              about_polln
              currently_being_treated
              current_prescription
              taking_any_medication
              taking_any_medication_list
              achieve_polln
              addtl_info
            }
            ihi_no
            ihi_record_status
            ihi_status
            dva_no
          }
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              date_of_birth
              street
              city
              state
              postcode
              country
              profile_pic
              description
              provider_no
              prescriber_no
              onboarding_step
            }
            phone_verification
            email_verification
            status
          }
          consultation_date
          product {
            id
            type
            brand
            product_name
            product_type {
              dispensing_limit
              icon
              id
              name
              unit
            }
            stock
            format
            schedule
            price
            is_active
          }
          product_image
          product_status
          description
          remaining
          total
          status
          availability
          reach_limit
          reach_interval
          expiry
          dispatch_days
          product_size
          dispensing_limits
          last_order
          order_available_in
          monthly_order
        }
      }
    }
  `,
  consultationNextDoctorAvailabillity: gql`
    query consultationNextDoctorAvailabillity($input: consultationNextDoctorAvailabillityInput!) {
      consultationNextDoctorAvailabillity(input: $input) {
        code
        success
        message
        consultation_schedules {
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          # schedule_id
        }
      }
    }
  `,
  paymentMyCart: gql`
    query paymentMyCart {
      paymentMyCart {
        code
        success
        message
        cart {
          id
          escript {
            id
            product {
              id
              type
              brand
              product_name
              product_type {
                dispensing_limit
                icon
                id
                name
                unit
              }
              stock
              format
              schedule
              price
              is_active
            }
            product_image
            description
            remaining
            total
            availability
            reach_limit
            expiry
            dispatch_days
            product_size
            dispensing_limits
            last_order
            order_available_in
            monthly_order
          }
          quantity
        }
      }
    }
  `,
  paymentCardToken: gql`
    query paymentCardToken {
      paymentCardToken {
        code
        message
        success
        data {
          token
          user_id
        }
      }
    }
  `,
  paymentMyCards: gql`
    query paymentMyCards {
      paymentMyCards {
        code
        message
        success
        cards {
          card_number
          expiry_month
          expiry_year
          full_name
          id
          is_active
          type
        }
      }
    }
  `,
  patientOrders: gql`
    query patientOrders($id: String, $is_history: Boolean) {
      patientOrders(id: $id, is_history: $is_history) {
        code
        message
        success
        orders {
          created_at
          delivery_date
          id
          order_id
          paid_at
          paid_card
          paid_card_ending
          patient_address
          price
          shipping_fee
          status
          wallet_used
          coupon {
            code
            id
            price_discount
          }
        }
      }
    }
  `,
  patientOrderDetails: gql`
    query patientOrderDetails($id: String!) {
      patientOrderDetails(id: $id) {
        code
        message
        success
        order {
          created_at
          delivery_date
          id
          order_id
          paid_at
          paid_card
          paid_card_ending
          patient_address
          price
          products {
            brand
            description
            format
            id
            is_active
            price
            product_name
            product_image
            product_type {
              dispensing_limit
              icon
              id
              name
              unit
            }
            product_type_id
            schedule
            stock
            type
          }
          shipping_fee
          status
          wallet_used
          coupon {
            code
            id
            price_discount
          }
        }
      }
    }
  `,
  paymentValidateCart: gql`
    query paymentValidateCart {
      paymentValidateCart {
        code
        message
        success
      }
    }
  `,
  paymentMyWallet: gql`
    query paymentMyWallet {
      paymentMyWallet {
        code
        message
        success
        wallet
      }
    }
  `
};

export default query;
