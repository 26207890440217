import React, { ReactNode, FC } from 'react';
import clsx, { ClassValue } from 'clsx';
import { TButtonRound, TButtonSize, TVariant } from 'utils/types';
import { Link, To } from 'react-router-dom';

export const sizes = {
  '2xs': 'p-1 text-xs leading-4',
  xs: 'px-2.5 py-1.5 text-xs leading-4',
  sm: 'px-3 py-2 text-sm leading-4',
  md: 'px-6 py-2 text-md leading-5',
  lg: 'px-6 py-2 text-lg leading-6',
  xl: 'px-6 py-3 text-xl leading-6',
  inline: ''
};

export const roundedness = {
  none: '',
  sm: 'rounded-sm',
  rounded: 'rounded',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full'
};

export const variants = {
  primary: {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased shadow-md text-white ',
      'disabled:cursor-not-allowed',
      'bg-polln-green-500 hover:rounded-lg',
      'disabled:opacity-50'
    )
  },
  disabled: {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'disabled:cursor-not-allowed disabled:opacity-50 border',
      'text-white dark:text-white',
      'border-0',
      'bg-gray-300'
    )
  },
  'semi-visible': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased shadow-md text-white ',
      'disabled:cursor-not-allowed',
      'bg-polln-green-100 hover:rounded-lg',
      'disabled:opacity-50'
    )
  },
  'is-gp': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased text-yellow-500',
      'disabled:cursor-not-allowed',
      'bg-white hover:rounded-lg',
      'disabled:opacity-50'
    )
  },
  outlined: {
    span: 'inline hover', // shadow-sm
    button: clsx(
      'inline text-left text-polln-green-900 border border-polln-green-900 hover:border-polln-green-700 focus:bg-gray-50 transition ease-in-out duration-150',
      'focus:border-polln-green-700 bg-white hover:rounded-lg',
      'focus:outline-none focus:ring-1 focus:ring-polln-green-700'
    )
  },
  'outlined-primary': {
    span: 'inline hover', // shadow-sm
    button: clsx(
      'inline text-left text-polln-green-500 border border-polln-green-500 hover:border-polln-green-500 focus:bg-gray-50 transition ease-in-out duration-150',
      'focus:border-polln-green-700 bg-white hover:rounded-lg',
      'focus:outline-none focus:ring-1 focus:ring-polln-green-700'
    )
  },
  secondary: {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition ease-in-out duration-150',
      'disabled:cursor-not-allowed',
      'border-gray-300 focus:border-indigo-500 dark:border-gray-600 dark:focus:border-indigo-500',
      'text-gray-700 active:text-gray-800 hover:text-gray-500 dark:text-gray-200 dark:active:text-white dark:hover:text-white',
      'bg-white active:bg-gray-50 dark:bg-gray-800 dark:active:bg-gray-900 dark:hover:bg-gray-750',
      'focus:outline-none dark:focus:outline-none focus:ring-1 focus:ring-indigo-500 dark:focus:ring-indigo-500'
    )
  },
  save: {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition ease-in-out duration-150',
      'disabled:cursor-not-allowed',
      'text-white dark:text-white',
      'border-0',
      'bg-emerald-500 hover:bg-emerald-600 dark:bg-emerald-500 hover:bg-emerald-400 dark:hover:bg-emerald-400 active:bg-emerald-600 dark:active:bg-emerald-700',
      'focus:outline-none dark:focus:outline-none focus:ring-2 focus:ring-emerald-300 dark:focus:ring-emerald-700'
    )
  },
  danger: {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition ease-in-out duration-150 antialiased',
      'disabled:cursor-not-allowed',
      'text-white dark:text-white',
      'border-0',
      'bg-red-600 hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-500 active:bg-red-700 dark:active:bg-red-700',
      'focus:outline-none dark:focus:outline-none focus:ring-2 focus:ring-red-300 dark:focus:ring-red-300'
    )
  },
  'danger-invert': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition ease-in-out duration-150 antialiased',
      'disabled:cursor-not-allowed',
      'text-red-600',
      'border-red-600 border',
      'bg-white',
      'focus:outline-none focus:ring-2 focus:ring-red-300'
    )
  },
  'red-btn-100': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition ease-in-out duration-150 antialiased',
      'disabled:cursor-not-allowed',
      'text-white dark:text-white',
      'border-0',
      'bg-polln-orange-100',
      'focus:outline-none focus:ring-2 focus:ring-red-300 '
    )
  },
  'red-btn-100-invert': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition ease-in-out duration-150 antialiased',
      'disabled:cursor-not-allowed',
      'text-polln-orange-100',
      'border-polln-orange-100',
      'bg-white',
      'focus:outline-none focus:ring-2 focus:ring-red-300 '
    )
  },
  borderless: {
    span: 'inline hover', // shadow-sm
    button: clsx(
      'inline text-left border border-transparent hover:border-gray-300 focus:bg-gray-50 transition ease-in-out duration-150 dark:hover:border-gray-600 dark:focus:bg-gray-750',
      'focus:border-indigo-500 dark:focus:border-indigo-500',
      'focus:outline-none focus:ring-1 focus:ring-indigo-500'
    )
  },
  invisible: {
    span: 'inline',
    button: clsx(
      'inline-flex items-center text-left border border-transparent',
      'hover:bg-gray-800 dark:hover:bg-gray-100 bg-opacity-10 hover:bg-opacity-10 dark:hover:bg-opacity-10',
      'focus:outline-none'
    )
  },
  'is-link-100': {
    span: 'inline hover', // shadow-sm
    button: clsx(
      'inline text-left border border-transparent text-polln-solid-green-300 transition ease-in-out duration-150',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'hover:underline'
    )
  },
  'is-link-200': {
    span: 'inline hover', // shadow-sm
    button: clsx(
      'inline text-left border border-transparent text-polln-solid-green-600 transition ease-in-out duration-150',
      'hover:underline font-bold'
    )
  },
  'green-btn-100': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased text-black',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'bg-polln-green-800 hover:shadow-md'
    )
  },
  'green-btn-200': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased text-white',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'bg-polln-green-600 hover:shadow-md'
    )
  },
  'green-btn-300': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased text-white',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'bg-polln-solid-green-400 hover:shadow-md'
    )
  },
  'green-btn-400': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased text-white',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'bg-polln-solid-green-500 hover:shadow-md'
    )
  },
  'green-btn-500': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased text-white',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'bg-polln-solid-green-600 hover:shadow-md'
    )
  },
  'gray-btn-15': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased text-black',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'bg-gray-15 hover:shadow-md'
    )
  },
  'white-btn-100': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased text-black',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'bg-white hover:shadow-md'
    )
  },
  'white-btn-200': {
    span: 'inline-flex', // shadow-sm
    button: clsx(
      'inline-flex items-center font-medium border transition-all ease-in-out duration-150 antialiased text-black',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'bg-polln-gray-300 hover:shadow-md'
    )
  }
};

interface ILinkTo {
  to: To;
  children: ReactNode;
  className?: ClassValue;
  classNameLink?: ClassValue;
  variant?: TVariant;
  size?: TButtonSize;
  rounded?: TButtonRound;
  disabled?: boolean;
}

const LinkTo: FC<ILinkTo> = ({
  to,
  children,
  variant = 'secondary',
  size = 'md',
  rounded = 'rounded',
  className,
  classNameLink,
  disabled = false
}) => {
  return (
    <span
      className={clsx(
        'inline-flex select-none h-full',
        variants[variant].span,
        roundedness[rounded],
        className
      )}
    >
      <Link
        to={to}
        className={clsx(
          'focus:outline-none flex w-full',
          variants[variant].button,
          sizes[size],
          roundedness[rounded],
          classNameLink
        )}
      >
        {children}
      </Link>
    </span>
  );
};

export default LinkTo;
