import React, { FC, ReactNode, useState } from 'react';
import { ClassValue } from 'clsx';
import { useNavigate } from 'react-router-dom';
import { TButtonRound, TButtonSize, TVariant } from 'utils/types';
import useAuth from 'hooks/useAuth';
import { useGlobalLoading } from 'hooks/useGlobalLoading';

// graphql
import { useMutation } from '@apollo/client';
import mutation from 'graphql/mutation';

// components
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalPrivacyConsent from 'components/modals/ModalPrivacyConsent';
import ModalCompleteHealthHistory from 'components/modals/ModalCompleteHealthHistory';
import { toast } from 'components/Toast';

interface IButtonConsultation {
  className?: ClassValue;
  classNameButton?: ClassValue;
  variant?: TVariant;
  rounded?: TButtonRound;
  size?: TButtonSize;
  disabled?: boolean;
  children: ReactNode;
}

const ButtonConsultation: FC<IButtonConsultation> = ({
  className,
  classNameButton,
  variant,
  rounded,
  size,
  disabled = false,
  children
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { setShowLoader } = useGlobalLoading();
  const [openModalHistory, setOpenModalHistory] = useState<boolean>(false);
  const [openModalConsent, setOpenModalConsent] = useState<boolean>(false);

  const [initialConsultation] = useMutation<{
    consultationInitialize: {
      code: string;
      success: boolean;
      message: string;
      consultation: {
        id: string;
      };
    };
  }>(mutation.consultationInitialize, {
    fetchPolicy: 'network-only'
  });

  const handleBooking = async () => {
    setShowLoader(true);
    try {
      const { data } = await initialConsultation();

      if (data?.consultationInitialize.code === '200' && data?.consultationInitialize.success) {
        const {
          consultationInitialize: {
            consultation: { id }
          }
        } = data;

        setShowLoader(false);
        navigate(`/booking/${id}`, { replace: true });
      }
    } catch (err) {
      setShowLoader(false);
      toast({
        title: 'Error',
        message: 'An error occurred',
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Button
        type="button"
        variant={variant}
        className={className}
        classNameButton={classNameButton}
        rounded={rounded}
        size={size}
        onClick={() =>
          user?.health_history && user.health_history.status === 'DONE'
            ? user?.consent_approved
              ? handleBooking()
              : setOpenModalConsent(true)
            : setOpenModalHistory(true)
        }
        disabled={disabled}
      >
        {children}
      </Button>

      <Modal
        open={openModalHistory}
        onClose={setOpenModalHistory}
        round="lg"
        size="sm"
        hasCloseIcon={false}
      >
        <ModalCompleteHealthHistory setOpenModal={setOpenModalHistory} />
      </Modal>

      <Modal
        open={openModalConsent}
        onClose={setOpenModalConsent}
        round="lg"
        size="7xl"
        bgType="transparent"
        hasCloseIcon={false}
      >
        <ModalPrivacyConsent setOpenModal={setOpenModalConsent} />
      </Modal>
    </>
  );
};

export default ButtonConsultation;
