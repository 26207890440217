import React, { FC, useState } from 'react';
import { ICartItem, ICheckoutDetails } from 'utils/models';
import { TShippingDetailsForm } from 'utils/types';

// components
import LoaderSpinner from 'components/LoaderSpinner';
import CartItems from 'components/cart/CartItems';
import CartShipping from 'components/cart/CartShipping';
import CartCheckout from 'components/cart/CartCheckout';
import CardNoCartItem from 'components/cards/CardNoCartItem';
import StepBar from 'components/StepBar';

interface ICart {
  cartItems:
    | {
        cart: ICartItem[];
      }
    | undefined;
  refetch: () => Promise<any> | void;
  step: number;
  setStep: (step: number) => void;
  setCheckoutDetails: (details: ICheckoutDetails) => void;
  close: (error: boolean) => void;
  isLoading?: boolean;
}

const Cart: FC<ICart> = ({
  cartItems,
  refetch,
  step,
  setStep,
  setCheckoutDetails,
  close,
  isLoading
}) => {
  const [shippingDetails, setShippingDetails] = useState<TShippingDetailsForm | null>(null);

  const memoTotalPrice = cartItems?.cart.reduce(
    (total: number, item: ICartItem) => total + +item.escript.product.price * item.quantity,
    0
  );

  return (
    <>
      {isLoading && (
        <div className="w-full fixed bg-black bg-opacity-10 flex justify-center items-center z-[999] h-screen">
          <LoaderSpinner className="w-10 h-20" />
        </div>
      )}

      {cartItems?.cart && cartItems.cart.length > 0 ? (
        <div>
          <div className="space-y-1 w-full">
            <StepBar
              steps={[
                { label: 'Cart', step: 1 },
                { label: 'Shipping', step: 2 },
                { label: 'Checkout', step: 3 }
              ]}
              step={step}
              setStep={setStep}
            />

            {step === 1 && (
              <CartItems
                step={step}
                setStep={setStep}
                totalPrice={memoTotalPrice || 0}
                items={cartItems.cart}
                refetch={refetch}
                isLoading={isLoading}
                showUpdateButtons
                showButtonBottom
                className="h-[calc(100vh-18rem)] md:h-[calc(100vh-17rem)] overflow-y-auto pb-10 md:pb-0"
              />
            )}

            {step === 2 && (
              <CartShipping
                step={step}
                setStep={setStep}
                totalPrice={memoTotalPrice || 0}
                shippingDetails={shippingDetails}
                setShippingDetails={setShippingDetails}
              />
            )}

            {step === 3 && (
              <CartCheckout
                shippingDetails={shippingDetails}
                setCheckoutDetails={setCheckoutDetails}
                totalPrice={memoTotalPrice || 0}
                step={step}
                setStep={setStep}
                close={close}
                items={cartItems.cart}
                refetch={refetch}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      ) : (
        <CardNoCartItem close={close} />
      )}
    </>
  );
};

export default Cart;
