import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { IMenuTypes } from 'utils/models';
import useAuth from 'hooks/useAuth';

// components
import Header from 'components/authenticated/Header';
import HeaderPolln from 'components/HeaderPolln';

const OnBoardingLayout: FC<{ hasHeader?: boolean }> = ({ hasHeader = false }) => {
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const menu: IMenuTypes[] = [];

  if (user?.status === 'ACTIVE') {
    menu.push({
      label: 'Dashboard',
      to: '/'
    });
  }

  menu.push({
    label: 'Logout',
    className: 'text-polln-gray-200',
    onClick: () => {
      logout();
      navigate('/login', { replace: true });
    }
  });

  return (
    <main className="flex flex-col h-screen bg-gradient-to-t from-polln-light-green-1000 to-polln-light-green-900">
      <div className="md:hidden ml-4">
        <HeaderPolln />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <div className="flex flex-1 flex-col">
          {hasHeader && (
            <div className="flex flex-col">
              <Header menu={menu} />
            </div>
          )}

          <div className="flex flex-1 flex-col overflow-y-auto" id="main">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};

export default OnBoardingLayout;
