import { useQuery } from '@apollo/client';
import query from 'graphql/query';

const useWallet = () => {
  const { data, loading } = useQuery<{
    paymentMyWallet: {
      code: string;
      message: string;
      success: boolean;
      wallet: number;
    };
  }>(query.paymentMyWallet, {
    fetchPolicy: 'network-only'
  });
  const walletBalance = data?.paymentMyWallet.wallet || 0;

  return { walletBalance, loading };
};

export default useWallet;
