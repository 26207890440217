import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

const GuestLayout: FC = () => {
  return (
    <div className="h-screen bg-gradient-to-t from-polln-light-green-1000 to-polln-light-green-900">
      <Outlet />
    </div>
  );
};

export default GuestLayout;
