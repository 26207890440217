import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AppStateProvider, { useAppState } from 'contexts/VideoContext';
import useConnectionOptions from 'hooks/useConnectionOptions';

// components
import PageWrap from 'components/PageWrap';
import { VideoProvider } from 'components/video-provider';
import { ChatProvider } from 'components/chat-provider';
import { toast } from 'components/Toast';

const VideoApp: FC = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  useEffect(() => {
    if (error) {
      toast({ title: 'Error', message: error, variant: 'error' });
    }
  }, [error]);

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ChatProvider>
        <div className="m-auto h-[100dvh]">
          <Outlet />
        </div>
      </ChatProvider>
    </VideoProvider>
  );
};

const VideoLayout: FC = () => {
  return (
    <PageWrap title="On going Consultation">
      <AppStateProvider>
        <VideoApp />
      </AppStateProvider>
    </PageWrap>
  );
};

export default VideoLayout;
