import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleWare from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducers } from './reducers';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import { CONF_ENVIRONMENT } from 'utils/config';
import { IS_DEV } from 'utils/constants';

const dev = IS_DEV.includes(CONF_ENVIRONMENT || 'development');
const initialState = {};

// const persistConfig = {
//   key: 'root',
//   storage
// };

// const persistedReducer = persistReducer(persistConfig, rootReducers);

const middleWare = dev
  ? composeWithDevTools(applyMiddleware(thunkMiddleWare))
  : applyMiddleware(thunkMiddleWare);

export default createStore(rootReducers, initialState, compose(middleWare));
// const store = createStore(persistedReducer, initialState, compose(middleWare));
// const persistor = persistStore(store);

// export { store, persistor };
