import React, { FC, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';
import uuid from 'react-uuid';

interface IToggle {
  label?: string | ReactNode;
  className?: ClassValue;
  disabled?: boolean;
  isChecked: boolean;
  onChange: (x: boolean) => void;
}

const Toggle: FC<IToggle> = ({ label, className, disabled = false, isChecked, onChange }) => {
  const toggleId = `toggle-${uuid()}`;
  return (
    <div className={clsx(className, 'form-toggle')}>
      <label htmlFor={toggleId} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            id={toggleId}
            type="checkbox"
            checked={isChecked}
            onChange={(e) => onChange(e.target.checked)}
            className="sr-only"
            disabled={disabled}
          />
          <div className="block bg-polln-gray-500 bg-opacity-40 w-14 h-8 rounded-full" />
          <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition" />
        </div>

        {label && (
          <div className={clsx('ml-3 font-medium', disabled ? 'text-gray-300' : 'text-gray-700')}>
            {label}
          </div>
        )}
      </label>
    </div>
  );
};

export default Toggle;
