import { combineReducers } from 'redux';
import meReducers from './meReducers';
import otherReducers from './otherReducers';

export const rootReducers = combineReducers({
  me: meReducers,
  others: otherReducers
});

export type RootState = ReturnType<typeof rootReducers>;
