import gql from 'graphql-tag';

const mutation = {
  patientLogIn: gql`
    mutation patientLogIn($input: patientLogInInput!) {
      patientLogIn(input: $input) {
        code
        success
        message
        token
        refresh_token
        token_expires
        refresh_token_expires
        otpHash
        verification_code
        patient {
          id
          status
        }
      }
    }
  `,
  patientLogInOtp: gql`
    mutation patientLogInOtp($input: patientLogInOtpInput!) {
      patientLogInOtp(input: $input) {
        code
        success
        message
        token
        refresh_token
        token_expires
        refresh_token_expires
        patient {
          id
          status
        }
      }
    }
  `,
  patientSignUp: gql`
    mutation patientSignUp($input: patientSignUpInput!) {
      patientSignUp(input: $input) {
        code
        success
        message
        token
        refresh_token
        token_expires
        refresh_token_expires
        patient {
          id
          status
        }
      }
    }
  `,
  patientUpdateProfile: gql`
    mutation patientUpdateProfile($input: patientUpdateProfileInput!) {
      patientUpdateProfile(input: $input) {
        code
        success
        message
        patient {
          id
          email
          timezone
          contact_no
          profile {
            patient_id
            first_name
            last_name
            pronoun
            gender
            date_of_birth
            street
            city
            state
            postcode
            country
            medicare_no
            profile_pic
            is_australian_resident
            is_over_18
            tried_conventional_treatment
            medical_symptoms
            medical_conditions
            conditions
            other_conditions
          }
          background {
            patient_id
            cannabis_used
            have_medical_prescription
            cannabis_history
            has_allergy
            allergy
            allergy_severity
            has_surgery
            surgery
            surgery_date
            alcohol_used
            tobacco_used
            exescise_activity
            family_history
            other_family_history
            medical_history
            other_medical_history
            currently_being_treated
            current_clinic
            current_doctor
            current_date
            currently_taking_medication
            current_medication
            current_medication_dose
            current_work_status
            current_work
          }
          phone_verification
          email_verification
          status
          billing_address {
            street
            city
            state
            postcode
          }
          delivery_address {
            street
            city
            state
            postcode
          }
          consultation_status
          last_consultation_date
          consent_approved
          same_delivery
          same_billing
          dva_no
          ihi_no
          ihi_record_status
          ihi_status
        }
      }
    }
  `,
  sendOtp: gql`
    mutation sendOtp($phone: String!) {
      sendOtp(phone: $phone) {
        code
        success
        message
        fullHash
        verification_code
      }
    }
  `,
  verifyOtp: gql`
    mutation verifyOtp($input: verifyOtpInput!) {
      verifyOtp(input: $input) {
        code
        success
        message
      }
    }
  `,
  consultationInitialize: gql`
    mutation consultationInitialize {
      consultationInitialize {
        code
        success
        message
        consultation {
          id
        }
      }
    }
  `,
  consultationSelectDoctorSchedule: gql`
    mutation consultationSelectDoctorSchedule($input: consultationSelectDoctorScheduleInput!) {
      consultationSelectDoctorSchedule(input: $input) {
        code
        success
        message
        consultation {
          id
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            price
            is_default
          }
          enquiry
          step
          status
          schedule_id
        }
      }
    }
  `,
  consultationConfirm: gql`
    mutation consultationConfirm($input: consultationConfirmInput!) {
      consultationConfirm(input: $input) {
        code
        success
        message
        consultation {
          id
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            price
            is_default
          }
          enquiry
          step
          status
        }
      }
    }
  `,
  patientSaveBilling: gql`
    mutation patientSaveBilling($input: addressInput!) {
      patientSaveBilling(input: $input) {
        code
        success
        message
      }
    }
  `,
  patientSaveDelivery: gql`
    mutation patientSaveDelivery($input: addressInput!) {
      patientSaveDelivery(input: $input) {
        code
        success
        message
      }
    }
  `,
  consultationComplete: gql`
    mutation consultationComplete($id: String!) {
      consultationComplete(id: $id) {
        code
        success
        message
      }
    }
  `,
  consultationRequestRepeat: gql`
    mutation consultationRequestRepeat {
      consultationRequestRepeat {
        code
        success
        message
        consultation {
          id
        }
      }
    }
  `,
  patientUploadDocuments: gql`
    mutation patientUploadDocuments($input: patientUploadDocumentsInput) {
      patientUploadDocuments(input: $input) {
        code
        success
        message
        documents {
          filename
          file_url
        }
      }
    }
  `,
  consultationCallJoin: gql`
    mutation consultationCallJoin($id: String!) {
      consultationCallJoin(id: $id) {
        code
        success
        message
        token
        conversation_id
      }
    }
  `,
  paymentPay: gql`
    mutation paymentPay($input: payInput!) {
      paymentPay(input: $input) {
        code
        success
        message
        payment {
          id
          patient_id
          consultation_id
          consultation {
            start_at
            end_at
            reference_id
            doctor {
              profile {
                first_name
                last_name
                profile_pic
              }
            }
            consultation_type {
              capitalize_name
              description
              duration
              id
              price
            }
          }
          invoice
          status
        }
      }
    }
  `,
  consultationCancel: gql`
    mutation consultationCancel($id: String!, $isBlock: Boolean) {
      consultationCancel(id: $id, isBlock: $isBlock) {
        code
        success
        message
      }
    }
  `,
  consultationReschedule: gql`
    mutation consultationReschedule($id: String!, $consultation_timestamp: DateTime!) {
      consultationReschedule(id: $id, consultation_timestamp: $consultation_timestamp) {
        code
        success
        message
      }
    }
  `,
  changePassword: gql`
    mutation changePassword($input: ChangePasswordInput!) {
      changePassword(input: $input) {
        code
        success
        message
      }
    }
  `,
  patientRequestResetPassword: gql`
    mutation patientRequestResetPassword($input: patientRequestResetPasswordInput!) {
      patientRequestResetPassword(input: $input) {
        code
        success
        message
      }
    }
  `,
  patientResetPassword: gql`
    mutation patientResetPassword($input: patientResetPasswordInput!) {
      patientResetPassword(input: $input) {
        code
        success
        message
        token
        refresh_token
        token_expires
        refresh_token_expires
        patient {
          id
          status
        }
      }
    }
  `,
  paymentAddToCart: gql`
    mutation paymentAddToCart($input: cartInput!) {
      paymentAddToCart(input: $input) {
        code
        success
        message
      }
    }
  `,
  paymentRemoveToCart: gql`
    mutation paymentRemoveToCart($id: String!) {
      paymentRemoveToCart(id: $id) {
        code
        success
        message
      }
    }
  `,
  paymentCheckout: gql`
    mutation paymentCheckout($input: checkoutInput!) {
      paymentCheckout(input: $input) {
        code
        success
        message
        data {
          reference_id
          total
          name
          email
          phone
          address {
            street
            state
            city
            postcode
          }
        }
      }
    }
  `,
  paymentRemoveCard: gql`
    mutation paymentRemoveCard($id: String!) {
      paymentRemoveCard(id: $id) {
        code
        message
        success
      }
    }
  `
};

export default mutation;
