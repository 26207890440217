import React, { FC } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { IMenuTypes } from 'utils/models';
import useAuth from 'hooks/useAuth';

// components
import Header from 'components/authenticated/Header';
import SideBar from 'components/SideBar';
import MobileNavigationBottom from 'components/MobileNavigationBottom';

interface IAuthLayout {
  hasTopBar?: boolean;
  hasSideBar?: boolean;
  hasBookingAppointment?: boolean;
}

const AuthLayout: FC<IAuthLayout> = ({
  hasSideBar = false,
  hasTopBar = false,
  hasBookingAppointment = false
}) => {
  const { user, logout, isAdmin } = useAuth();
  const navigate = useNavigate();

  // sticky top-0 z-50
  const menu: IMenuTypes[] = [
    {
      label: 'My Profile',
      to: '/profile'
    },
    {
      label: 'Change Password',
      to: '/change-password'
    },
    {
      label: 'FAQ',
      href: 'https://www.polln.com/faqs',
      target: '_blank'
    },
    {
      label: 'Terms & Conditions',
      href: 'https://www.polln.com/terms-and-conditions',
      target: '_blank'
    },
    {
      label: 'Privacy Policy',
      href: 'https://www.polln.com/privacy-policy',
      target: '_blank'
    },
    {
      label: 'Logout',
      onClick: () => {
        logout();
        navigate('/login', { replace: true });
      }
    }
  ];

  return (
    <main
      className={clsx(
        'flex flex-col bg-polln-light-green-500',
        'md:h-screen bg-polln-no-human bg-opacity-90 bg-no-repeat bg-center bg-cover bg-blend-overlay bg-center'
      )}
    >
      <div className="md:flex flex-1 md:overflow-hidden">
        <div className="flex flex-1 flex-col">
          {hasTopBar && (
            <div
              className={clsx(
                'flex flex-col bg-polln-light-green-500 relative z-10',
                'md:shadow-md md:bg-white',
                !isAdmin && 'px-3 py-0.5'
              )}
            >
              <Header
                menu={menu}
                hasBookingAppointment={hasBookingAppointment}
                hasCart={user?.checkout_enabled}
              />
            </div>
          )}

          <div className="flex flex-1 overflow-y-auto h-full">
            {hasSideBar && (
              <div className="hidden md:block">
                <SideBar />
              </div>
            )}

            <div className="flex flex-col flex-1 overflow-y-auto ">
              <div className="w-full mx-auto md:pt-5 pb-10 md:pb-0 " id="main">
                <Outlet />
              </div>

              <div className="flex md:hidden bottom-0 z-10 w-full fixed">
                <MobileNavigationBottom hasCart={user?.checkout_enabled} />
              </div>
            </div>
          </div>
        </div>

        {hasSideBar && (
          <div className="md:hidden flex items-center">
            <SideBar />
          </div>
        )}
      </div>
    </main>
  );
};

export default AuthLayout;
