import React, { FC } from 'react';
import { Link, To, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import Button from './Button';
import { XIcon } from '@heroicons/react/outline';
import Accordion from './Accordion';
import { CONF_SEO_URL } from 'utils/config';

interface IMobileMenu {
  setIsDrawerOpen: (open: boolean) => void;
}

interface MenuLink {
  label: string;
  link: To | string;
}

const MY_DASHBOARD: MenuLink[] = [
  {
    label: 'My Profile',
    link: '/profile'
  },
  {
    label: 'My Documents',
    link: '/documents'
  },
  {
    label: 'My Orders',
    link: '/orders'
  },
  {
    label: 'Change Password',
    link: '/change-password'
  }
];

const ABOUT_POLLN: MenuLink[] = [
  {
    label: 'FAQ',
    link: `${CONF_SEO_URL}/faqs`
  },
  {
    label: 'Medicinal Cannabis Library',
    link: `${CONF_SEO_URL}/medicinal-cannabis-library`
  },
  {
    label: 'Patient Resource Portal',
    link: `${CONF_SEO_URL}/patient-portal`
  },
  {
    label: 'Terms & Conditions',
    link: `${CONF_SEO_URL}/terms-and-conditions`
  },
  {
    label: 'Privacy Policy',
    link: `${CONF_SEO_URL}/privacy-policy`
  }
];

const MobileMenu: FC<IMobileMenu> = ({ setIsDrawerOpen }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/login', { replace: true });
  };

  return (
    <>
      <div className="text-lg font-medium p-4 capitalize flex items-center">
        <Button variant="invisible" size="inline" onClick={() => setIsDrawerOpen(false)}>
          <XIcon className="w-8 h-8" />
        </Button>
      </div>
      <div className="px-5 py-1 space-y-5 text-sm">
        <Accordion
          title="My polln dashboard"
          rounded="xl"
          classNameTitle="uppercase font-extrabold p-x-8 py-2"
          shadow="lg"
          open
        >
          <div className="divide-y">
            {MY_DASHBOARD.map((item: MenuLink, index: number) => (
              <div key={index}>
                <Link
                  to={item.link}
                  onClick={() => setIsDrawerOpen(false)}
                  className="uppercase text-polln-solid-green-400 text-sm !font-extrabold px-8 py-4 block w-full"
                >
                  {item.label}
                </Link>
              </div>
            ))}
          </div>
        </Accordion>

        <Accordion
          title="About Polln"
          rounded="xl"
          classNameTitle="uppercase !font-extrabold p-x-8 py-2"
          shadow="lg"
        >
          <div className="divide-y">
            {ABOUT_POLLN.map((item: MenuLink, index: number) => (
              <div key={index}>
                <a
                  href={item.link as string}
                  className="uppercase text-polln-solid-green-400 text-sm !font-extrabold px-8 py-4 block w-full"
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.label}
                </a>
              </div>
            ))}
          </div>
        </Accordion>

        <div>
          <Button
            variant="invisible"
            size="inline"
            className="w-full"
            classNameButton="uppercase !bg-white rounded-xl font-bold shadow-lg px-4 py-5 font-extrabold"
            onClick={() => handleLogout()}
          >
            Log Out
          </Button>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
