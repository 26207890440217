import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IMeResponse } from 'utils/models';
import { CONF_SEO_URL } from 'utils/config';

// gql
import { useMutation } from '@apollo/client';
import mutation from 'graphql/mutation';

// components
import Card from 'components/Card';
import FormCheckbox from 'components/forms/FormCheckbox';
import PrivacyConsentTerms from 'components/privacy-consent/PrivacyConsentTerms';
import Button from 'components/Button';
import { toast } from 'components/Toast';

type TFormData = {
  consent: boolean;
  readConsent: boolean;
};

const validationSchema = Yup.object().shape({
  consent: Yup.bool().oneOf([true], ' the above information'),
  readConsent: Yup.bool().oneOf([true], ' our Terms & Conditions.')
});

const ModalPrivacyConsent: FC<{ setOpenModal: (open: boolean) => void }> = ({ setOpenModal }) => {
  const navigate = useNavigate();

  const [updateMe] = useMutation<{
    patientUpdateProfile: IMeResponse;
  }>(mutation.patientUpdateProfile, {
    fetchPolicy: 'network-only'
  });

  const [initialConsultation] = useMutation<{
    consultationInitialize: {
      code: string;
      success: boolean;
      message: string;
      consultation: {
        id: string;
      };
    };
  }>(mutation.consultationInitialize, {
    fetchPolicy: 'network-only'
  });

  const onSubmit = async (values: TFormData) => {
    try {
      if (values.consent && values.readConsent) {
        const { data: meResp } = await updateMe({
          variables: {
            input: {
              consent_approved: true
            }
          }
        });

        if (meResp?.patientUpdateProfile.code === '200' && meResp.patientUpdateProfile.success) {
          const { data } = await initialConsultation({ refetchQueries: ['patientMe'] });

          if (data?.consultationInitialize.code === '200' && data?.consultationInitialize.success) {
            const {
              consultationInitialize: {
                consultation: { id }
              }
            } = data;

            setOpenModal(false);
            navigate(`/booking/${id}`, { replace: true });
          }
        }
      }
    } catch (err) {
      toast({
        title: 'Error',
        message: 'An error occured',
        variant: 'error'
      });
    }
  };

  const initialValues: TFormData = {
    consent: false,
    readConsent: false
  };
  return (
    <div className="h-[calc(100dvh)]">
      <div className="p-2 container m-auto">
        <div className="grid gap-3 md:gap-10 grid-cols-12">
          <div className="col-span-12">
            <Card>
              <div className="space-y-3">
                <PrivacyConsentTerms />

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formikProps) => {
                    const { touched, errors, handleSubmit, isSubmitting, values } = formikProps;
                    const errMessage: string[] = [];

                    if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
                      Object.values(errors).forEach((v: any) => {
                        errMessage.push(v);
                      });
                    }

                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className="space-y-3 text-sm">
                          <div className="space-y-2 p-4">
                            <FormCheckbox
                              name="consent"
                              label="I have read and consent to the information above."
                            />

                            <FormCheckbox
                              name="readConsent"
                              label={
                                <>
                                  I have read and accepted the&nbsp;
                                  <a
                                    href={`${CONF_SEO_URL}/terms-and-conditions`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="underline text-polln-solid-green-400"
                                  >
                                    Polln Terms &amp; Conditions
                                  </a>
                                </>
                              }
                            />
                          </div>
                          <div className="ml-4 !-mt-5">
                            {errMessage.length > 0 && (
                              <div className="text-red-500 my-4 text-left text-md">
                                To book a consultation with Polln, you must read, consent and accept{' '}
                                {`${errMessage.join(' and')}`}
                              </div>
                            )}
                          </div>
                          <div className="flex justify-center flex-col md:flex-row">
                            <Button
                              type="submit"
                              size="md"
                              variant="green-btn-300"
                              className="md:w-1/3 h-13"
                              rounded="xl"
                              classNameButton="uppercase font-extrabold text-md justify-center py-4 my-4 mx-4 md:my-4"
                              disabled={isSubmitting || !(values.consent && values.readConsent)}
                              isLoading={isSubmitting}
                            >
                              Accept and book consult
                            </Button>
                            <Button
                              variant="red-btn-100-invert"
                              rounded="xl"
                              size="md"
                              className="md:w-1/3 h-13"
                              classNameButton="uppercase font-extrabold text-md justify-center py-4 my-1 mx-4 md:my-4"
                              onClick={() => setOpenModal(false)}
                            >
                              Decline and Go Back
                            </Button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPrivacyConsent;
