import React, { FC, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';

interface ICheckbox {
  value: boolean;
  label?: string | ReactNode;
  className?: ClassValue;
  classNameInput?: ClassValue;
  disabled?: boolean;
  onChange: (x: boolean) => void;
}

const Checkbox: FC<ICheckbox> = ({
  value,
  label,
  className,
  classNameInput,
  disabled = false,
  onChange
}) => {
  return (
    <div className="w-full block">
      <div className={clsx(className, 'form-checkbox')}>
        <label className="text-md leading-5 !text-gray-500 label-checkbox">
          {label && label}
          <input
            type="checkbox"
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            className={clsx(classNameInput, {
              'text-indigo-600': !disabled,
              'text-gray-500': disabled
            })}
            disabled={disabled}
          />
          <span className="checkmark" />
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
