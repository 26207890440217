import React, { FC, ReactNode } from 'react';

interface IStepNumber {
  step: number;
  text: string;
  subText?: ReactNode;
}

const StepNumber: FC<IStepNumber> = ({ step, text, subText }) => (
  <div className="flex items-center space-x-2">
    <div className="rounded-full text-white bg-black text-xs p-0.5 font-bold h-5 w-5 text-center">
      {step}
    </div>
    <span className="font-bold capitalize">{text}</span>
    {subText}
  </div>
);

export default StepNumber;
