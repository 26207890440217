import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

// grapqhl
import { useMutation } from '@apollo/client';
import { IMeResponse } from 'utils/models';
import mutation from 'graphql/mutation';

// components
import Button from 'components/Button';
import { toast } from 'components/Toast';

// assets
import UserPlusImage from 'assets/images/user-plus.png';

const ModalCompleteHealthHistory: FC<{ setOpenModal: (x: boolean) => void }> = ({
  setOpenModal
}) => {
  const navigate = useNavigate();
  const { getMe, user } = useAuth();

  const [updateMe, { loading }] = useMutation<{
    patientUpdateProfile: IMeResponse;
  }>(mutation.patientUpdateProfile, {
    fetchPolicy: 'network-only'
  });

  const handleCheckStep = async () => {
    if (user?.profile && user.profile.onboarding_step <= 4) {
      try {
        const { data } = await updateMe({
          variables: {
            input: {
              onboarding_step: 5,
              health_status: 'PENDING'
            }
          }
        });

        if (data?.patientUpdateProfile.code === '200' && data.patientUpdateProfile.success) {
          getMe();
          navigate('/health-history', { replace: true });
        }

        if (data?.patientUpdateProfile && !data.patientUpdateProfile.success) {
          toast({
            title: 'Error',
            message: data.patientUpdateProfile.message,
            variant: 'error'
          });
        }
      } catch (error) {
        toast({
          title: 'Error',
          message: 'An error occurred',
          variant: 'error'
        });
      }
    } else {
      navigate('/health-history', { replace: true });
    }
  };

  return (
    <div className="space-y-7 text-center pt-8 pb-2 px-5">
      <img src={UserPlusImage} alt="User Plus" className="d-block mx-auto w-14" />

      <div className="font-base">
        <div className="uppercase font-bold text-polln-orange-100">Important:</div>
        <div>
          You must complete your{' '}
          <Button
            variant="is-link-200"
            rounded="lg"
            classNameButton="space-x-2 font-bold underline"
            onClick={handleCheckStep}
            size="inline"
            disabled={loading}
          >
            Health History
          </Button>{' '}
          before your first consultation.
        </div>
      </div>

      <div className="space-y-5 md:flex md:space-y-0 md:space-x-4">
        <Button
          variant="green-btn-200"
          className="w-full h-12 md:h-10"
          classNameButton="justify-center uppercase text-sm font-extrabold"
          rounded="xl"
          onClick={handleCheckStep}
          isLoading={loading}
        >
          Now
        </Button>

        <Button
          variant="red-btn-100-invert"
          className="w-full h-12 md:h-10"
          classNameButton="justify-center uppercase text-sm font-extrabold"
          rounded="xl"
          onClick={() => setOpenModal(false)}
          isLoading={loading}
        >
          Later
        </Button>
      </div>
    </div>
  );
};

export default ModalCompleteHealthHistory;
