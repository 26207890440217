import React, { FC, Fragment, ReactNode, useEffect } from 'react';

interface IPageWrap {
  title: string;
  pageTitle?: string;
  children: ReactNode;
}

const PageWrap: FC<IPageWrap> = ({ title, pageTitle, children }) => {
  useEffect(() => {
    document.title = `Polln | ${title}`;
  }, [title]);

  return (
    <Fragment>
      {pageTitle && (
        <div className="uppercase text-lg text-polln-green-900 px-6 pt-6">{pageTitle}</div>
      )}
      {children}
    </Fragment>
  );
};

export default PageWrap;
