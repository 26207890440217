import React, { FC, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { XIcon } from '@heroicons/react/solid';
import { ICartItem, ICheckoutDetails } from 'utils/models';
import useAuth from 'hooks/useAuth';

// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import { CONF_STRIPE_ID } from 'utils/config';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import { actions } from 'store/actions';

// graphql
import { useLazyQuery } from '@apollo/client';
import query from 'graphql/query';

// components
import Card from 'components/Card';
import Button from 'components/Button';
import Drawer from 'components/Drawer';
import Cart from 'components/cart/Cart';
import CartSuccess from 'components/cart/CartSuccess';
import CartConsent from 'components/cart/CartConsent';
import { SvgCart } from 'components/SvgIcons';

// const stripePromise = loadStripe(CONF_STRIPE_ID || '');

const ButtonViewCart: FC = () => {
  // const navigate = useNavigate();

  const { user } = useAuth();

  const reducers = useSelector((state: RootState) => state.others);
  const cartItemReducers = reducers.cartItems as ICartItem[];
  const dispatch = useDispatch();

  const [showCart, setShowCart] = useState<boolean>(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
  const [checkoutDetails, setCheckoutDetails] = useState<ICheckoutDetails | null>(null);
  const [step, setStep] = useState<number>(1);

  const [getCartItem, { data, loading }] = useLazyQuery<{
    paymentMyCart: {
      code: string;
      success: boolean;
      message: string;
      cart: ICartItem[];
    };
  }>(query.paymentMyCart, {
    fetchPolicy: 'cache-and-network'
  });
  const cartItems = data?.paymentMyCart;

  const fetchCartItem = useCallback(async () => {
    await getCartItem();
    dispatch(actions.refetchEscript(true));
  }, [getCartItem, dispatch]);

  const handleShowCart = async () => {
    setShowCart(true);
    await fetchCartItem();
  };

  const handleClose = async (error: boolean) => {
    if (!error) {
      await fetchCartItem();
      setIsPaymentSuccess(true);
    }

    if (error) {
      setShowCart(false);
    }
  };

  const handleGoToDashboard = () => (window.location.href = '/');

  useEffect(() => {
    if (cartItems) {
      dispatch(actions.getCartItems(cartItems.cart));
    }
  }, [cartItems, dispatch]);

  useEffect(() => {
    fetchCartItem();
  }, [fetchCartItem]);

  return (
    <>
      <Button
        rounded="none"
        size="xs"
        className={clsx('mt-1 flex-1', 'block md:hidden')}
        classNameButton={clsx('flex-col space-y-1', 'text-gray-500')}
        variant="invisible"
        onClick={() => handleShowCart()}
      >
        <div className="relative">
          <SvgCart className="h-5 w-5" />
          <span>Cart</span>
          {cartItemReducers.length > 0 && (
            <div className="w-3 h-3 bg-[#CE744B] rounded-full absolute -top-1 -right-1" />
          )}
        </div>
      </Button>

      <div className="relative hidden md:block">
        <Button variant="invisible" size="inline" onClick={() => handleShowCart()}>
          <SvgCart className="h-8 w-8 text-black" />
        </Button>

        {cartItemReducers.length > 0 && (
          <div className="animate-ping w-4 h-4 bg-[#CE744B] rounded-full absolute top-1 right-1 md:-top-1 md:-right-1" />
        )}
      </div>

      <Drawer
        isOpen={showCart}
        setIsOpen={(open: boolean) => {
          isPaymentSuccess ? handleGoToDashboard() : setShowCart(open);
          setStep(1);
        }}
        size={
          step === 3 && !isPaymentSuccess && cartItems && cartItems?.cart.length > 0 ? '6xl' : '2xl'
        }
      >
        <div className="p-5 text-lg font-medium leading-6 p-4 capitalize flex justify-end items-center">
          <Button
            variant="invisible"
            onClick={() => {
              isPaymentSuccess ? handleGoToDashboard() : setShowCart(false);
              setStep(1);
            }}
          >
            <XIcon className="w-5 h-5 md:w-6 md:h-6" />
          </Button>
        </div>

        {user?.shop_consent_approved ? (
          isPaymentSuccess ? (
            <div className="p-6">
              <Card className="!p-0 bg-gray-25">
                {checkoutDetails && <CartSuccess checkoutDetails={checkoutDetails} />}
                <div className="p-5 text-center mt-5">
                  <Button
                    variant="green-btn-500"
                    size="xl"
                    rounded="lg"
                    className="w-full h-14"
                    classNameButton="justify-center !text-sm uppercase !font-extrabold"
                    onClick={handleGoToDashboard}
                  >
                    My Dashboard
                  </Button>
                </div>
              </Card>
            </div>
          ) : (
            <Cart
              cartItems={cartItems}
              setCheckoutDetails={setCheckoutDetails}
              refetch={fetchCartItem}
              step={step}
              setStep={setStep}
              close={handleClose}
              isLoading={loading}
            />
          )
        ) : (
          <CartConsent close={handleClose} />
        )}
      </Drawer>
    </>
  );
};

export default ButtonViewCart;
