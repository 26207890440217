import React, { FC, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';
import { getIn, useFormikContext } from 'formik';

interface IFormCheckbox {
  label?: string | ReactNode;
  name: string;
  className?: ClassValue;
  classNameInput?: ClassValue;
  classNameLabel?: ClassValue;
  disabled?: boolean;
  onChange?: (x: boolean) => void;
}

const FormCheckbox: FC<IFormCheckbox> = ({
  label,
  name,
  className,
  classNameInput,
  classNameLabel,
  disabled = false,
  onChange
}) => {
  const { handleChange, values } = useFormikContext();
  return (
    <div className="w-full block">
      <div className={clsx(className, 'form-checkbox')}>
        <label className={clsx('text-sm text-black label-checkbox', classNameLabel)}>
          {label && label}
          <input
            type="checkbox"
            name={name}
            value={getIn(values, name)}
            checked={getIn(values, name)}
            onChange={(e) => {
              handleChange(e);
              if (onChange) onChange(e.target.checked);
            }}
            className={clsx(classNameInput, {
              'text-indigo-600': !disabled,
              'text-gray-500': disabled
            })}
            disabled={disabled}
          />
          <span className="checkmark" />
        </label>
      </div>
    </div>
  );
};

export default FormCheckbox;
