import React, { ElementType, FC, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';
import { getIn, useFormikContext, ErrorMessage } from 'formik';

interface IFormInput {
  as?: ElementType | string;
  label?: string;
  labelClassName?: ClassValue;
  labelAction?: ReactNode;
  labelPlacement?: 'top' | 'left';
  name: string;
  placeholder?: string;
  type?: string;
  className?: ClassValue;
  isRequired?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
  rows?: number;
  leftLabel?: ReactNode;
  leftLabelClassName?: ClassValue;
  actions?: ReactNode;
  variant?: 'normal' | 'white' | 'green';
  height?: 'normal' | 'full';
  maxLength?: number;
  onChange?: (value: any) => void;
  isErrorBelow?: boolean;
  classNameErrorText?: ClassValue;
}

const variants = {
  normal: clsx(
    'border-gray-300 border',
    'focus:outline-none focus:ring-1 focus:ring-blue-100 focus:border-transparent',
    'hover:outline-none hover:ring-1 hover:ring-blue-100 hover:border-transparent'
  ),
  white: clsx(
    'border-polln-white-500 border',
    'focus:outline-none focus:ring-1 focus:ring-polln-white-500 focus:border-transparent',
    'hover:outline-none hover:ring-1 hover:ring-polln-white-500 hover:border-transparent',
    'bg-polln-gray-300 text-polln-black-300 placeholder-polln-black-400'
  ),
  green: clsx(
    'border-gray-300 border-r border-y',
    'focus:outline-none focus:border-blue-100',
    'hover:outline-none hover:border-blue-100',
    'bg-polln-white-100 text-polln-black-300 placeholder-gray-400'
  )
};

const heights = {
  normal: '',
  full: 'h-full'
};

const FormInput: FC<IFormInput> = ({
  as = 'input',
  label,
  labelClassName,
  labelAction,
  labelPlacement = 'top',
  name,
  placeholder,
  type = 'text',
  className,
  isRequired = false,
  icon,
  disabled = false,
  rows = 4,
  leftLabel,
  leftLabelClassName,
  actions,
  variant = 'normal',
  height = 'normal',
  maxLength,
  onChange,
  isErrorBelow = false,
  classNameErrorText
}) => {
  const { errors, touched, values, handleChange } = useFormikContext();
  const Comp = as;

  return (
    <div
      className={clsx(
        'w-full block',
        labelPlacement === 'left' && 'flex items-center space-x-16',
        heights[height]
      )}
    >
      <div
        className={clsx(
          (icon || label) && labelPlacement === 'top' ? 'mb-3' : 'w-24',
          'flex items-center gap-2'
        )}
      >
        <div className="flex space-x-2">
          {icon && icon}
          {label && (
            <label htmlFor={name} className={clsx('text-sm', labelClassName)}>
              {label} {isRequired && <span className="text-red-800">*</span>}
            </label>
          )}
        </div>
        <div>{labelAction && labelAction}</div>
      </div>

      <div
        className={clsx(
          'flex items-center relative',
          labelPlacement === 'left' && 'grow',
          heights[height]
        )}
      >
        {leftLabel && (
          <div className={clsx(leftLabelClassName)}>
            <span className="text-gray-400">{leftLabel}</span>
          </div>
        )}

        <Comp
          id={name}
          type={type}
          name={name}
          placeholder={placeholder}
          className={clsx(
            getIn(errors, name) && getIn(touched, name)
              ? clsx(
                  'border border-red-400',
                  'focus:outline-none focus:ring-1 focus:ring-red-400 focus:border-transparent',
                  'hover:outline-none hover:ring-1 hover:ring-red-400 hover:border-transparent'
                )
              : variants[variant],
            'w-full p-2 disabled:bg-gray-100 disabled:cursor-not-allowed',
            className
          )}
          value={getIn(values, name)}
          onChange={(e: any) => {
            handleChange(e);
            if (onChange) onChange(e.target.value);
          }}
          maxLength={maxLength}
          min={0}
          disabled={disabled}
          rows={rows}
        />

        {actions && <div className="absolute right-0 top-0 h-full">{actions}</div>}
      </div>

      {isErrorBelow && (
        <ErrorMessage
          name={name}
          component="div"
          className={clsx('text-red-500', classNameErrorText)}
        />
      )}
    </div>
  );
};

export default FormInput;
