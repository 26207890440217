import React, { FC, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';
import { getIn, useFormikContext, ErrorMessage } from 'formik';
import ReactSelect from 'react-select';
import { ISelectOptions } from 'utils/models';

interface IFormSelect {
  name: string;
  options: ISelectOptions[];
  label?: string;
  labelClassName?: ClassValue;
  labelPlacement?: 'top' | 'left';
  className?: ClassValue;
  isRequired?: boolean;
  placeholder?: string;
  icon?: ReactNode;
  round?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'r-2xl' | 'full';
  leftLabel?: ReactNode;
  leftLabelClassName?: ClassValue;
  variant?: 'normal' | 'white' | 'green';
  disabled?: boolean;
  isErrorBelow?: boolean;
}

const customStyles = {
  option: (style: any, state: any) => {
    const customColors = {
      ...style,
      color: state.isFocused ? '#ffffff' : '#000000',
      backgroundColor: state.isFocused ? '#0F5342' : '#ffffff',
      ':active': {
        ...style[':active'],
        backgroundColor: '#0F5342'
      }
    };

    return customColors;
  }
};

const FormSelect: FC<IFormSelect> = ({
  name,
  label,
  labelClassName,
  labelPlacement = 'top',
  className,
  options,
  isRequired = false,
  placeholder,
  isErrorBelow = false,
  icon,
  round = 'md',
  variant = 'normal',
  leftLabel,
  leftLabelClassName,
  disabled = false
}) => {
  const { setFieldValue, errors, touched, values } = useFormikContext();

  const optionValue = options.find((item: ISelectOptions) => item.value === getIn(values, name));

  return (
    <div
      className={clsx('w-full block', labelPlacement === 'left' && 'flex items-center space-x-16')}
    >
      <div
        className={clsx(
          (icon || label) && labelPlacement === 'top' ? 'mb-3' : 'w-24',
          'flex items-center gap-2'
        )}
      >
        {icon && icon}
        {label && (
          <label className={clsx('text-sm', labelClassName)}>
            {label} {isRequired && <span className="text-red-800">*</span>}
          </label>
        )}
      </div>

      <div className={clsx('flex items-center relative', labelPlacement === 'left' && 'grow')}>
        {leftLabel && (
          <div className={clsx(leftLabelClassName)}>
            <span className="text-gray-400">{leftLabel}</span>
          </div>
        )}

        <ReactSelect
          styles={{
            ...customStyles,
            menu: (provided) => ({ ...provided, zIndex: 99999 }),
            menuPortal: (provided) => ({ ...provided, zIndex: 99999, position: 'fixed' })
          }}
          options={options}
          onChange={(x) => setFieldValue(name, x?.value)}
          value={optionValue}
          classNamePrefix="form-select"
          placeholder={placeholder}
          className={clsx(
            className,
            getIn(errors, name) && getIn(touched, name) && clsx('error'),
            'relative text-left form-select w-full',
            `round-${round}`,
            variant
          )}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#dbeafe'
            }
          })}
          menuPlacement="auto"
          menuPortalTarget={document.getElementById('select-portal')}
          isDisabled={disabled}
          menuShouldBlockScroll
        />
      </div>
      {isErrorBelow && <ErrorMessage name={name} component="div" className="text-red-500" />}
    </div>
  );
};

export default FormSelect;
