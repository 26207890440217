import { USER_ME, USER_LOGIN, USER_LOGOUT, USER_ONBOARD } from 'store/types';
import { IActionPayload } from 'store/actions/actions';
import { IUser, IUserToken } from 'utils/models';

export type TMeReducerState = {
  user: IUser | IUserToken | null;
};

const initialState: TMeReducerState = {
  user: null
};

const reducers = (state: TMeReducerState = initialState, action: IActionPayload) => {
  switch (action.type) {
    case USER_ME:
      return {
        ...state,
        user: action.payload
      };
    case USER_ONBOARD:
      return {
        ...state,
        isOnBoarding: action.payload
      };
    case USER_LOGIN:
      return {
        ...state,
        isLoggedIn: true
      };
    case USER_LOGOUT:
      return {
        ...state,
        user: null
      };
    default:
      return state;
  }
};

export default reducers;
