import { createContext, useContext } from 'react';

type TGlobalLoading = { showLoader: boolean; setShowLoader: (x: boolean) => void };

export const GlobalLoading = createContext<TGlobalLoading>({
  showLoader: false,
  setShowLoader: (x: boolean) => {}
});

export const useGlobalLoading = () => useContext(GlobalLoading);
