import React, { FC, ReactNode, useEffect, useRef } from 'react';
import clsx, { ClassValue } from 'clsx';
import { TModalSize } from 'utils/types';

interface IDrawer {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  className?: ClassValue;
  children: ReactNode;
  size?: TModalSize;
}

const sizes = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
  full: 'w-full'
};

const Drawer: FC<IDrawer> = ({ children, isOpen, setIsOpen, size = 'xs', className }) => {
  const bodyRef = useRef(document.querySelector('body'));

  /**
   * to disable body scrolling
   */
  useEffect(() => {
    const updatePageScroll = () => {
      if (bodyRef.current) {
        if (isOpen) {
          bodyRef.current.style.overflow = 'hidden';
        } else {
          bodyRef.current.style.overflow = '';
        }
      }
    };

    updatePageScroll();
  }, [isOpen]);

  return (
    <div
      className={clsx(
        'fixed overflow-hidden inset-0 transform ease-in-out',
        'bg-gray-900 bg-opacity-25 z-20 md:z-10',
        isOpen
          ? 'transition-opacity opacity-100 duration-500 translate-x-0'
          : 'delay-500 opacity-0 translate-x-full'
        // isOpen && 'transition-opacity opacity-100 duration-500 translate-x-0'
      )}
    >
      <div
        className={clsx(
          sizes[size],
          'w-screen right-0 absolute bg-zinc-50 h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform',
          isOpen ? 'translate-x-0' : 'translate-x-full'
        )}
      >
        <div
          className={clsx(sizes[size], className, 'relative w-screen pb-10 flex flex-col h-full')}
        >
          {isOpen && children}
        </div>
      </div>
      <div className="w-screen h-full cursor-pointer" onClick={() => setIsOpen(false)} />
    </div>
  );
};

export default Drawer;
