import React, { FC } from 'react';
import ReactGA from 'react-ga4';
import { AuthProvider } from 'contexts/AuthContext';
import Router from './Router';
import { CONF_ENVIRONMENT, CONF_GA4 } from 'utils/config';

if (CONF_ENVIRONMENT === 'production') {
  const TRACKING_ID = CONF_GA4;
  ReactGA.initialize(TRACKING_ID as string);
}

const App: FC = () => (
  <AuthProvider>
    <Router />
  </AuthProvider>
);

export default App;
