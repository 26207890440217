import React, { FC, Suspense, lazy, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { GlobalLoading } from 'hooks/useGlobalLoading';

// components
import Loadable from 'components/Loadable';
import LoaderSpinner from 'components/LoaderSpinner';

// layouts
import GuestLayout from 'layout/GuestLayout';
import AuthLayout from 'layout/AuthLayout';
import OnBoardingLayout from 'layout/OnBoardingLayout';
import VideoLayout from 'layout/VideoLayout';
import ScrollToTop from 'components/ScrollToTop';

// pages with loadable component
const NotFound = Loadable(lazy(() => import('pages/error/404')));
const AdminAuthorization = Loadable(lazy(() => import('pages/guest/AdminAuthorization')));
const Login = Loadable(lazy(() => import('pages/guest/Login')));
const SignUp = Loadable(lazy(() => import('pages/guest/SignUp')));
const ForgotPassword = Loadable(lazy(() => import('pages/guest/ForgotPassword')));
const OnBoarding = Loadable(lazy(() => import('pages/authenticated/OnBoarding')));
const HealthHistory = Loadable(lazy(() => import('pages/authenticated/HealthHistory')));
const Dashboard = Loadable(lazy(() => import('pages/authenticated/Dashboard')));
const Consultations = Loadable(lazy(() => import('pages/authenticated/Consultations')));
const Prescriptions = Loadable(lazy(() => import('pages/authenticated/Prescriptions')));
const Documents = Loadable(lazy(() => import('pages/authenticated/Documents')));
const Profile = Loadable(lazy(() => import('pages/authenticated/Profile')));
const ChangePassword = Loadable(lazy(() => import('pages/authenticated/ChangePassword')));
const Booking = Loadable(lazy(() => import('pages/authenticated/Booking')));
const Reschedule = Loadable(lazy(() => import('pages/authenticated/Reschedule')));
const WaitingRoom = Loadable(lazy(() => import('pages/authenticated/WaitingRoom')));
const MeetingRoom = Loadable(lazy(() => import('pages/authenticated/MeetingRoom')));
const Orders = Loadable(lazy(() => import('pages/authenticated/Orders')));
const ResetPassword = Loadable(lazy(() => import('pages/common/ResetPassword')));

const Router: FC = () => {
  const { isLoading, isLoggedIn, isOnBoarding, token, user } = useAuth();
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: 'Test Page View'
    });
  }, []);

  return (
    <GlobalLoading.Provider value={{ showLoader, setShowLoader }}>
      {showLoader && (
        <div className="flex justify-center items-center fixed h-full w-full z-50 bg-black bg-opacity-50">
          <LoaderSpinner className="w-10 h-20" />
        </div>
      )}
      <Suspense fallback={null}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              {isLoading ? (
                <Route
                  path="*"
                  element={
                    <div className="flex justify-center items-center fixed h-full w-full">
                      <LoaderSpinner className="w-10 h-20" />
                    </div>
                  }
                />
              ) : (
                <>
                  <Route path="/authorization" element={<AdminAuthorization />} />

                  {!isLoggedIn && !token && (
                    <Route path="/" element={<GuestLayout />}>
                      {/* <Route path="/" element={<Navigate to="/login" />} /> */}
                      <Route index element={<SignUp />} />
                      <Route path="login" element={<Login />} />
                      <Route path="sign-up" element={<SignUp />} />
                      <Route path="forgot-password" element={<ForgotPassword />} />
                      <Route path="reset-password/:token" element={<ResetPassword />} />
                      <Route path="*" element={<NotFound />} />
                    </Route>
                  )}

                  {isLoggedIn && token && !isOnBoarding && user && (
                    <>
                      <Route
                        path="/"
                        element={<AuthLayout hasSideBar hasTopBar hasBookingAppointment />}
                      >
                        <Route path="*" element={<Navigate to="/dashboard" />} />
                        <Route index element={<Dashboard />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="consultations" element={<Consultations />} />
                        <Route path="prescriptions" element={<Prescriptions />} />
                        <Route path="documents" element={<Documents />} />
                        <Route path="orders" element={<Orders />} />
                        <Route path="booking/:bookingId" element={<Booking />} />
                        <Route path="reschedule/:bookingId" element={<Reschedule />} />
                      </Route>

                      <Route path="/" element={<AuthLayout hasSideBar />}>
                        <Route path="change-password" element={<ChangePassword />} />
                      </Route>

                      <Route path="health-history" element={<OnBoardingLayout />}>
                        <Route
                          index
                          element={
                            user?.health_history?.status === 'DONE' ? (
                              <Navigate to="/dashboard" />
                            ) : (
                              <HealthHistory />
                            )
                          }
                        />
                      </Route>

                      <Route path="waiting-room/:id" element={<VideoLayout />}>
                        <Route index element={<WaitingRoom />} />
                      </Route>

                      <Route path="meeting-room/:id" element={<VideoLayout />}>
                        <Route index element={<MeetingRoom />} />
                      </Route>

                      <Route path="reset-password/:token" element={<AuthLayout />}>
                        <Route index element={<ResetPassword isLoggedIn />} />
                      </Route>
                    </>
                  )}

                  {isLoggedIn && token && isOnBoarding && user && (
                    <Route
                      path="/"
                      element={
                        user.profile?.onboarding_step && user.profile.onboarding_step === 4 ? (
                          <GuestLayout />
                        ) : (
                          <OnBoardingLayout />
                        )
                      }
                    >
                      <Route
                        path="*"
                        element={
                          <Navigate
                            to={
                              user.profile?.onboarding_step && user.profile.onboarding_step >= 5
                                ? '/health-history'
                                : '/on-boarding'
                            }
                          />
                        }
                      />
                      <Route
                        index
                        element={
                          user.profile?.onboarding_step && user.profile.onboarding_step >= 5 ? (
                            <HealthHistory />
                          ) : (
                            <OnBoarding />
                          )
                        }
                      />
                      <Route
                        path={
                          user.profile?.onboarding_step && user.profile.onboarding_step >= 5
                            ? '/health-history'
                            : '/on-boarding'
                        }
                        element={
                          user.profile?.onboarding_step && user.profile.onboarding_step >= 5 ? (
                            <HealthHistory />
                          ) : (
                            <OnBoarding />
                          )
                        }
                      />
                    </Route>
                  )}
                </>
              )}
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </Suspense>
    </GlobalLoading.Provider>
  );
};

export default Router;
