import { ElementType, ReactElement, ReactNode } from 'react';
import { ClassValue } from 'clsx';
import {
  TBookingSteps,
  TButtonRound,
  TCard,
  TConsultation,
  TConsultationStatus,
  TEscriptsStatus,
  TFileTypes,
  TGenders,
  THealthHistorySteps,
  TOnBoardingSteps,
  TOrderStatus,
  TPatientHealthStatus,
  TPatientStatus,
  TProductStatus,
  TVisibilityTypes,
  TYesOrNo
} from './types';
import { To } from 'react-router-dom';

export interface IGuard {
  children: ReactElement;
}
export interface ICheckOptions {
  id: number;
  className: ClassValue;
  value: string;
  icon?: ElementType;
}

export interface IOnboardingSteps {
  step: TOnBoardingSteps;
  setStep: (x: TOnBoardingSteps) => void;
}

export interface IHealthHistorySteps {
  step: THealthHistorySteps;
  setStep: (x: THealthHistorySteps) => void;
}

export interface IYesOrNo {
  value: TYesOrNo;
  label: string;
}

export interface ISelectOptions {
  value: any;
  label: string;
  isDisabled?: boolean;
}

export interface IProfile {
  first_name: string;
  last_name: string;
  pronoun: string;
  gender: TGenders;
  date_of_birth: Date;
  email: string;
  street: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  medicare_no: string;
  profile_pic: string;
  is_over_18: 1 | 2;
  tried_conventional_treatment: 1 | 2;
  conditions: string;
  other_conditions: string;
}

export interface INotifications {
  id: string;
  message: string;
  date: Date;
}

export interface IMenu {
  // to: To;
  label: string;
  icon?: ReactNode;
  itemRound?: TButtonRound;
  className?: ClassValue;
  divider?: boolean;
  setting?: boolean;
}

export interface IMenuLink extends IMenu {
  to: To;
  state?: { [x: string]: any };
  replace?: boolean;
}

export const isIMenuLink = (action: any): action is IMenuLink => typeof action.to === 'string';

export interface IMenuAtag extends IMenu {
  href: string;
  target?: string;
}

export const isIMenuAtag = (action: any): action is IMenuAtag => typeof action.href === 'string';

export interface IMenuButton extends IMenu {
  onClick: (event: any) => void;
  type?: 'button' | 'submit';
}

export const isIMenuButton = (action: any): action is IMenuButton =>
  typeof action.onClick === 'function';

export interface IMenuActions extends IMenu {
  actions: IMenuTypes[];
  hoverable?: boolean;
}

export const isMenuActions = (action: any): action is IMenuActions =>
  action.actions instanceof Array;

export type IMenuTypes = IMenuLink | IMenuAtag | IMenuButton | IMenuActions;

export interface IConsultationDetails {
  id: string;
  reference_id: number;
  doctor: IDoctorDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  step: TBookingSteps;
  status: TConsultationStatus;
  prescription: IPrescriptionDetails;
  tga_approval: boolean;
  tga_acceptance: boolean;
  script_approval: boolean;
  documents: IConsultationDocuments[];
  script_file?: IConsultationDocuments;
  tgaapproval_file?: IConsultationDocuments;
  tgaacceptance_file?: IConsultationDocuments;
  coupon: ICouponCode | null;
  // schedule_id: string | null;
  can_be_cancel: boolean;
}

export interface ITokenResponse {
  code: string;
  success: boolean;
  message: string;
  token: string;
  refresh_token: string;
  token_expires: string;
  refresh_token_expires: string;
  otpHash: string;
  verification_code: string;
  patient: IUser;
}

export interface IPatientProfile {
  patient_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  pronoun: string;
  gender: TGenders;
  date_of_birth: Date;
  street: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  medicare_no: string;
  profile_pic: string;
  is_australian_resident: 1 | 2;
  is_over_18: 1 | 2;
  tried_conventional_treatment: 1 | 2;
  medical_symptoms: string;
  medical_conditions: string;
  conditions: string;
  other_conditions: string;
  how_long_suffered: string;
  using_any_medicine: string;
  have_any_allergy: string;
  allergy_details: string;
  relevant_notes: string;
  about_polln: string;
  onboarding_step: number;
  currently_being_treated: 1 | 2;
  current_prescription: 1 | 2;
  taking_any_medication: 1 | 2;
  taking_any_medication_list: string;
  achieve_polln: string;
  addtl_info: string;
}

export interface IPatientHistory {
  patient_id: string;
  english_language: 1 | 2;
  primary_language: string;
  video_comfortable: 1 | 2;
  heart_lung_condition: string;
  mental_neuro_condition: string;
  muscle_condition: string;
  other_condition: string;
  other_condition_list: string;
  currently_taking_medications: 1 | 2;
  current_medications: string;
  medicine_with_prescription: 1 | 2;
  medicine_with_prescription_details: string;
  medicine_without_prescription: 1 | 2;
  medicine_without_prescription_details: string;
  smoke_tobacco: 1 | 2;
  smoke_tobacco_frequency: string;
  drink_alcohol: 1 | 2;
  drink_alcohol_frequency: string;
  pregnant_breastfeeding: 1 | 2;
  psychiatric_disorder: 1 | 2;
  family_with_health_condition: 1 | 2;
  family_with_health_condition_details: string;
  injury_surgery__hospitalization: 1 | 2;
  injury_surgery__hospitalization_details: string;
  current_diet: string;
  physical_activity_level: string;
  stress_emotions: string;
  status: TPatientHealthStatus;
}

export interface IPatientBackground {
  patient_id: string;
  cannabis_used: string;
  have_medical_prescription: string;
  cannabis_history: string;
  has_allergy: string;
  allergy: string;
  allergy_severity: string;
  has_surgery: string;
  surgery: string;
  surgery_date: Date;
  alcohol_used: string;
  tobacco_used: string;
  exescise_activity: string;
  family_history: string;
  other_family_history: string;
  medical_history: string;
  other_medical_history: string;
  currently_being_treated: string;
  current_clinic: string;
  current_doctor: string;
  current_date: Date;
  currently_taking_medication: string;
  current_medication: string;
  current_medication_dose: string;
  current_work_status: string;
  current_work: string;
}

export interface IAddress {
  street: string;
  city: string;
  state: string;
  postcode: string;
}
export interface IUser {
  id: string;
  email: string;
  timezone: string;
  contact_no: string;
  secondary_contact_no: string;
  profile: IPatientProfile;
  health_history: IPatientHistory;
  background: IPatientBackground;
  phone_verification: boolean;
  email_verification: boolean;
  status: TPatientStatus;
  consultation_status: TConsultationStatus;
  last_consultation_date: Date;
  consent_approved: boolean;
  shop_consent_approved: boolean;
  delivery_address: IAddress | null;
  billing_address: IAddress | null;
  same_delivery: boolean;
  same_billing: boolean;
  id_verification: boolean;
  // wallet_balance: number;
  checkout_enabled: boolean;
  ihi_no: string;
  ihi_record_status: string;
  ihi_status: string;
  dva_no: string;
}

export interface IUserToken {
  id: string;
  status: TPatientStatus;
}

export interface IMeResponse {
  code: string;
  success: boolean;
  message: string;
  is_from_admin: boolean;
  patient: IUser;
}

export interface IOtpSendResponse {
  code: string;
  success: boolean;
  message: string;
  fullHash: string;
  verification_code: number;
}

export interface IOtpVerifyResponse {
  code: string;
  success: boolean;
  message: string;
}

export interface IFileCheckResponse {
  success: boolean;
  message: string;
}

export interface IBookingConsultation {
  setStep?: (x: number) => void;
}

export interface IAvailableDoctors {
  bookingId: string;
  time: string;
  date: Date | string;
  firstName: string;
  lastName: string;
  profilePic: string;
}

export interface IDoctorDetails {
  id: string;
  email: string;
  timezone: string;
  contact_no: string;
  profile: {
    doctor_id: string;
    first_name: string;
    last_name: string;
    profile_pic: string | null;
  };
}

export interface IDoctorSchedules {
  doctor: IDoctorDetails[];
  start_at: Date;
  end_at: Date;
  // schedule_id: string;
}

export interface IConsultationType {
  id: string;
  name: string;
  duration: number;
  is_default: boolean;
  is_preselected: boolean;
  price: string;
  capitalize_name: TConsultation;
  description: string;
}

export interface IConsultationDocuments {
  filename: string;
  file_url: string;
  file_key: string;
  file_type: TFileTypes;
  uploaded_by: string;
  upload_date: Date;
  visibility: TVisibilityTypes;
}

export interface IExtendedDocuments extends IConsultationDocuments {
  consultation: IConsultationDetails;
}

export interface IFileMutation {
  id?: number;
  filename: string;
  file_url: string;
  filetype?: TFileTypes;
}

export interface IProducts {
  id: string;
  type: string;
  brand: string;
  product_name: string;
  product_type: IProductType;
  product_image: string | null;
  stock: number;
  format: string;
  schedule: string;
  price: string;
  description: string;
  is_active: boolean;
}

export interface IProductType {
  dispensing_limit: number;
  icon: string;
  id: string;
  name: string;
  unit: string;
}

export interface IProductSelection {
  id?: string;
  product_id: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  product?: IProducts;
}

export interface IProductCustom {
  id?: string;
  product_name: string;
  product_details: string;
  product_type: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  brand_name: string;
  sku: string;
  type: string;
  format: string;
}

export interface IPrescriptionDetails {
  id: string;
  patient_id: string;
  consultation_id: string;
  products: IProductSelection[];
  custom_products: IProductCustom[];
  consultation: IConsultationDetails;
  consultation_notes: string;
  treatment_notes: string;
  careteam_notes: string;
}

export interface ICouponCode {
  id: string;
  price_discount: number;
  code: string;
}

export interface IEscripts {
  id: string;
  reference_id: string;
  patient: IUser;
  doctor: IDoctorDetails;
  consultation_date: Date;
  product: IProducts;
  description: string;
  remaining: number;
  total: number;
  status: TEscriptsStatus;
  quantity?: number;
  availability: boolean;
  reach_limit: boolean;
  reach_interval: boolean;
  expiry: Date;
  dispatch_days: number;
  product_size: number;
  product_status: TProductStatus;
  dispensing_limits: number;
  last_order: Date;
  order_available_in: Date;
  monthly_order: number;
  product_image: string | null;
}

export interface IFormOtpLogin {
  email: string;
  password: string;
  patientId: string;
  otpHash: string;
  verificationCode: string;
  onClose?: () => void;
}

export interface ILinkPermission {
  name: string;
  url: string;
}

export interface ICartItem {
  id: string;
  escript: IEscripts;
  quantity: number;
}

export interface ICheckoutDetails {
  reference_id: string;
  total: number;
  name: string;
  email: string;
  phone: string;
  address: {
    street: string;
    city: string;
    state: string;
    postcode: string;
  };
}

export interface IPaymentCardToken {
  token: string;
  user_id: string;
}

export interface ICardAccount {
  card_number: string;
  expiry_month: string;
  expiry_year: string;
  full_name: string;
  id: string;
  is_active: boolean;
  type: string;
}

export interface IPaymentProp {
  paymentId: string | 'WALLET';
  isSaveCard: boolean;
  isExist: boolean;
}

export interface IPaymentPayResponse {
  id: string;
  patient_id: string;
  consultation_id: string;
  invoice: string;
  consultation: {
    start_at: Date;
    end_at: Date;
    reference_id: number;
    doctor: IDoctorDetails;
    consultation_type: IConsultationType;
  };
  status: 'PENDING' | 'FAILED' | 'SUCCESS';
}

export interface ISteps {
  label: string;
  step: number;
}

export interface IOrders {
  created_at: Date;
  delivery_date: Date;
  id: string;
  order_id: string;
  paid_at: Date | null;
  paid_card: TCard;
  paid_card_ending: string;
  patient_address: string;
  price: string;
  shipping_fee: string;
  status: TOrderStatus;
  wallet_used: number;
  coupon: ICouponCode | null;
}

export interface IOrderInfo {
  created_at: Date;
  delivery_date: Date;
  id: string;
  order_id: string;
  paid_at: Date;
  paid_card: TCard;
  paid_card_ending: string;
  patient_address: string;
  price: string;
  products: IProducts[];
  shipping_fee: string;
  status: TOrderStatus;
  wallet_used: number;
  coupon: ICouponCode | null;
}

export interface ICurrentMedication {
  name: string;
  dose: string;
  frequency: string;
  reason: string;
}

export interface IMedicineDetails {
  type: string;
  frequency: string;
}
