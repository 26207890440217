import React, { FC, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';
import { TCardRound, TShadow } from 'utils/types';

interface ICard {
  className?: ClassValue;
  classNameTitle?: ClassValue;
  title?: ReactNode | string;
  actions?: ReactNode;
  children: ReactNode;
  shadow?: TShadow;
  rounded?: TCardRound;
}

const shadows = {
  sm: 'shadow-sm',
  shadow: 'shadow',
  md: 'shadow-md',
  lg: 'shadow-lg',
  xl: 'shadow-xl',
  '2xl': 'shadow-2xl',
  inner: 'shadow-inner',
  none: 'shadow-none'
};

const roundedness = {
  none: '',
  sm: 'rounded-sm',
  rounded: 'rounded',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full'
};

const Card: FC<ICard> = ({
  className,
  classNameTitle,
  title,
  actions,
  children,
  shadow = 'shadow',
  rounded = 'lg'
}) => {
  return (
    <div
      className={clsx('bg-zinc-50 p-5 md:p-6', roundedness[rounded], shadows[shadow], className)}
    >
      <div className={clsx(actions && 'flex justify-between items-center', 'relative')}>
        <div className={clsx(classNameTitle)}>{title}</div>
        {actions && actions}
      </div>

      {children}
    </div>
  );
};

export default Card;
