import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import 'fix-date'; // fix for invalid date in safari browsers
import './main.css';
import 'react-datepicker/dist/react-datepicker.css';
import { CONF_DSN_SENTRY } from 'utils/config';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import 'react-tooltip/dist/react-tooltip.css';

// sentry tracker
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import AppProvider from 'ApolloProvider';
import reportWebVitals from './reportWebVitals';
import ConfirmDialog from 'components/modals/ModalConfirm';

Sentry.init({
  dsn: CONF_DSN_SENTRY,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <AppProvider>
    <Provider store={store}>
      <App />
      <ConfirmDialog />
    </Provider>
  </AppProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
