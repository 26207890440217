import React, { FC, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';
import { motion, AnimatePresence, MotionStyle } from 'framer-motion';

interface MotionToastProps {
  show: boolean;
  className?: ClassValue;
  children: ReactNode;
}

const MotionToast: FC<MotionToastProps> = ({ show, className, children }) => (
  <AnimatePresence>
    {show && (
      <motion.div
        className={clsx(className, 'z-40')}
        style={{ originY: 1, originX: 1 } as MotionStyle}
        variants={{
          hidden: { scale: 0.95, opacity: 0 },
          visible: { scale: 1, opacity: 1 }
        }}
        transition={{
          type: 'tween',
          ease: 'easeOut',
          duration: 0.3
        }}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

export default MotionToast;
