import React, { FC } from 'react';
import clsx, { ClassValue } from 'clsx';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { fullName, photoPlaceholder } from 'utils/helpers';
import { IMenuTypes } from 'utils/models';
import useAuth from 'hooks/useAuth';
import { Link } from 'react-router-dom';

// components
import MenuAction from 'components/MenuAction';
import ButtonViewCart from 'components/buttons/ButtonViewCart';
import ButtonConsultation from 'components/buttons/ButtonConsultation';
import { SvgPollnBrand, SvgSideSettings } from 'components/SvgIcons';
import HeaderPolln from 'components/HeaderPolln';

interface IHeader {
  className?: ClassValue;
  hasCart?: boolean;
  hasBookingAppointment?: boolean;
  menu: IMenuTypes[];
}

const Header: FC<IHeader> = ({
  className,
  menu,
  hasCart = false,
  hasBookingAppointment = false
}) => {
  const { user, isAdmin } = useAuth();

  return (
    <div className={clsx(className, 'relative w-full', isAdmin && 'bg-red-300')}>
      <div className="hidden md:block p-3 mx-auto">
        <div
          className={clsx(
            (hasBookingAppointment || isAdmin) && 'flex justify-between items-center'
          )}
        >
          <div className="flex items-left space-x-10">
            <Link to="/dashboard">
              <div className="flex justify-start flex-col items-left space-y-0.5 text-left">
                <SvgPollnBrand className="text-polln-solid-green-600 w-16 h-5" />
                <div className="text-gray-500 font-bold text-sm">Patient Dashboard</div>
              </div>
            </Link>

            {hasBookingAppointment && (
              <ButtonConsultation
                variant="green-btn-200"
                rounded="lg"
                classNameButton="space-x-2 uppercase !font-extrabold text-sm p-3"
              >
                <PlusCircleIcon className="w-6 h-6" /> <span>New Consultation</span>
              </ButtonConsultation>
            )}
          </div>

          {isAdmin && (
            <div className="flex justify-center uppercase text-xl font-bold">
              You are logged-in as admin
            </div>
          )}

          <div className="flex justify-end items-center px-5">
            <div className="hidden md:inline-block">
              <div className="flex items-center space-x-5 mr-5">
                <div
                  className={clsx(
                    'bg-no-repeat bg-cover bg-center',
                    'rounded-full h-12 w-12 border'
                  )}
                  style={{
                    backgroundImage: `url(${
                      user?.profile?.profile_pic || photoPlaceholder['patient']
                    })`
                  }}
                />

                {user?.profile?.first_name && user?.profile?.last_name && (
                  <div>
                    <div className="text-black font-bold text-polln-black-600">
                      {fullName(user)}
                    </div>
                    <div className="text-black text-polln-black-600 font-normal">Polln Patient</div>
                  </div>
                )}
              </div>
            </div>

            {hasCart && (
              <div className="mx-3">
                <ButtonViewCart />
              </div>
            )}

            <div className="hidden md:block">
              <MenuAction
                menuButton={<SvgSideSettings className="w-9 h-9 text-black" />}
                classNameMain="ml-5"
                menu={menu}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block md:hidden">
        <HeaderPolln />
      </div>
    </div>
  );
};

export default Header;
