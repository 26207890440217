import React, { FC } from 'react';
import clsx from 'clsx';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { NumericFormat } from 'react-number-format';
import { fullName } from 'utils/helpers';
import { TShippingDetailsForm } from 'utils/types';
import {
  PICKUP_SHIPPING_FEE,
  STANDARD_SHIPPING_FEE,
  STATE_OPTIONS,
  STEPS_CART
} from 'utils/constants';
import useAuth from 'hooks/useAuth';

// components
import Button from 'components/Button';
import FormInput from 'components/forms/FormInput';
import FormSelect from 'components/forms/FormSelect';
import StepNumber from 'components/StepNumber';
import { SvgBoxTime } from 'components/SvgIcons';

interface ICartShipping {
  step: 1 | 2 | 3;
  setStep: (x: 1 | 2 | 3) => void;
  totalPrice: number;
  shippingDetails: TShippingDetailsForm | null;
  setShippingDetails: (x: TShippingDetailsForm) => void;
}

const validationSchema = Yup.object().shape({
  address: Yup.object().shape({
    street: Yup.string().required('Required'),
    city: Yup.string().required('Please enter your city.'),
    state: Yup.string().required('Required.'),
    postcode: Yup.string().required('Please enter your postcode.')
  })
});

const CartShipping: FC<ICartShipping> = ({
  shippingDetails,
  setShippingDetails,
  step,
  setStep,
  totalPrice
}) => {
  const { user } = useAuth();

  /**
   * on submit shipping details
   * @param values
   * @param param1
   */
  const submit = async (values: TShippingDetailsForm) => {
    setShippingDetails(values);
    setStep(3);
  };

  const initialValues: TShippingDetailsForm = {
    name: fullName(user),
    phone: shippingDetails?.phone || user?.contact_no || '',
    email: shippingDetails?.email || user?.email || '',
    address: shippingDetails?.address || {
      street: user?.profile?.street || '',
      state: user?.profile?.state || '',
      city: user?.profile?.city || '',
      postcode: user?.profile?.postcode || ''
    },
    shippingOption: shippingDetails?.shippingOption || 'STANDARD'
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
      enableReinitialize
    >
      {(formikProps) => {
        const { touched, errors, handleSubmit, isSubmitting, values, setFieldValue } = formikProps;
        const errMessage: string[] = [];

        if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
          Object.values(errors).forEach((v: any) => {
            if (Object.keys(v).length > 0) {
              Object.values(v).forEach((x: any) => errMessage.push(x));
            } else {
              errMessage.push(v);
            }
          });
        }

        return (
          <Form onSubmit={handleSubmit}>
            <div
              className={clsx(
                'pb-10 md:py-8 px-8',
                'h-[calc(100dvh-21.5rem)]',
                'md:h-[calc(100vh-20rem)]',
                'overflow-y-auto'
              )}
            >
              <div className="grid gap-3 grid-cols-12">
                <div className="col-span-12">
                  <StepNumber step={1} text="Confirm Shipping Details" />
                </div>
                <div className="col-span-12">
                  <div className="mb-3 text-sm">
                    Please check if the details we have are correct:
                  </div>
                  <FormInput
                    placeholder="Address Line 1"
                    labelClassName="uppercase font-bold"
                    name="address.street"
                    variant="normal"
                    className="rounded-md text-sm"
                    isErrorBelow
                  />
                </div>
                <div className="col-span-12">
                  <FormInput
                    labelClassName="uppercase font-bold"
                    placeholder="City"
                    name="address.city"
                    variant="normal"
                    className="rounded-md text-sm"
                    isErrorBelow
                  />
                </div>
                <div className="col-span-12 md:col-span-8">
                  <FormSelect
                    labelClassName="uppercase font-bold"
                    name="address.state"
                    placeholder="State"
                    options={STATE_OPTIONS}
                    round="md"
                    variant="normal"
                    className="full-border text-sm"
                    isErrorBelow
                  />
                </div>
                <div className="col-span-12 md:col-span-4">
                  <FormInput
                    labelClassName="uppercase font-bold"
                    placeholder="Postcode"
                    name="address.postcode"
                    variant="normal"
                    className="rounded-md text-sm"
                    isErrorBelow
                  />
                </div>

                <div className="col-span-12">
                  <div className="flex items-center mt-5 space-x-2">
                    <StepNumber step={2} text="Select a Shipping Option" />
                  </div>
                </div>

                <div className="col-span-12">
                  <Button
                    variant={values.shippingOption === 'STANDARD' ? 'green-btn-500' : 'invisible'}
                    size="inline"
                    className="w-full"
                    classNameButton={clsx(
                      values.shippingOption !== 'STANDARD' && 'bg-gray-400',
                      'py-4 px-3',
                      'text-left'
                    )}
                    rounded="lg"
                    onClick={() => setFieldValue('shippingOption', 'STANDARD')}
                  >
                    <SvgBoxTime
                      className="md:w-12 md:h-12 hidden md:block fill-none"
                      stroke={values.shippingOption === 'STANDARD' ? 'white' : 'black'}
                    />
                    <div className="text-sm !mx-0 md:!mx-6 md:py-3 py-1">
                      <div
                        className={clsx(
                          values.shippingOption !== 'STANDARD' && 'text-polln-green-600',
                          'font-bold text-base'
                        )}
                      >
                        {`$${STANDARD_SHIPPING_FEE} Express Shipping`}
                      </div>
                      <div className="mt-2 italic">Estimated Shipping Date: 1-3 Business Days</div>

                      <div>
                        All your items will be{' '}
                        <span className="italic underline">shipped in one package</span> when they
                        are all available.
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>

            <div className="p-5 w-full space-y-2 border bg-white fixed bottom-0 md:relative">
              <div className="mb-4">
                <div className="text-sm md:text-base font-bold">
                  <div className="flex items-center space-x-3 justify-end">
                    <div>Shipping:</div>
                    {values.shippingOption ? (
                      <NumericFormat
                        value={
                          values.shippingOption === 'STANDARD'
                            ? STANDARD_SHIPPING_FEE
                            : PICKUP_SHIPPING_FEE
                        }
                        prefix="$"
                        decimalScale={2}
                        thousandSeparator
                        displayType="text"
                      />
                    ) : (
                      <span>$TBC</span>
                    )}
                  </div>
                </div>

                <div className="text-sm md:text-base font-bold">
                  <div className="flex items-center space-x-3 justify-end">
                    <div>Product Total:</div>
                    <NumericFormat
                      value={totalPrice}
                      prefix="$"
                      decimalScale={2}
                      thousandSeparator
                      displayType="text"
                    />
                  </div>
                </div>

                {values.shippingOption && (
                  <div className="text-sm md:text-base font-bold">
                    <div className="flex items-center space-x-3 justify-end">
                      <div>Order Total:</div>

                      <NumericFormat
                        value={
                          (values.shippingOption === 'STANDARD'
                            ? STANDARD_SHIPPING_FEE
                            : PICKUP_SHIPPING_FEE) + totalPrice
                        }
                        prefix="$"
                        decimalScale={2}
                        thousandSeparator
                        displayType="text"
                      />
                    </div>
                  </div>
                )}
              </div>
              <Button
                type="submit"
                variant="green-btn-500"
                size="lg"
                className="w-full h-14"
                classNameButton="justify-center md:py-4"
                rounded="lg"
                disabled={isSubmitting || !values.shippingOption}
              >
                <span className="font-extrabold text-xs md:text-sm uppercase">
                  {STEPS_CART[step]}
                </span>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CartShipping;
