import { CONF_ENVIRONMENT } from 'utils/config';

export const pushConversionEvent = (event: string, category: string, title: string) => {
  /**
   * should only push in production
   * so error won't show in dev, qa and staging
   */
  if (CONF_ENVIRONMENT === 'production') {
    window.dataLayer.push({
      event,
      category,
      title
    });
  }
};
