import React, { FC } from 'react';

const HeaderPolln: FC = () => (
  <div className="relative w-full">
    <div className="relative py-3 px-0 d:px-5 flex justify-between">
      <a href="/dashboard">
        <div className="bg-green-polln bg-no-repeat bg-contain h-4 md:h-5 w-40" />
      </a>
    </div>
  </div>
);

export default HeaderPolln;
