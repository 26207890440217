import React, { FC, useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { NumericFormat } from 'react-number-format';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { ICartItem } from 'utils/models';
import { STEPS_CART } from 'utils/constants';

// grapqhl
import { useLazyQuery, useMutation } from '@apollo/client';
import mutation from 'graphql/mutation';
import query from 'graphql/query';

// components
import Button from 'components/Button';
import ButtonUpdateCart from 'components/buttons/ButtonUpdateCart';
import EScriptProductImage from 'components/EScriptProductImage';
import Card from 'components/Card';
import { toast } from 'components/Toast';
import { SvgTrash } from 'components/SvgIcons';

interface ICartItems {
  step: 1 | 2 | 3;
  setStep: (x: 1 | 2 | 3) => void;
  totalPrice: number;
  items: ICartItem[];
  refetch: () => Promise<any> | void;
  isLoading?: boolean;
  showDelete?: boolean;
  showUpdateButtons?: boolean;
  showButtonBottom?: boolean;
  className?: ClassValue;
  classNameItem?: ClassValue;
}

const CartItems: FC<ICartItems> = ({
  items,
  refetch,
  step,
  setStep,
  totalPrice,
  isLoading = false,
  showDelete = false,
  showUpdateButtons = false,
  showButtonBottom = false,
  className,
  classNameItem
}) => {
  const [disableCartButton, setDisableCartButton] = useState<boolean>(false);

  const [validateCart, { loading: isValidating }] = useLazyQuery<{
    paymentValidateCart: {
      code: string;
      success: boolean;
      message: string;
    };
  }>(query.paymentValidateCart, {
    fetchPolicy: 'network-only'
  });

  const [removeItemToCart] = useMutation<{
    paymentRemoveToCart: {
      code: string;
      success: boolean;
      message: string;
    };
  }>(mutation.paymentRemoveToCart, {
    fetchPolicy: 'network-only'
  });

  const handleValidateCart = async () => {
    try {
      const { data } = await validateCart();

      if (data?.paymentValidateCart.code === '200' && data.paymentValidateCart.success) {
        setStep(2);
      }

      if (!data?.paymentValidateCart.success) {
        toast({
          title: 'Cart error',
          message: data?.paymentValidateCart.message,
          variant: 'error'
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        message: 'An error occurred',
        variant: 'error'
      });
    }
  };

  const handleRemoveItem = async (id: string) => {
    try {
      const { data } = await removeItemToCart({
        variables: {
          id
        }
      });

      if (data?.paymentRemoveToCart.code === '200' && data.paymentRemoveToCart.success) {
        refetch();
        toast({
          title: 'Item has been removed from the cart.'
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        message: 'An error occurred',
        variant: 'error'
      });
    }
  };

  return (
    <>
      <div
        className={clsx(
          'snap-y snap-mandatory mt-5 md:mt-3 px-2 py-6 gap-5 flex flex-col',
          className
        )}
      >
        <div className="space-y-5 px-5">
          {items.map((item, index) => (
            <div className="snap-align-none md:snap-center shrink-0" key={index}>
              <Card
                rounded="lg"
                shadow="md"
                className="space-y-3 md:space-y-0 drop-shadow-lg bg-zinc-100"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="relative">
                      <EScriptProductImage
                        className="md:block hidden h-20 w-20"
                        image={item.escript.product_image}
                      />
                      {!showUpdateButtons && (
                        <div className="absolute w-6 h-6 -top-2 -right-2 text-center p-1 rounded-full bg-gray-400 text-xs text-white font-bold">
                          {item.quantity}
                        </div>
                      )}
                    </div>

                    <div className="md:space-y-1 md:-ml-40 pr-5">
                      <div className={clsx('text-sm text-black font-bold', classNameItem)}>
                        {item.escript.product.product_name}
                      </div>
                      <div className="text-sm text-black">
                        {item.escript.product?.product_type?.name || item.escript.description}
                      </div>
                      {/**
                       * to comment for now until needed
                       * as per suren
                       */}
                      {/* <div className="text-xs md:text-sm text-polln-light-green-600">
                        {`Est. Dispatch ${item.escript.dispatch_days || 0}`}
                      </div> */}
                    </div>
                  </div>

                  <div className="flex items-center space-x-5">
                    <div className="text-base">${item.escript.product.price}</div>

                    {showDelete && (
                      <Button variant="invisible" onClick={() => handleRemoveItem(item.id)}>
                        <SvgTrash className="h-6 w-6 text-red-500" />
                      </Button>
                    )}
                  </div>
                </div>

                {showUpdateButtons && (
                  <div className="space-y-2">
                    <div className="flex items-center space-x-4 justify-center">
                      <ButtonUpdateCart
                        size="sm"
                        variant="green-btn-200"
                        escriptId={item.escript.id}
                        quantity={item.quantity - 1}
                        refetch={refetch}
                        setDisabler={setDisableCartButton}
                        disabled={disableCartButton}
                        toggleDisablerAfter
                      >
                        <MinusIcon className="w-3 h-5" />
                      </ButtonUpdateCart>
                      <div className="text-center">{item.quantity}</div>
                      <ButtonUpdateCart
                        size="sm"
                        variant="green-btn-200"
                        escriptId={item.escript.id}
                        quantity={item.quantity + 1}
                        refetch={refetch}
                        setDisabler={setDisableCartButton}
                        disabled={
                          item.quantity >= item.escript.remaining ||
                          item.escript.reach_limit ||
                          disableCartButton ||
                          item.quantity >= 2
                        }
                        toggleDisablerAfter
                      >
                        <PlusIcon className="w-3 h-5" />
                      </ButtonUpdateCart>
                    </div>
                  </div>
                )}
              </Card>
            </div>
          ))}
        </div>
      </div>

      {showButtonBottom && (
        <div className="p-5 w-full space-y-5 border bg-white fixed bottom-0 md:relative">
          <div className="text-sm md:text-lg font-bold">
            <div className="flex items-center space-x-3 justify-end">
              <div>Product Total:</div>
              <NumericFormat
                value={totalPrice}
                prefix="$"
                decimalScale={2}
                thousandSeparator
                displayType="text"
              />
            </div>
          </div>

          <Button
            variant="green-btn-500"
            size="lg"
            className="w-full"
            classNameButton="justify-center md:py-4  py-5 !text-xs md:!text-sm"
            rounded="lg"
            disabled={isLoading}
            isLoading={isValidating}
            onClick={() => handleValidateCart()}
          >
            <span className="font-extrabold uppercase">{STEPS_CART[step]}</span>
          </Button>
        </div>
      )}
    </>
  );
};

export default CartItems;
