import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { IMeResponse, ITokenResponse, IUser } from 'utils/models';
import storage from 'utils/storage';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import { pushConversionEvent } from 'utils/ga';

// redux
import { useDispatch } from 'react-redux';
import { actions } from 'store/actions';

// graphql
import { useLazyQuery, useMutation } from '@apollo/client';
import query from 'graphql/query';
import mutation from 'graphql/mutation';

interface IContext {
  user: IUser | null;
  isLoggedIn: boolean;
  isOnBoarding: boolean;
  isLoading: boolean;
  token: string | null;
  isAdmin: boolean;
  login: (email: string, password: string) => Promise<any> | void;
  loginOtp: (code: string, hash: string, patientId: string) => Promise<any> | void;
  logout: () => void;
  signup: (email: string, password: string) => Promise<any> | void;
  getMe: () => Promise<any> | void;
  logMe: () => Promise<any> | void;
}

const AuthContext = createContext<IContext>({
  user: null,
  isLoggedIn: false,
  isOnBoarding: false,
  isLoading: true,
  token: null,
  isAdmin: false,
  login: (email: string, password: string) => {},
  loginOtp: (code: string, hash: string, patientId: string) => {},
  logout: () => {},
  signup: (email: string, password: string) => {},
  getMe: () => {},
  logMe: () => {}
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [tokenRefresh, setTokenRefresh] = useState<string | null>(null);
  const [expiry, setExpire] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isOnBoarding, setIsOnBoarding] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(true);

  const dispatch = useDispatch();

  const gaEventTracker = useAnalyticsEventTracker('Sign Up');

  /**
   * patient login
   */
  const [patientLogIn] = useMutation<{
    patientLogIn: ITokenResponse;
  }>(mutation.patientLogIn, {
    fetchPolicy: 'network-only'
  });

  /**
   * patient login otp
   */
  const [patientLogInOtp] = useMutation<{
    patientLogInOtp: ITokenResponse;
  }>(mutation.patientLogInOtp, {
    fetchPolicy: 'network-only'
  });

  /**
   * patient sign up
   */
  const [patientSignUp] = useMutation<{
    patientSignUp: ITokenResponse;
  }>(mutation.patientSignUp, {
    fetchPolicy: 'network-only'
  });

  /**
   * me query
   */
  const [patientMe, { data: dataMe, loading }] = useLazyQuery<{
    patientMe: IMeResponse;
  }>(query.patientMe, {
    fetchPolicy: 'network-only'
  });

  const handleAuth = useCallback(
    (
      meToken: string | null,
      me: IUser,
      login: boolean,
      status: boolean,
      meTokenExpiration: string | null,
      meTokenRefresh: string | null,
      meAdmin: boolean
    ) => {
      if (meToken && meTokenExpiration && meTokenRefresh) {
        storage.setItem('token', meToken);
        storage.setItem('token-refresh', meTokenRefresh);
        storage.setItem('expiry', meTokenExpiration);
        setToken(meToken);
        setTokenRefresh(meTokenRefresh);
        setExpire(meTokenExpiration);
        setUser(me);
        setIsLoggedIn(login);
        setIsOnBoarding(status);
        setIsAdmin(meAdmin);
        dispatch(actions.userMe(me));
      }
    },
    [dispatch]
  );

  /**
   * login function
   * @param email
   * @param password
   * @returns
   */
  const login = async (email: string, password: string) => {
    const { data } = await patientLogIn({
      variables: {
        input: {
          email,
          password
        }
      }
    });

    if (data?.patientLogIn.code === '200' && data.patientLogIn.success) {
      if (data.patientLogIn.otpHash === null && data.patientLogIn.patient.status !== 'DISCHARGED') {
        const me = data.patientLogIn.patient;
        const status = data.patientLogIn.patient.status === 'INACTIVE';
        const token = data.patientLogIn.token;
        const tokenRefresh = data.patientLogIn.refresh_token;
        const expiry = data.patientLogIn.token_expires;
        handleAuth(token, me, true, status, expiry, tokenRefresh, false);
      }
    }

    return data?.patientLogIn;
  };

  /**
   * login otp function
   * @param code
   * @param hash
   * @param patientId
   * @returns
   */
  const loginOtp = async (code: string, hash: string, patientId: string) => {
    const { data } = await patientLogInOtp({
      variables: {
        input: {
          code: +code,
          hash,
          patient_id: patientId
        }
      }
    });

    if (data?.patientLogInOtp.code === '200' && data.patientLogInOtp.success) {
      const me = data.patientLogInOtp.patient;
      const token = data.patientLogInOtp.token;
      const tokenRefresh = data.patientLogInOtp.refresh_token;
      const expiry = data.patientLogInOtp.token_expires;
      handleAuth(token, me, true, false, expiry, tokenRefresh, false);
    }

    return data?.patientLogInOtp;
  };

  /**
   * sign up function
   * @param email
   * @param password
   * @returns
   */
  const signup = async (email: string, password: string) => {
    const { data } = await patientSignUp({
      variables: {
        input: {
          email,
          password
        }
      }
    });

    if (data?.patientSignUp.code === '200' && data.patientSignUp.success) {
      const me = data.patientSignUp.patient;
      const status = data.patientSignUp.patient.status === 'INACTIVE';
      const token = data.patientSignUp.token;
      const tokenRefresh = data.patientSignUp.refresh_token;
      const expiry = data.patientSignUp.token_expires;

      /**
       * for ga event track
       */
      gaEventTracker('Patient On-Boarding', 'Initial Sign-Up');
      pushConversionEvent('initial_patient_sign_up', 'cat_initial_patient_sign_up', 'sign-up');

      /**
       * for handle auth
       */
      handleAuth(token, me, true, status, expiry, tokenRefresh, false);
    }

    return data?.patientSignUp;
  };

  const logout = useCallback(() => {
    storage.removeItem('token');
    storage.removeItem('expiry');
    storage.removeItem('token-refresh');
    dispatch(actions.userLogout());
    setUser(null);
    setIsLoggedIn(false);
    setIsOnBoarding(false);
    setToken(null);
    setExpire(null);
    setTokenRefresh(null);
  }, [dispatch]);

  const getMe = useCallback(async () => {
    try {
      await patientMe();
    } catch (error) {
      logout();
    }
  }, [patientMe, logout]);

  const logMe = useCallback(() => {
    if (storage.getItem('token')) setToken(storage.getItem('token'));
    if (storage.getItem('expiry')) setExpire(storage.getItem('expiry'));
    if (storage.getItem('token-refresh')) setTokenRefresh(storage.getItem('token-refresh'));

    getMe();
  }, [getMe]);

  useEffect(() => {
    if (dataMe && dataMe?.patientMe?.code === '200' && dataMe?.patientMe?.success) {
      const me = dataMe.patientMe.patient;
      const status = dataMe.patientMe.patient.status === 'INACTIVE';
      const meAdmin = dataMe.patientMe.is_from_admin;
      handleAuth(token, me, true, status, expiry, tokenRefresh, meAdmin);
    }

    if (
      dataMe &&
      (((dataMe?.patientMe?.code === '422' || dataMe?.patientMe?.code === '404') &&
        !dataMe?.patientMe?.success) ||
        dataMe.patientMe.patient.status === 'DISCHARGED')
    ) {
      logout();
    }
  }, [dataMe, token, handleAuth, logout, expiry, tokenRefresh]);

  useEffect(() => {
    if (token) getMe();
  }, [getMe, token]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (storage.getItem('token')) setToken(storage.getItem('token'));
    if (storage.getItem('expiry')) setExpire(storage.getItem('expiry'));
    if (storage.getItem('token-refresh')) setTokenRefresh(storage.getItem('token-refresh'));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        loginOtp,
        logout,
        signup,
        getMe,
        logMe,
        user,
        isLoggedIn,
        isOnBoarding,
        isLoading,
        token,
        isAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
