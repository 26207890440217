import React, { ReactElement } from 'react';
import * as ReactDOM from 'react-dom';

const toastRoot = document.createElement('div');
toastRoot.className = 'flex flex-col justify-start fixed inset-0 pointer-events-none z-50';
document.body.appendChild(toastRoot);

const toast = (
  DialogContent: (props: { giveAnswer: (answer: boolean) => void }) => ReactElement
): Promise<boolean> =>
  new Promise((res) => {
    const toastContainer = document.createElement('div');
    toastRoot.appendChild(toastContainer);

    const giveAnswer = (answer: boolean) => {
      ReactDOM.unmountComponentAtNode(toastContainer);
      res(answer);
    };

    ReactDOM.render(<DialogContent giveAnswer={giveAnswer} />, toastContainer);
  });

export default toast;
