import {
  USER_LOGIN,
  UPDATE_GP,
  UPDATE_SAVE_CARD,
  USER_ME,
  USER_LOGOUT,
  USER_ONBOARD,
  UPDATE_CONSULTATION,
  GET_CART_ITEMS,
  UPDATE_CART_ITEMS,
  UPDATE_NO_OF_SAVED_CARD,
  GET_ESCRIPT_ITEMS,
  REFETCH_ESCRIPT
} from 'store/types';
import { ICartItem, IConsultationDetails, IEscripts, IUser, IUserToken } from 'utils/models';

export interface IActionPayload {
  type:
    | typeof USER_LOGIN
    | typeof USER_LOGOUT
    | typeof UPDATE_GP
    | typeof UPDATE_SAVE_CARD
    | typeof UPDATE_NO_OF_SAVED_CARD
    | typeof USER_ME
    | typeof USER_ONBOARD
    | typeof UPDATE_CONSULTATION
    | typeof GET_CART_ITEMS
    | typeof UPDATE_CART_ITEMS
    | typeof GET_ESCRIPT_ITEMS
    | typeof REFETCH_ESCRIPT;

  payload?: any;
}

const userMe = (payload: IUser | IUserToken) => {
  const action: IActionPayload = {
    type: USER_ME,
    payload
  };

  return action;
};

const userOnBoard = (isOnBoarding: boolean) => {
  const action: IActionPayload = {
    type: USER_ONBOARD,
    payload: isOnBoarding
  };

  return action;
};

const userLogin = () => {
  const action: IActionPayload = {
    type: USER_LOGIN
  };

  return action;
};

const userLogout = () => {
  const action: IActionPayload = {
    type: USER_LOGOUT
  };

  return action;
};

const updateGp = () => {
  const action: IActionPayload = {
    type: UPDATE_GP
  };

  return action;
};

const userSaveCard = (saveCard: boolean) => {
  const action: IActionPayload = {
    type: UPDATE_SAVE_CARD,
    payload: saveCard
  };

  return action;
};

const updateNoOfSavedCard = (cards: number) => {
  const action: IActionPayload = {
    type: UPDATE_NO_OF_SAVED_CARD,
    payload: cards
  };

  return action;
};

const updateConsultation = (consultation: IConsultationDetails) => {
  const action: IActionPayload = {
    type: UPDATE_CONSULTATION,
    payload: consultation
  };

  return action;
};

const getCartItems = (cart: ICartItem[]) => {
  const action: IActionPayload = {
    type: GET_CART_ITEMS,
    payload: cart
  };

  return action;
};

const getEscriptItems = (escript: IEscripts[]) => {
  const action: IActionPayload = {
    type: GET_ESCRIPT_ITEMS,
    payload: escript
  };

  return action;
};

const refetchEscript = (refetch: boolean) => {
  const action: IActionPayload = {
    type: REFETCH_ESCRIPT,
    payload: refetch
  };

  return action;
};

const actions = {
  userOnBoard,
  userLogin,
  userLogout,
  updateGp,
  userSaveCard,
  updateNoOfSavedCard,
  updateConsultation,
  userMe,
  getCartItems,
  getEscriptItems,
  refetchEscript
};

export default actions;
