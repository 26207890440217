import React, { FC } from 'react';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import { IMeResponse } from 'utils/models';
import { useGlobalLoading } from 'hooks/useGlobalLoading';

// graphql
import { useMutation } from '@apollo/client';
import mutation from 'graphql/mutation';

// components
import ShopConsentTerms from 'components/privacy-consent/ShopConsentTems';
import Button from 'components/Button';
import { toast } from 'components/Toast';

interface ICartConsent {
  close: (error: boolean) => void;
}

const CartConsent: FC<ICartConsent> = ({ close }) => {
  const { getMe } = useAuth();
  const { setShowLoader } = useGlobalLoading();

  const [updateMe] = useMutation<{
    patientUpdateProfile: IMeResponse;
  }>(mutation.patientUpdateProfile, {
    fetchPolicy: 'network-only'
  });

  const handleAcceptTerms = async () => {
    setShowLoader(true);
    try {
      const { data } = await updateMe({
        variables: {
          input: {
            shop_consent_approved: true
          }
        }
      });

      if (data?.patientUpdateProfile.code === '200' && data.patientUpdateProfile.success) {
        await getMe();
        setShowLoader(false);
      }
    } catch (err) {
      toast({
        title: 'Error',
        message: 'An error occured',
        variant: 'error'
      });
      setShowLoader(false);
    }
  };

  return (
    <div className="space-y-8 divide-y  overflow-y-clip md:-mt-5">
      <div className="space-y-1 px-5 mx-3 md:mr-20">
        <h2 className="text-left text-lg md:text-xl font-extrabold mt-0">
          Partner Pharmacy Terms of Sale
        </h2>
        <div className="font-base text-polln-solid-green-600 md:text-sm">
          We work with our independent partner pharmacies to fulfil your prescription. All goods
          purchased are subject to the following terms and conditions.
        </div>
        <div className="text-sm">Date: {format(new Date(), 'dd/MM/yyyy')}</div>
      </div>
      <div className="p-6 space-y-4">
        <ShopConsentTerms />

        <div className="flex justify-center flex-col md:flex-row">
          <Button
            type="submit"
            size="md"
            variant="green-btn-300"
            className="md:w-1/2"
            rounded="xl"
            classNameButton="uppercase font-extrabold text-sm justify-center !py-4 my-4 mx-4 md:my-4"
            onClick={handleAcceptTerms}
          >
            Accept
          </Button>
          <Button
            variant="red-btn-100-invert"
            rounded="xl"
            size="md"
            className="md:w-1/2"
            classNameButton="uppercase font-extrabold text-sm justify-center !py-4 my-1 mx-4 md:my-4"
            onClick={() => close(true)}
          >
            Decline
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartConsent;
