import React, { FC } from 'react';
import clsx from 'clsx';
import { STANDARD_SHIPPING_FEE } from 'utils/constants';

const ShopConsentTerms: FC = () => {
  return (
    <div
      className={clsx(
        'px-6 h-[calc(100dvh-31rem)] md:h-[calc(100dvh-21rem)] overflow-y-auto',
        'space-y-8 text-sm bg-gradient-to-t from-polln-white-200'
      )}
    >
      <ol className="list-decimal space-y-5">
        <li className="space-y-1">
          <div className="font-bold">Shipping Cost</div>
          <p>
            Express shipping with signature on delivery is a flat rate of ${STANDARD_SHIPPING_FEE}{' '}
            Australia-wide.
          </p>
        </li>
        <li className="space-y-1">
          <div className="font-bold">Delivery Timeframes</div>
          <ul className="list-disc px-5">
            <li>
              Once payment has been confirmed, your prescription order will be processed and shipped
              to you within 2–5 business days. In some cases your product may not be immediately
              available with the supplier, which may delay processing. We will notify you if there
              is a delay to your prescription order outside of our usual time frame.
            </li>
            <li>
              Polln and our Partner Pharmacies strive to provide the best standard of delivery
              possible. We offer nationwide express post delivery via Australia Post. Express
              Shipping Timeframes:{' '}
              <a
                href="https://auspost.com.au/serviceupdates/domestic-delivery-times"
                target="_blank"
                rel="noreferrer"
              >
                auspost.com.au/serviceupdates/domestic-delivery-times
              </a>
            </li>
            <li>
              Please note - these are estimated timeframes provided by AusPost and may be subject to
              network congestion and COVID delays. Polln and Polln partner pharmacies are not liable
              for any delivery delays by third-party couriers.
            </li>
          </ul>
        </li>
        <li className="space-y-1">
          <div className="font-bold">Acceptance of Goods</div>

          <ul className="list-disc px-5">
            <li>
              To ensure your privacy and the safe delivery of your products, all of our pharmacy
              deliveries require a signature upon acceptance. Please ensure you have allocated a
              delivery address where someone will be available to sign for your delivery between the
              hours of 9am–5pm, Monday to Friday.
            </li>
            <li>
              Prescription products cannot be given an authority to leave direction. If no one is
              present to sign for your products, your package will be taken to the nearest post
              office for collection and a card with collection instructions will be left at your
              address.
            </li>
          </ul>
        </li>
        <li className="space-y-1">
          <div className="font-bold">Title & Risk</div>
          <ul className="list-disc px-5">
            <li>
              The sale occurs upon your order leaving the dispensing pharmacy and the title to the
              goods pass to you at that time. You are the legal owner of the products, are
              responsible for those products and the products are at your risk upon delivery.
            </li>
            <li>
              Unfortunately, refunds may not be provided on prescription items, pharmacy medicines
              or pharmacist-only medicines. This is due to state health regulations as the products
              are unable to be returned to stock or re-sold after leaving the premises. Storage
              conditions and therefore, the effectiveness of the medication, are unable to be
              guaranteed once this has occurred.
            </li>
          </ul>
        </li>
        <li className="space-y-3">
          <div className="font-bold">Please contact Polln if the item is:</div>
          <ul className="list-disc px-5">
            <li>Faulty or is not as described;</li>
            <li>Significantly different to what was advertised;</li>
            <li>The product is unsafe; or</li>
            <li>Not of acceptable quality.</li>
          </ul>
          <div className="space-y-6">
            <div>
              As per TGA guidelines, the acceptable weight variation of prescription products may
              vary up to 10% of the total weight value and will not be subject to a refund or return
              on this basis.
            </div>
            <div>
              All products dispensed by Polln partner pharmacies are based on industry Recommended
              Retail Price (RRP) and manufacturer retail costs.
            </div>
            <div>
              The pharmacy reserves the right to return the product to the manufacturer for further
              evaluation. The outcome of this evaluation will be provided, and no refund or
              replacement will be provided if the item was damaged due to misuse or neglect.
            </div>
            <div>No returns, refunds or exchanges will be offered for changes of mind.</div>
          </div>
        </li>
        <li className="space-y-1">
          <div className="font-bold">Damaged or Incorrect Products</div>
          <ul className="list-disc px-5">
            <li>
              Please contact Polln within 3 days of receipt of the products: If your products
              arrived damaged; or If the incorrect item was delivered.
            </li>
            <li>
              You may be required to provide us with an image of the damaged or incorrect product in
              order to process a refund or replacement.
            </li>
            <li>The cost of freight will be covered for these returned products.</li>
          </ul>
        </li>

        <li className="space-y-1">
          <div className="font-bold">Prescription Expiry</div>
          <ul className="list-disc px-5">
            <li>
              Medication prescriptions do expire – Schedule 8 medication prescriptions are valid for
              six months, and Schedule 4, for twelve months from the date of prescribing. So please
              keep this in mind too, as you will need to book a return consultation with your doctor
              if you need to access these prescriptions after their expiry date.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default ShopConsentTerms;
