import React, { FC } from 'react';
import { format } from 'date-fns';

const PrivacyConsentTerms: FC = () => {
  return (
    <div className="p-4 rounded-md text-polln-black-200 space-y-2 bg-gradient-to-t from-polln-white-200 divide-y">
      <div className="pb-4 md:-mt-5 md:ml-3">
        <div className="text-base !text-left font-bold text-polln-green-900 my-0 md:my-5">
          We care about your privacy and security.
        </div>
        <h2 className="text-left text-lg font-black md:!-mt-6 mt-0">
          Polln Client Declaration and Consent Form
        </h2>
        <div className="text-sm md:!-mt-1">Date: {format(new Date(), 'dd/MM/yyyy')}</div>
      </div>

      <div className="overflow-y-scroll h-[calc(100dvh-30rem)] md:h-[calc(100vh-27rem)] pt-5">
        <div className="px-3 md:px-6 text-sm">
          <ol className="list-decimal space-y-5">
            <li className="space-y-1">
              <div className="font-bold">Purpose of this consent form</div>
              <p>
                The purpose of this consent form is to provide you with information about Polln’s
                services.
              </p>
            </li>
            <li className="space-y-1">
              <div className="font-bold">Benefits and risks of using Polln’s platform</div>
              <div>Benefits</div>
              <ul className="list-disc px-5 md:px-8">
                <li>
                  Polln enables you to seek consultations with qualified doctors and other
                  practitioners.
                </li>
                <li>Polln provides services via an online platform, eliminating travel time.</li>
              </ul>
              <div>Risks</div>
              <ul className="list-disc px-5 md:px-8">
                <li>
                  Whilst Polln has privacy and security measures in place, the privacy and security
                  of information transmitted via the internet cannot be guaranteed.
                </li>
                <li>Some technical issues may be experienced during online consultations.</li>
                <li>
                  Complementary and alternative medicines, including medicinal cannabis, may not be
                  registered by the Therapeutic Goods Administration (Australia’s regulator of
                  therapeutic goods) for use in your condition.
                </li>
                <li>
                  Polln is a telehealth platform which has some inherent limitations compared to
                  face to face consultations. Your practitioner’s assessment of your condition may
                  not be as complete as if conducted in a face to face setting.
                </li>
              </ul>
            </li>
            <li className="space-y-1">
              <div className="font-bold">
                Use of medicinal cannabis and other alternative or complementary medicines
              </div>
              <div>I acknowledge that</div>
              <ul className="list-disc px-5 md:px-8">
                <li>
                  my use of the Polln website is subject to its User Terms and Conditions which may
                  be amended from time to time.
                </li>
                <li>
                  complementary and alternative medicines, including medicinal cannabis, prescribed
                  or recommended by doctors or other practitioners consulted via the Polln website
                  may carry risks.
                </li>
                <li>
                  it is the responsibility of the relevant practitioner to provide advice
                  surrounding medicinal cannabis, complementary and alternative medicines. Polln
                  will not be held liable for the advice provided by doctors or other practitioners
                  who use our platform.
                </li>
                <li>
                  it is the responsibility of the prescribing practitioner to inform me of the risks
                  and potential side effects of the treatment prescribed based on the information
                  you will provide to the practitioner. Polln will not be held liable for side
                  effects or adverse events resulting from treatment, regardless of whether I was
                  advised of the risk of these side effects or adverse events.
                </li>
                <li>
                  it is my responsibility to provide Polln and practitioners consulted via the Polln
                  website, with complete and accurate information as requested. A failure to provide
                  complete and accurate information may impact upon the quality of services that I
                  receive.
                </li>
                <li>
                  it is my responsibility to ensure that I use products prescribed through the Polln
                  platform in accordance with the advice of the prescribing practitioner.
                </li>
                <li className="space-y-1">
                  Medical cannabis(MC)treatment includes:
                  <ul className="list-disc px-5 md:px-8">
                    <li>Ensure to maintain a healthy lifestyle that will help my illness</li>
                    <li>Regular monthly reviews</li>
                    <li>
                      Regular blood tests to ensure the MC is not causing any liver or kidney
                      problems
                    </li>
                    <li>Following my GPs advice on dosage & frequency of MC use</li>
                    <li>Ensuring I report to my GP if I sufferany adverse event or side-effect</li>
                    <li>
                      Avoid the use of illicit and unprescribed medication that may interact with MC
                    </li>
                    <li>Avoid the use of alcohol that may interact with MC</li>
                    <li>
                      Avoid driving or use of machinery if the MC causes any symptoms that may
                      affect my judgement
                    </li>
                    <li>Report to my GP any clinical benefits of MC</li>
                    <li>Report to my GP if MC does not work for me</li>
                  </ul>
                </li>
                <li className="space-y-1">
                  <div>Medical Cannabis drugs are experimental / investigational drugs:</div>
                  <div>
                    Medical Cannabis drugs are not registered in Australia for use in your condition
                    by the Therapeutic Goods Administration of the Australian Department of Health
                    and Ageing. Therefore arrangements to access / import the drugs need to be made
                    through a Special Access Scheme I acknowledge that potentially all costs of
                    importing and accessing the medical cannabis drug(s) (including the cost of the
                    drug itself) are my personal responsibility. The government and private health
                    insurance may not contribute to any of the costs.
                  </div>
                </li>

                <div>The possibility of unknown risks and late side effects.</div>
                <div>
                  Cannabis treatment drugs are still in an experimental phase of testing. There may
                  be side effects and risks which are currently unknown. In the event that your
                  treatment is ongoing you will need to be updated with any significant information
                  regarding previously unknown risks.
                </div>

                <li className="space-y-1">
                  <div>
                    Possible side-effects of a treatment with medicinal cannabis may include:
                  </div>

                  <ul className="list-disc px-5 md:px-8">
                    <li>Asthenia (abnormal physical weakness or lack of energy)</li>
                    <li>Confusion, Disorientation, Dizziness, Drowsiness, Vertigo, Sleepiness</li>
                    <li>Avoid machinery and driving if this occurs</li>
                    <li>
                      Avoid concomitant use of alcohol, drugs of addiction, and medication that may
                      aggravate these side-effects should they occur
                    </li>
                    <li>Balance problems, coordination</li>
                    <li>Memory problems</li>
                    <li>Diarrhea</li>
                    <li>Dry Mouth</li>
                    <li>Fatigue</li>
                    <li>Hallucinations</li>
                    <li>Vomiting or Nausea</li>
                  </ul>
                </li>
                <br />
                <li className="space-y-1">
                  <div className="font-bold">Possible chronic effects may include:</div>
                  <ul className="list-disc px-5 md:px-8">
                    <li>cannabis use disorder</li>
                    <li>cognitive impairment</li>
                    <li>chronic bronchitis [if inhaled - not advised]</li>
                  </ul>
                </li>
                <br />
                <li className="space-y-1">
                  <div className="font-bold">Possible social dysfunction may result</div>
                  <ul className="list-disc px-5 md:px-8">
                    <li>at work/school</li>
                    <li>motor vehicle accidents</li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="space-y-1">
              <div className="font-bold">Polln client platform</div>
              <div>
                I consent to participate in the Polln client platform for medicinal cannabis or
                complementary or alternative medicine users. I agree that
              </div>
              <ul className="list-disc px-5 md:px-8">
                <li>
                  once available, I may be been given access to the Polln client platform. I consent
                  to use the Polln client platform to monitor information about my condition,
                  symptoms, treatments, and any other personal details.
                </li>
                <li>
                  the Polln client platform will track and monitor my personal details, clinical
                  details and medicinal cannabis or complementary medicine data including
                  information about my health conditions and responses.
                </li>
                <li>
                  any data entered in the Polln client platform is stored and may be used by Polln
                  or Polln’s associates (in de-identified form) for the purpose of research into my
                  condition and related purposes. I release any claim I may have over the data
                  collected by Polln.
                </li>
              </ul>
            </li>
            <li className="space-y-1">
              <div className="font-bold">Privacy</div>
              <div>
                Polln must comply with the <i>Privacy Act 1988</i> (Cth). I acknowledge that Polln
                may collect, use and disclose my personal information in accordance with their
                Privacy Policy. I agree that my use of the Polln platform is subject to the Privacy
                Policy, which may be amended from time to time.
              </div>
            </li>
            <li className="space-y-1">
              <div className="font-bold">Cancellation Policy</div>
              <div>I acknowledge that</div>
              <ul className="list-disc px-5 md:px-8">
                <li>
                  Polln charges a consultation fee on behalf of our Polln Practitioners. The
                  consultation fee is charged at the time of booking and is non-refundable.
                </li>
                <li>Changes to my booking must be made with at least 24 hours’ notice.</li>
                <li>
                  I will be charged a cancellation fee equal to the cost of the consultation for any
                  missed or cancelled appointments where I have provided less than 24 hours’ notice.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyConsentTerms;
