import React, { FC, ReactNode, useState, useEffect, useCallback } from 'react';
import createToast from 'utils/toast';
import { CheckCircleIcon, ExclamationCircleIcon, XIcon } from '@heroicons/react/outline';

// components
import MotionToast from 'components/motions/MotionToast';

interface ToastProps {
  giveAnswer: (x: boolean) => void;
  title: ReactNode;
  message?: ReactNode;
  variant?: 'success' | 'error';
  timeout?: number;
}

const Toast: FC<ToastProps> = ({
  giveAnswer,
  title,
  message,
  variant = 'success',
  timeout = 6000
}) => {
  const [open, setOpen] = useState(true);

  const close = useCallback(
    (answer: boolean) => {
      setOpen(false);
      setTimeout(() => {
        giveAnswer(answer);
      }, 300);
    },
    [giveAnswer]
  );

  const [hover, setHover] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      !hover && close(false);
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout, close, hover]);

  useEffect(() => {
    const handleClose = () => close(false);

    window.addEventListener('keyup', handleClose);
    return () => window.removeEventListener('keyup', handleClose);
  }, [close]);

  return (
    <MotionToast show={open} className="pointer-events-none my-2 w-full flex justify-end">
      <div
        className="max-w-sm w-full mr-4 bg-white shadow-lg rounded-lg pointer-events-auto relative toast"
        onClick={() => close(true)}
        onMouseOut={() => setHover(false)}
        onMouseOver={() => setHover(true)}
      >
        <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                {variant === 'success' ? (
                  <CheckCircleIcon className="h-6 w-6 text-green-400" />
                ) : variant === 'error' ? (
                  <ExclamationCircleIcon className="h-6 w-6 text-red-400" />
                ) : null}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm leading-5 font-medium text-gray-900">{title}</p>
                {message && <p className="mt-1 text-sm leading-5 text-gray-500">{message}</p>}
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                  <XIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MotionToast>
  );
};

export default Toast;

interface ToastFnProps {
  title: ReactNode;
  message?: ReactNode;
  variant?: 'success' | 'error';
  timeout?: number;
}

export const toast = async ({ title, message, variant, timeout }: ToastFnProps) => {
  await createToast(({ giveAnswer }) => (
    <Toast
      giveAnswer={giveAnswer}
      title={title}
      message={message}
      variant={variant}
      timeout={timeout}
    />
  ));
};
