import React, { FC, ReactNode, useState } from 'react';
import { ClassValue } from 'clsx';
import { TButtonRound, TButtonSize, TVariant } from 'utils/types';

// graphql
import { useMutation } from '@apollo/client';
import mutation from 'graphql/mutation';

// component
import Button from 'components/Button';
import { toast } from 'components/Toast';

interface IButtonUpdateCart {
  escriptId: string;
  quantity: number;
  className?: ClassValue;
  classNameButton?: ClassValue;
  variant?: TVariant;
  rounded?: TButtonRound;
  size?: TButtonSize;
  refetch?: () => Promise<any> | void;
  disabled?: boolean;
  setDisabler?: (x: boolean) => void;
  toggleDisablerAfter?: boolean;
  children: ReactNode;
}

const ButtonUpdateCart: FC<IButtonUpdateCart> = ({
  escriptId,
  quantity,
  className,
  classNameButton,
  variant,
  rounded,
  size,
  refetch,
  disabled = false,
  setDisabler,
  toggleDisablerAfter = false,
  children
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [updateItemQuantity] = useMutation<{
    paymentAddToCart: {
      code: string;
      success: boolean;
      message: string;
    };
  }>(mutation.paymentAddToCart, {
    fetchPolicy: 'network-only'
  });

  const handleUpdateQuantity = async () => {
    setIsLoading(true);
    setDisabler && setDisabler(true);
    try {
      const { data } = await updateItemQuantity({
        variables: {
          input: {
            escript_id: escriptId,
            quantity
          }
        }
      });

      if (data?.paymentAddToCart.code === '200' && data.paymentAddToCart.success) {
        if (refetch) await refetch();
        toggleDisablerAfter && setDisabler && setDisabler(false);
      }

      if (data?.paymentAddToCart.code === '422' && !data.paymentAddToCart.success) {
        toast({
          title: 'Update quantity',
          message: data.paymentAddToCart.message,
          variant: 'error'
        });
        setDisabler && setDisabler(false);
      }

      setIsLoading(false);
    } catch (error) {
      toast({
        title: 'Error',
        message: 'An error occurred',
        variant: 'error'
      });
      setIsLoading(false);
      setDisabler && setDisabler(false);
    }
  };

  return (
    <Button
      variant={variant}
      rounded={rounded}
      size={size}
      className={className}
      classNameButton={classNameButton}
      onClick={() => handleUpdateQuantity()}
      isLoading={isLoading}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default ButtonUpdateCart;
