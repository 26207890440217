import React, { FC, ReactNode, useState } from 'react';
import { ExclamationCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import create from 'zustand';

// components
import Modal from 'components/Modal';
import Button from 'components/Button';
import Stack from 'components/Stack';
import clsx from 'clsx';
import { TVariant } from 'utils/types';

const variants: any = {
  error: 'text-red-500',
  success: 'text-green-500',
  warning: 'text-yellow-500'
};

const ConfirmDialog: FC = () => {
  const {
    title,
    message,
    onSubmit,
    close,
    open,
    confirmVariant = 'danger',
    confirmText = 'Confirm',
    cancelText = 'Go Back',
    variant = 'error'
  } = useConfirmDialogStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await onSubmit(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setIsLoading(false);
      close();
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      title={title}
      classNameTitle="bg-polln-white-200 text-black"
      round="lg"
      isConfirmOpen
      hasCloseIcon={false}
      disableClose={isLoading}
    >
      <div className="space-y-4">
        <div className="flex justify-center">
          {variant === 'warning' ? (
            <ExclamationIcon className={clsx('h-20 w-20', variants[variant])} />
          ) : (
            <ExclamationCircleIcon className={clsx('h-20 w-20', variants[variant])} />
          )}
        </div>
        <div className="text-center text-sm">{message}</div>

        <Stack spacingX={3} className="justify-center">
          <Button
            variant="invisible"
            rounded="xl"
            classNameButton="px-8 py-2 text-xs font-extrabold uppercase"
            disabled={isLoading}
            onClick={close}
          >
            {cancelText}
          </Button>
          <Button
            variant={confirmVariant}
            rounded="xl"
            classNameButton="px-8 py-2 text-xs font-extrabold  uppercase"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => handleConfirm()}
          >
            {confirmText}
          </Button>
        </Stack>
      </div>
    </Modal>
  );
};

interface IConfirm {
  title?: string;
  message: ReactNode;
  variant?: 'error' | 'success' | 'warning';
  onSubmit: (x: boolean) => Promise<any> | void;
  confirmVariant?: TVariant;
  confirmText?: string;
  cancelText?: string;
}

const useConfirmDialogStore = create<IConfirm & { close: () => void; open: boolean }>((set) => ({
  title: '',
  message: '',
  open: false,
  variant: 'error',
  onSubmit: (open: boolean) => set({ open }),
  confirmVariant: 'danger',
  confirmText: '',
  cancelText: '',
  close: () => set({ open: false })
}));

export const confirmDialog = ({
  title,
  message,
  variant = 'error',
  confirmVariant = 'danger',
  confirmText = 'Confirm',
  cancelText = 'Go Back',
  onSubmit
}: IConfirm) => {
  useConfirmDialogStore.setState({
    title,
    open: true,
    variant,
    message,
    confirmVariant,
    confirmText,
    cancelText,
    onSubmit
  });
};

export default ConfirmDialog;
