import React, { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { NumericFormat } from 'react-number-format';
import { ICartItem, ICheckoutDetails, IPaymentCardToken, IPaymentProp } from 'utils/models';
import { TShippingDetailsForm } from 'utils/types';
import { PICKUP_SHIPPING_FEE, STANDARD_SHIPPING_FEE, STEPS_CART } from 'utils/constants';
import { useGlobalLoading } from 'hooks/useGlobalLoading';

// gql
import { useLazyQuery, useMutation } from '@apollo/client';
import mutation from 'graphql/mutation';
import query from 'graphql/query';

// components
import FormZaiPayment from 'components/forms/FormZaiPayment';
import LoaderSpinner from 'components/LoaderSpinner';
import Button from 'components/Button';
import CartItems from 'components/cart/CartItems';
import Accordion from 'components/Accordion';
import { toast } from 'components/Toast';
import Checkbox from 'components/Checkbox';
import useWallet from 'hooks/useWallet';

interface ICartCheckout {
  items: ICartItem[];
  refetch: () => Promise<any> | void;
  shippingDetails: TShippingDetailsForm | null;
  setCheckoutDetails: (details: ICheckoutDetails) => void;
  totalPrice: number;
  step: 1 | 2 | 3;
  setStep: (x: 1 | 2 | 3) => void;
  close: (error: boolean) => void;
  isLoading?: boolean;
}

const CartCheckout: FC<ICartCheckout> = ({
  items,
  refetch,
  shippingDetails,
  setCheckoutDetails,
  totalPrice,
  step,
  setStep,
  close,
  isLoading = false
}) => {
  const [useWalletBalance, setUseWalletBalance] = useState<boolean>(false);
  const { setShowLoader, showLoader } = useGlobalLoading();
  const { walletBalance } = useWallet();

  const [getPaymentToken, { data, loading }] = useLazyQuery<{
    paymentCardToken: {
      code: string;
      message: string;
      success: boolean;
      data: IPaymentCardToken;
    };
  }>(query.paymentCardToken, {
    fetchPolicy: 'network-only'
  });
  const paymentToken = data?.paymentCardToken;

  const [payCart] = useMutation<{
    paymentCheckout: {
      code: string;
      success: boolean;
      message: string;
      data: ICheckoutDetails;
    };
  }>(mutation.paymentCheckout, { fetchPolicy: 'network-only' });

  /**
   * on submit payment checkout
   * @param paymentId
   */
  /**
   * on submit payment checkout
   * @param paymentId
   */
  const onSubmitPayment = async ({ paymentId, isSaveCard, isExist }: IPaymentProp) => {
    if (paymentId === 'WALLET') {
      setShowLoader(true);
    }

    await payCart({
      variables: {
        input: {
          name: shippingDetails?.name,
          address: shippingDetails?.address,
          email: shippingDetails?.email,
          phone: shippingDetails?.phone,
          shipping: shippingDetails?.shippingOption,
          source_id: paymentId,
          is_save_card: isSaveCard,
          is_exist: isExist,
          use_patient_wallet: useWalletBalance
        }
      },
      onCompleted: (data) => {
        if (data?.paymentCheckout.code === '200' && data.paymentCheckout.success) {
          setCheckoutDetails(data.paymentCheckout.data);
          close(false);
        }

        if (!data?.paymentCheckout.success) {
          close(true);
          toast({
            title: 'Error',
            message: 'Payment has been declined. Please contact your bank.',
            variant: 'error'
          });
        }

        setShowLoader(false);
      },
      onError: (error) => {
        toast({
          title: 'Payment error',
          message: 'An error occurred',
          variant: 'error'
        });
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    if (paymentToken && !paymentToken?.success) {
      toast({
        title: 'Error',
        message: paymentToken.message,
        variant: 'error'
      });
      close(true);
    }
  }, [paymentToken, close]);

  useEffect(() => {
    getPaymentToken();
  }, [getPaymentToken]);

  const memoOrderTotal = useMemo(
    () =>
      (shippingDetails?.shippingOption === 'STANDARD'
        ? STANDARD_SHIPPING_FEE
        : PICKUP_SHIPPING_FEE) +
      (totalPrice || 0) -
      (useWalletBalance ? walletBalance : 0),
    [shippingDetails, totalPrice, useWalletBalance, walletBalance]
  );

  if (loading) {
    return (
      <div className="w-full flex justify-center items-center z-[999] h-full">
        <LoaderSpinner className="w-10 h-20" />
      </div>
    );
  }

  return (
    <div className="grid gap-3 grid-cols-12">
      <div className="col-span-12 md:col-span-6">
        <div className="hidden md:block shrink-0">
          <CartItems
            step={step}
            setStep={setStep}
            totalPrice={totalPrice || 0}
            items={items}
            refetch={refetch}
            isLoading={isLoading}
            showUpdateButtons
            className="h-[calc(100vh-9rem)] overflow-y-auto"
          />
        </div>

        <div className="block md:hidden shrink-0">
          <Accordion title={`Show order summary: $${totalPrice || 0}`}>
            <CartItems
              step={step}
              setStep={setStep}
              totalPrice={totalPrice || 0}
              items={items}
              refetch={refetch}
              isLoading={isLoading}
              showUpdateButtons
              className="h-[calc(100vh-25rem)] overflow-y-auto"
            />
          </Accordion>
        </div>
      </div>

      <div className="col-span-12 md:col-span-6">
        {paymentToken?.data && shippingDetails && (
          <FormZaiPayment
            cardToken={paymentToken.data}
            onSuccess={onSubmitPayment}
            className={clsx(
              'overflow-y-auto p-8',
              'h-[calc(100dvh-24rem)] md:h-[calc(100vh-21rem)]'
            )}
            customSubmit={
              <div
                className={clsx('p-5 w-full space-y-2 border bg-white fixed bottom-0 md:relative')}
              >
                <div className="flex items-center space-x-1 text-sm text-gray-500 underline">
                  <Checkbox
                    label="Apply wallet balance?"
                    value={useWalletBalance}
                    onChange={(flag) => setUseWalletBalance(flag)}
                    className="justify-end flex"
                    disabled={walletBalance <= 0}
                  />

                  <NumericFormat
                    value={walletBalance}
                    prefix="($"
                    suffix=")"
                    decimalScale={2}
                    thousandSeparator
                    className="italic"
                    displayType="text"
                  />
                </div>

                <div className="mb-4">
                  <div className="text-sm md:text-base font-bold">
                    <div className="flex items-center space-x-3 justify-end">
                      <div>Shipping:</div>

                      <NumericFormat
                        value={
                          shippingDetails?.shippingOption === 'STANDARD'
                            ? STANDARD_SHIPPING_FEE
                            : PICKUP_SHIPPING_FEE
                        }
                        prefix="$"
                        decimalScale={2}
                        thousandSeparator
                        displayType="text"
                      />
                    </div>
                  </div>

                  <div className="text-md md:text-xl font-bold">
                    <div className="flex items-center text-sm md:text-base space-x-3 justify-end">
                      <div>Product Total:</div>
                      <NumericFormat
                        value={totalPrice}
                        prefix="$"
                        decimalScale={2}
                        thousandSeparator
                        displayType="text"
                      />
                    </div>
                  </div>

                  <div className="flex font-bold items-center space-x-3  text-sm md:text-base justify-end">
                    <div>Order Total:</div>

                    <NumericFormat
                      value={memoOrderTotal > 0 ? memoOrderTotal : 0}
                      prefix="$"
                      decimalScale={2}
                      thousandSeparator
                      displayType="text"
                    />
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <Button
                    type={memoOrderTotal > 0 ? 'submit' : 'button'}
                    variant="green-btn-500"
                    size="lg"
                    className="w-full h-14"
                    classNameButton="justify-center md:py-4"
                    rounded="lg"
                    isLoading={showLoader}
                    onClick={() =>
                      memoOrderTotal <= 0 &&
                      onSubmitPayment({ paymentId: 'WALLET', isSaveCard: false, isExist: false })
                    }
                  >
                    <span className="font-extrabold text-xs md:text-sm uppercase">
                      {STEPS_CART[step]}
                    </span>
                  </Button>
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default CartCheckout;
