import axiosLib, { AxiosRequestConfig, AxiosError } from 'axios';
import storage from 'utils/storage';
import { CONF_API_URL } from 'utils/config';

export const api = axiosLib.create({
  baseURL: CONF_API_URL
});

api.interceptors.request.use(async (config: any) => {
  const token = storage.getItem('token');
  config.headers.Authorization = token;
  return config;
});

const ApiCalls = {
  get: async (url: string, options?: AxiosRequestConfig) => {
    try {
      return await api.get(url, options);
    } catch (error: any) {
      error.request = { url, options };
      throw error;
    }
  },
  delete: async (url: string, options?: AxiosRequestConfig) => {
    try {
      return await api.delete(url, options);
    } catch (error: any) {
      error.request = { url, options };
      throw error;
    }
  },
  post: async (url: string, data: any = {}, options?: AxiosRequestConfig) => {
    try {
      return await api.post(url, data, options);
    } catch (error: any) {
      error.request = { url, data, options };
      throw error;
    }
  },
  put: async (url: string, data: any, options?: AxiosRequestConfig) => {
    try {
      return await api.put(url, data, options);
    } catch (error: any) {
      error.request = { url, data, options };
      throw error;
    }
  },
  patch: async (url: string, data: any, options?: AxiosRequestConfig) => {
    try {
      return await api.patch(url, data, options);
    } catch (error: any) {
      error.request = { url, data, options };
      throw error;
    }
  },
  handleError: (error: AxiosError, text?: string, report = true) => {
    // if (report && error.response.status) reportError(error.req, error)
    console.log(error);
  }
};

export default ApiCalls;
