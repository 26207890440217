import { TEnv } from './types';

export const CONF_ENVIRONMENT: TEnv = process.env.REACT_APP_NODE as TEnv;
export const CONF_API_URL = process.env.REACT_APP_API_URL;
export const CONF_SEO_URL = process.env.REACT_APP_SEO_URL;
export const CONF_STRIPE_ID = process.env.REACT_APP_STRIPE_ID;
export const CONF_DIGITAL_ID = process.env.REACT_APP_DIGITAL_ID;
export const CONF_DIGITAL_ID_URL = process.env.REACT_APP_DIGITAL_ID_URL;
export const CONF_MAP_API = process.env.REACT_APP_MAP_API;
export const CONF_GA = process.env.REACT_APP_GA;
export const CONF_GA4 = process.env.REACT_APP_GA4;
export const CONF_SHOP_URL = process.env.REACT_APP_SHOP_URL;
export const CONF_DSN_SENTRY = process.env.REACT_APP_SENTRY_DSN;
