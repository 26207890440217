import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import Card from 'components/Card';
import Button from 'components/Button';

// assets
import IconMedsImage from 'assets/images/icon-meds.png';

const CardNoCartItem: FC<{ close: (error: boolean) => void }> = ({ close }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/prescriptions', { replace: true });
    close(true);
  };

  return (
    <div className="px-6 py-3">
      <Card className="bg-gray-25">
        <div className="space-y-10 text-center p-8">
          <img src={IconMedsImage} alt="Medicine" className="d-block mx-auto" />

          <div className="font-base px-10 md:px-24">
            It looks like you don’t have any items in your cart.
          </div>

          <Button
            size="xl"
            variant="green-btn-500"
            className="w-60 md:w-full"
            classNameButton="w-full !text-sm  uppercase font-extrabold justify-center"
            rounded="xl"
            onClick={handleClose}
          >
            My Prescriptions
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default CardNoCartItem;
