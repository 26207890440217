import { saveAs } from 'file-saver';
import {
  format,
  differenceInYears,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek
} from 'date-fns';
import * as Sentry from '@sentry/browser';
import { TCalendarView, TGenders } from 'utils/types';
import { GENDER_OPTIONS, MOBILE_PREFIX } from 'utils/constants';
import { IDoctorDetails, IFileCheckResponse, ISelectOptions, IUser } from 'utils/models';
import { CONF_API_URL } from 'utils/config';
import PhotoPlaceholderDoctor from 'assets/images/placeholder-doctor.png';
import PhotoPlaceholderPatient from 'assets/images/placeholder-upload.jpg';

import { isPlainObject } from 'is-plain-object';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export const ensureMediaPermissionsWithDetails = async (
  name: string,
  consultationId: string | undefined,
  referenceId: string | undefined
) => {
  return await navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()))
          .catch((error) => {
            Sentry.captureMessage(
              `Patient ${name} denied camera and audio permission for consultation ID: ${consultationId} with reference ID: ${referenceId}, ERROR: ${error}`
            );

            return error;
          });
      }
    });
};

export const ensureMediaPermissions = async () => {
  return await navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()))
          .catch((error) => {
            Sentry.captureMessage(`Patient denied camera and audio permission. ERROR: ${error}`);

            return error;
          });
      }
    });
};

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

const genders: any = {
  MALE: 'Male',
  FEMALE: 'Female',
  NOTTOSAY: 'Preferred not to say'
};

export const range = (start: number, end: number) =>
  Array.from(Array(Math.abs(end - start) + 1), (_, i) => start + i);

export const countDown = (timer: number) => {
  const totalSeconds = (timer %= 3600);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return {
    minutes,
    seconds
  };
};

export const fileToObjectUrl = (image: File | string) =>
  image instanceof File ? URL.createObjectURL(image) : image;

export const getGender = (gender: TGenders, genderOther: string) => {
  const genderLabel = GENDER_OPTIONS.find((item: ISelectOptions) => item.value === gender);
  return gender === 'OTHERS' ? genderOther : genderLabel?.label;
};

export const getDobAge = (dateOfBirth: Date) => {
  const dob = format(new Date(dateOfBirth), 'MM/dd/yyyy');
  const age = differenceInYears(new Date(), new Date(dob));

  return {
    dob,
    age
  };
};

export const getConditions = (user: IUser | null) => {
  let conditions = '-';

  if (user) {
    const arrConditions: string[] = [];
    if (user.profile.conditions) {
      arrConditions.push(JSON.parse(user.profile.conditions));
    }

    arrConditions.push(user.profile.other_conditions);
    conditions = arrConditions.join(', ');
  }

  return conditions;
};

export const getGenderValue = (gender: TGenders | undefined, type: 'select' | 'input') => {
  let genderValue: any = gender
    ? !(gender === 'FEMALE' || gender === 'MALE' || gender === 'NOTTOSAY')
      ? 'OTHERS'
      : gender
    : '';

  if (type === 'input') {
    genderValue =
      gender && !(gender === 'FEMALE' || gender === 'MALE' || gender === 'NOTTOSAY') ? gender : '';
  }

  return {
    value: genderValue,
    label: gender
      ? !(gender === 'FEMALE' || gender === 'MALE' || gender === 'NOTTOSAY')
        ? gender
        : genders[gender]
      : ''
  };
};

export const getProfilePicInput = (profilePic: string | null | undefined) => {
  let profilePicUrl = profilePic;

  if (profilePic && profilePic.includes('https://')) {
    const split = profilePic.split('/');
    const splitSource = split[4].split('?');
    profilePicUrl = `${split[3]}/${splitSource[0]}`;
  }

  return profilePicUrl;
};

export const getPhoneNumber = (contactNumber: string) => {
  const phoneNumber = contactNumber.split(MOBILE_PREFIX);
  return phoneNumber[1];
};

export const checkValidNumber = (input: any) => input.match(/^[0-9]+$/);

export const fileChecker = ({
  file,
  fileTypes = [],
  fileSizeLimit
}: {
  file: File;
  fileTypes: string[];
  fileSizeLimit: number;
}) => {
  let response: IFileCheckResponse = {
    success: true,
    message: ''
  };

  if (file) {
    // @ts-ignore
    const ext = file.name.split('.').pop().toLowerCase();
    const validFileTypes = fileTypes;

    if (validFileTypes.includes(ext)) {
      const sizeLimit = fileSizeLimit / 1024;
      const sizeLimitByte = fileSizeLimit * 1000;
      const decimals = '';
      const k = 1024;
      const dm: number = decimals || 2;
      const sizes: string[] = ['Bytes', 'KB', 'MB'];
      const bigSizes: any = ['GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(file.size) / Math.log(k));
      const iC = Math.floor(Math.log(sizeLimitByte) / Math.log(k));
      const fileSize = parseFloat((file.size / Math.pow(k, i)).toFixed(dm));

      if ((fileSize > sizeLimit && sizes[i] === 'MB') || bigSizes.includes(sizes[i], bigSizes)) {
        const fSize = sizes[i];
        const cSize = sizes[iC];
        const limit = iC > 1 ? Math.round(sizeLimit) : fileSizeLimit;
        response = {
          success: false,
          message: `${file.name} has ${fileSize}${fSize} of file size. Please limit file size to ${limit}${cSize} and under only.`
        };
      }
    } else {
      response = {
        success: false,
        message: `${file.name} is not a valid file. We only accept ${validFileTypes.join(', ')}.`
      };
    }
  }

  return response;
};

export const photoPlaceholder: {
  patient: string;
  doctor: string;
} = {
  patient: PhotoPlaceholderPatient,
  doctor: PhotoPlaceholderDoctor
};

export const fullName = (user: IUser | IDoctorDetails | null) =>
  user ? `${user.profile.first_name} ${user.profile.last_name}` : '-';

export const downloadFile = (fileName: string, fileKey: string) =>
  saveAs(`${CONF_API_URL}/download?key=${fileKey}`, fileName);

export const calendarDate = ({ date, view }: { date: Date; view?: TCalendarView }) => {
  let formatDate = format(date, 'dd, MMMM yyyy');
  let from = format(date, 'yyyy-MM-dd 00:00:00');
  let to = format(date, 'yyyy-MM-dd 23:59:59');

  if (view === 'dayGridMonth') {
    formatDate = `${format(startOfMonth(date), 'dd, MMMM yyyy')} - ${format(
      endOfMonth(date),
      'dd, MMMM yyyy'
    )}`;

    from = format(startOfMonth(date), 'yyyy-MM-dd 00:00:00');
    to = format(endOfMonth(date), 'yyyy-MM-dd 23:59:59');
  }

  if (view === 'timeGridWeek') {
    formatDate = `${format(startOfWeek(date), 'dd, MMMM yyyy')} - ${format(
      endOfWeek(date),
      'dd, MMMM yyyy'
    )}`;

    from = format(startOfWeek(date), 'yyyy-MM-dd 00:00:00');
    to = format(endOfWeek(date), 'yyyy-MM-dd 23:59:59');
  }

  return {
    formatDate,
    fromUtc: new Date(from).toISOString(),
    from,
    to,
    toUtc: new Date(to).toISOString()
  };
};

export const getAddress = (user: IUser | null) =>
  user?.profile?.street && user?.profile?.state && user?.profile?.city && user?.profile?.postcode
    ? `${user?.profile?.street || '-'} ${user?.profile?.city || '-'}, ${
        user?.profile?.state || '-'
      }, ${user?.profile?.postcode || '-'}`
    : '';

export const formatId = (value: number, size: number) => {
  let s = value + '';
  while (s.length < size) s = '0' + s;
  return s;
};
