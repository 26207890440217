import React, { FC, ReactNode, useEffect, useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { TButtonRound, TShadow } from 'utils/types';

// components
import Expandable from './Expandable';

interface IAccordion {
  title: string;
  children: ReactNode;
  background?: 'white' | 'gray';
  rounded?: TButtonRound;
  shadow?: TShadow;
  classNameTitle?: ClassValue;
  open?: boolean;
}

const backgrounds = {
  white: 'bg-white',
  gray: 'bg-gray-15'
};

const roundedness = {
  none: '',
  sm: 'rounded-sm',
  rounded: 'rounded',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full'
};

const shadows = {
  sm: 'shadow-sm',
  shadow: 'shadow',
  md: 'shadow-md',
  lg: 'shadow-lg',
  xl: 'shadow-xl',
  '2xl': 'shadow-2xl',
  inner: 'shadow-inner',
  none: 'shadow-none'
};

const Accordion: FC<IAccordion> = ({
  title,
  children,
  background = 'white',
  rounded = 'md',
  shadow = 'md',
  classNameTitle,
  open = false
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div
      className={clsx(
        'flex align-center flex-col',
        backgrounds[background],
        roundedness[rounded],
        shadows[shadow]
      )}
    >
      <div
        className="cursor-pointer px-5 py-3 text-white inline-block hover:opacity-75 hover:shadow"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center justify-between flex-wrap text-black sm:flex-nowrap">
          <h3 className={clsx(classNameTitle, 'font-bold')}>{title}</h3>

          <div className="flex justify-end space-x-3">
            <div>
              {isOpen ? (
                <ChevronUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDownIcon className="w-5 h-5" />
              )}
            </div>
          </div>
        </div>
      </div>

      <Expandable show={isOpen}>{children}</Expandable>
    </div>
  );
};

export default Accordion;
