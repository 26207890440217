import React, { FC } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ICheckoutDetails } from 'utils/models';

interface ICartSuccess {
  checkoutDetails: ICheckoutDetails;
}

const CartSuccess: FC<ICartSuccess> = ({ checkoutDetails }) => (
  <div className="space-y-7 text-center">
    <div className="space-y-4 bg-polln-solid-green-500 rounded-t-lg p-5">
      <div className="flex justify-center">
        <CheckCircleIcon className="w-20 h-20 mt-5 text-white" />
      </div>

      <div className="text-white text-sm">
        <div>Order ID #{checkoutDetails.reference_id}</div>
      </div>

      <div className="text-white font-bold text-lg !mt-2">
        <div>Thank you for your purchase!</div>
        <div>We have received your order.</div>
      </div>
    </div>

    <div className="font-bold text-base">
      <div>Delivery Information</div>
      <div className="font-medium text-sm text-gray-400">
        <div>{checkoutDetails.name}</div>
        <div>{checkoutDetails.address.street}</div>

        <div>
          {`${checkoutDetails.address.city} ${checkoutDetails.address.state}
          ${checkoutDetails.address.postcode}`}
        </div>
      </div>
    </div>

    <div className="font-bold text-base">
      A receipt has been sent to{' '}
      <div className="text-polln-solid-green-600 text-base">{checkoutDetails.email}</div>
    </div>
  </div>
);

export default CartSuccess;
