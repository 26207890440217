import React, { FC, ReactNode, useEffect, useState } from 'react';
import { motion, AnimatePresence, MotionStyle } from 'framer-motion';

interface IExpandable {
  show: boolean;
  style?: { [x: string]: string };
  children: ReactNode;
}

const Expandable: FC<IExpandable> = ({ show, style, children }) => {
  const [initialState, setInitialState] = useState<boolean>(show);
  const [isAnimating, setAnimating] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(show);

  useEffect(() => {
    if (show) {
      setAnimating(true);
      setTimeout(() => setVisible(true), 1);
      setTimeout(() => setAnimating(false), 400);
    } else {
      setAnimating(true);
      setTimeout(() => setVisible(false), 1);
      setTimeout(() => setAnimating(false), 400);
    }
  }, [show]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          style={
            {
              originY: 0,
              padding: '0.5rem',
              margin: '-0.5rem',
              overflow: isAnimating ? 'hidden' : 'visible',
              ...style
            } as MotionStyle
          }
          variants={{
            hidden: { height: 0 },
            visible: { height: 'auto' }
          }}
          transition={{
            type: 'tween',
            ease: 'easeOut',
            duration: 0.3
          }}
          onAnimationComplete={() => setInitialState(false)}
          initial={initialState ? 'visible' : 'hidden'}
          animate="visible"
          exit="hidden"
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Expandable;
