import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { IMenuLink } from 'utils/models';

// component
import Button from 'components/Button';
import Drawer from 'components/Drawer';
import LinkTo from 'components/LinkTo';
import { SvgSidePrescription, SvgSideConsultation, SvgSideDashboard } from 'components/SvgIcons';
import ButtonViewCart from './buttons/ButtonViewCart';
import MobileMenu from './MobileMenu';

const MobileNavigationBottom: FC<{ hasCart?: boolean }> = ({ hasCart = false }) => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const links: IMenuLink[] = [
    {
      icon: <SvgSideDashboard className="w-5 h-5" />,
      label: 'Overview',
      to: '/dashboard'
    },
    {
      icon: <SvgSidePrescription className="w-5 h-5" />,
      label: 'Scripts',
      to: '/prescriptions'
    },
    {
      icon: <SvgSideConsultation className="w-5 h-5" />,
      label: 'Consults',
      to: '/consultations'
    }
  ];

  return (
    <div className="w-full border-t-4 border-polln-light-green-500">
      <div className="bg-white">
        <div className="flex items-center">
          <div className="flex" style={{ flex: '1 1 0' }}>
            {links.map((item, index: number) => {
              const active = location.pathname.indexOf(item.to as string) === 0 || false;

              return (
                <LinkTo
                  to={item.to}
                  rounded="none"
                  size="xs"
                  className={clsx('flex-1', active && 'bg-polln-solid-green-600')}
                  classNameLink={clsx(
                    'flex-col space-y-1',
                    active ? 'text-white' : 'text-gray-500'
                  )}
                  variant="invisible"
                  key={index}
                >
                  {item.icon}
                  <span>{item.label}</span>
                </LinkTo>
              );
            })}

            {hasCart && <ButtonViewCart />}

            <Button
              rounded="none"
              size="xs"
              className="text-gray-500 flex-1"
              classNameButton="flex-col pt-2.5 space-y-2"
              variant="invisible"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            >
              <div className="space-y-1 h-3">
                <div className="w-5 h-0.5 bg-gray-500" />
                <div className="w-5 h-0.5 bg-gray-500" />
                <div className="w-5 h-0.5 bg-gray-500" />
              </div>
              <span>Menu</span>
            </Button>
          </div>
        </div>

        <Drawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          size="full"
          className="overflow-y-scroll"
        >
          <MobileMenu setIsDrawerOpen={setIsDrawerOpen} />
        </Drawer>
      </div>
    </div>
  );
};

export default MobileNavigationBottom;
