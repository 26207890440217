export const UPDATE_GP = 'other/UPDATE_GP';
export const UPDATE_SAVE_CARD = 'other/UPDATE_SAVE_CARD';
export const UPDATE_NO_OF_SAVED_CARD = 'other/UPDATE_NO_OF_SAVED_CARD';
export const UPDATE_CONSULTATION = 'other/UPDATE_CONSULTATION';
export const GET_CART_ITEMS = 'other/GET_CART_ITEMS';
export const GET_ESCRIPT_ITEMS = 'other/GET_ESCRIPT_ITEMS';
export const UPDATE_CART_ITEMS = 'other/UPDATE_CART_ITEMS';
export const REFETCH_ESCRIPT = 'other/REFETCH_ESCRIPT';
export const USER_ME = 'me/USER_ME';
export const USER_ONBOARD = 'me/USER_ONBOARD';
export const USER_LOGIN = 'me/USER_LOGIN';
export const USER_LOGOUT = 'me/USER_LOGOUT';
