import {
  UPDATE_CONSULTATION,
  UPDATE_GP,
  GET_CART_ITEMS,
  UPDATE_SAVE_CARD,
  UPDATE_NO_OF_SAVED_CARD,
  GET_ESCRIPT_ITEMS,
  REFETCH_ESCRIPT
} from 'store/types';
import { IActionPayload } from 'store/actions/actions';
import { ICartItem, IConsultationDetails, IEscripts } from 'utils/models';

export type TOtherReducerState = {
  consultationDetails: IConsultationDetails | null;
  cartItems: ICartItem[];
  eScripts: IEscripts[];
  saveCard: boolean;
  noOfSavedCard: number;
  hasGp: boolean;
  refetchEscript: boolean;
};

const initialState: TOtherReducerState = {
  consultationDetails: null,
  cartItems: [],
  eScripts: [],
  saveCard: false,
  noOfSavedCard: 0,
  hasGp: true,
  refetchEscript: false
};

const reducers = (state: TOtherReducerState = initialState, action: IActionPayload) => {
  switch (action.type) {
    case UPDATE_GP:
      return {
        ...state,
        hasGp: false
      };
    case UPDATE_SAVE_CARD:
      return {
        ...state,
        saveCard: action.payload
      };
    case UPDATE_NO_OF_SAVED_CARD:
      return {
        ...state,
        noOfSavedCard: action.payload
      };
    case UPDATE_CONSULTATION:
      return {
        ...state,
        consultationDetails: action.payload
      };
    case GET_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload
      };
    case GET_ESCRIPT_ITEMS:
      return {
        ...state,
        eScripts: action.payload
      };
    case REFETCH_ESCRIPT:
      return {
        ...state,
        refetchEscript: action.payload
      };
    default:
      return state;
  }
};

export default reducers;
