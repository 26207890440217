import React, { FC, Fragment, ReactNode, useRef, useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { Dialog, Transition } from '@headlessui/react';
import { TModalRound, TModalSize } from 'utils/types';
import { XIcon } from '@heroicons/react/outline';
import { ModalSaving } from 'hooks/useModalSaving';

// components
import ConfirmDialog from './modals/ModalConfirm';
import Button from './Button';

interface IModal {
  open: boolean;
  onClose: (x: boolean) => void;
  title?: string | ReactNode;
  classNameTitle?: ClassValue;
  children?: ReactNode;
  size?: TModalSize;
  round?: TModalRound;
  bgType?: 'normal' | 'transparent';
  isConfirmOpen?: boolean;
  disableClose?: boolean;
  hasCloseIcon?: boolean;
}

const bgTypes = {
  normal: 'bg-white shadow-xl ',
  transparent: 'bg-transparent'
};

const sizes = {
  xs: 'max-w-xs',
  sm: 'max-w-xs md:max-w-sm',
  md: 'max-w-xs md:max-w-md',
  lg: 'max-w-xs md:max-w-lg',
  xl: 'max-w-xs md:max-w-xl',
  '2xl': 'max-w-xs md:max-w-2xl',
  '3xl': 'max-w-xs md:max-w-3xl',
  '4xl': 'max-w-xs md:max-w-4xl',
  '5xl': 'max-w-xs md:max-w-5xl',
  '6xl': 'max-w-xs md:max-w-6xl',
  '7xl': 'md:max-w-7xl',
  full: 'w-full'
};

const rounded = {
  sm: 'rounded-sm',
  rounded: 'rounded',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full'
};

const Modal: FC<IModal> = ({
  open,
  onClose,
  title,
  classNameTitle,
  size = 'md',
  round = 'rounded',
  bgType = 'normal',
  children,
  isConfirmOpen = false,
  disableClose = false,
  hasCloseIcon = true
}) => {
  const close = () => onClose(!open);
  const buttonRef = useRef(null);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  return (
    <ModalSaving.Provider value={{ isSaving, setIsSaving }}>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto"
          onClose={() => (isSaving || disableClose ? {} : close())}
          // onClose={() => {}}
          initialFocus={buttonRef}
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* Use the overlay to style a dim backdrop for your dialog */}
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={clsx(
                  sizes[size],
                  rounded[round],
                  bgTypes[bgType],
                  title && 'overflow-hidden',
                  'inline-block w-full text-left align-middle transition-all transform',
                  'relative'
                )}
              >
                {!title && hasCloseIcon && (
                  <div className="absolute top-0 right-0 -mt-2 -mr-2">
                    <Button
                      type="button"
                      variant="red-btn-100"
                      size="inline"
                      rounded="full"
                      ref={buttonRef}
                      disabled={isSaving || disableClose}
                      classNameButton="p-1"
                      onClick={() => close()}
                    >
                      <XIcon className="w-5 h-5" />
                    </Button>
                  </div>
                )}

                <div className="divide-y">
                  {title && (
                    <Dialog.Title
                      as="div"
                      className={clsx(
                        'text-lg font-medium leading-6 p-4 capitalize flex justify-between items-center',
                        classNameTitle
                      )}
                    >
                      <div>{title}</div>

                      {hasCloseIcon && (
                        <Button
                          type="button"
                          variant="invisible"
                          size="inline"
                          rounded="full"
                          ref={buttonRef}
                          disabled={isSaving || disableClose}
                          classNameButton="p-1"
                          onClick={() => close()}
                        >
                          <XIcon className="w-5 h-5" />
                        </Button>
                      )}
                    </Dialog.Title>
                  )}

                  <div className="p-4">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>

          {!isConfirmOpen && <ConfirmDialog />}
          <div id="picker-portal" />
        </Dialog>
      </Transition>
    </ModalSaving.Provider>
  );
};

export default Modal;
