import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { IMenuTypes } from 'utils/models';
import storage from 'utils/storage';
import useAuth from 'hooks/useAuth';

// components
import SideActions from 'components/SideActions';
import {
  SvgDocument,
  SvgHouse,
  SvgLefRightArrow,
  SvgSideConsultation,
  SvgSideDashboard,
  SvgSideLogout,
  SvgSidePrescription,
  SvgSideResource
} from 'components/SvgIcons';
import Button from './Button';
import { CONF_SEO_URL } from 'utils/config';

const SideBar: FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [isCollapse, setIsCollapse] = useState<boolean>(true);

  const links: IMenuTypes[] = [
    {
      icon: <SvgSideDashboard className="w-5 h-5" />,
      label: 'Overview',
      to: '/dashboard'
    },
    {
      icon: <SvgSideConsultation className="w-5 h-5" />,
      label: 'Consultations',
      to: '/consultations'
    },
    {
      icon: <SvgSidePrescription className="w-5 h-5" />,
      label: 'Prescriptions',
      to: '/prescriptions'
    },
    {
      icon: <SvgDocument className="w-5 h-5" />,
      label: 'Documents',
      to: '/documents'
    },
    {
      icon: <SvgHouse className="w-5 h-5" />,
      label: 'Orders',
      to: '/orders'
    },
    {
      icon: <SvgSideResource className="h-5 w-5" />,
      label: 'Resources',
      setting: true,
      actions: [
        {
          label: 'Medicinal Cannabis Library',
          href: `${CONF_SEO_URL}/medicinal-cannabis-library`,
          target: '_blank'
        },
        {
          label: 'Patient Resource Portal',
          href: `${CONF_SEO_URL}/patient-portal`,
          target: '_blank'
        }
      ]
    },
    {
      icon: <SvgSideLogout className="w-5 h-5" />,
      label: 'Log out',
      setting: true,
      onClick: () => {
        logout();
        navigate('/login', { replace: true });
      }
    }
  ];

  const handleCollapse = () => {
    const collapse = !isCollapse;
    setIsCollapse(collapse);
    storage.setItem('collapse', collapse ? 'true' : 'false');
  };

  useEffect(() => {
    const collapse = storage.getItem('collapse');
    if (collapse) setIsCollapse(JSON.parse(collapse));
  }, []);

  return (
    <div className="hidden md:block bg-polln-light-green-500 h-full">
      <div className={clsx('bg-polln-light-green-500 h-full relative', !isCollapse && 'w-max')}>
        <div className="absolute top-2.5 -right-3.5">
          <Button onClick={() => handleCollapse()} size="inline" variant="invisible">
            <div className="bg-polln-solid-green-600 rounded-full p-1">
              <SvgLefRightArrow className="text-white w-4 h-4" />
            </div>
          </Button>
        </div>

        <div className="pt-5">
          <SideActions actions={links} isCollapse={isCollapse} />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
