import React, { FC } from 'react';
import clsx, { ClassValue } from 'clsx';
import { PhotographIcon } from '@heroicons/react/outline';

const EScriptProductImage: FC<{ image: string | null; className?: ClassValue }> = ({
  image,
  className
}) =>
  image ? (
    <div
      className={clsx(className, 'rounded-md shrink-0', 'bg-contain bg-center bg-no-repeat')}
      style={{ backgroundImage: `url(${image})` }}
    />
  ) : (
    <div
      className={clsx(
        className,
        'rounded-md shrink-0 bg-white shadow drop-shadow-lg border',
        'justify-center items-center flex p-2'
      )}
    >
      <div className="text-gray-400 space-y-0.5">
        <PhotographIcon className="h-8 w-8 mx-auto" />
        <div className="text-center text-xs leading-tight uppercase">No image available</div>
      </div>
    </div>
  );

export default EScriptProductImage;
